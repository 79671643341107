// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

type DynamicReviewModalElement = HTMLElement & {
  record: any;
  dynamicFieldList: any[];
  applicationTypeName: string;
  reviewJsonResponse: any;
};

export const configJSON = require("./config");

export interface Props {
  modalOpen: boolean;
  record: any;
  applicationTypeName: string;
  dynamicFieldList: any[];
  reviewJsonResponse: any;
  onSubmit: (forms: any[]) => void;
  onCancel: () => void;
}



interface S {}

interface SS {}

export default class ReviewModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.modalContainerRef = { current: null };
    this.modalRef = { current: null };
  }

  modalContainerRef : React.MutableRefObject<HTMLDivElement | null>;
  modalRef : React.MutableRefObject<DynamicReviewModalElement | null>;

  async componentDidMount() {
    console.log("componentDidMount");
  }

  async receive(from: string, message: Message) {
    console.log("receive");
  }

  afterOpenModal = () => {
    const ref = this.modalContainerRef;
    const {record, dynamicFieldList, applicationTypeName, reviewJsonResponse} = this.props;
    if (ref) {
      const modalElement = document.createElement(
        "review-modal"
      ) as DynamicReviewModalElement;
      modalElement.record = record;
      modalElement.dynamicFieldList = dynamicFieldList;
      modalElement.applicationTypeName = applicationTypeName;
      modalElement.reviewJsonResponse = reviewJsonResponse;

      modalElement.addEventListener(
        "reviewResultChange",
        this.reviewResultChangeHandler
      );
      ref.current!.appendChild(modalElement);
      this.modalRef.current = modalElement;
    }
  };

  afterCloseModal = () => {
    this.modalRef.current!.remove();
    this.modalRef.current!.removeEventListener(
      "reviewResultChange",
      this.reviewResultChangeHandler
    );
    this.modalRef.current = null;
  };

  reviewResultChangeHandler = (event: Event) => {
    const {onSubmit, onCancel} = this.props;
    const result = ((event as CustomEvent<any>).detail);
    if (result.status === "CANCELED") onCancel();
    if (result.status === "SUBMITTED") onSubmit(result.formList);
  };
}
// Customizable Area End
