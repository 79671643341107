// Customizable Area Start
import { debounce } from "@material-ui/core";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent, MouseEvent } from "react";
import { NormalizedUserData } from "./DashboardController.web";
import { isEmail } from "../../utilities/src/emailValidation";
import { ContactInfoDetails } from "../../customisableuserprofiles/src/CustomisableUserProfilesController";

export const webConfigJSON = require("./config.js");

export interface DealershipNormalized {
  id: string;
  name: string;
  logo: string;
  createdAt: string;
  users: number;
  addresses: AddressData[];
  businessCredentials?: string;
  dealType: string[];
  bankInformation: BankInformation;
  checked: boolean;
}
export interface Dealership {
  id: string;
  type: string;
  attributes: DealershipAttributes;
}

export interface DealershipAttributes {
  dealership_name: string;
  business_credentials: string;
  deal_type: string[];
  created_at: string;
  updated_at: string;
  dealership_users: number;
  bank_information: BankInformation;
  addresses: Addresses;
  dealership_logo: any;
  w9_forms: any[];
}
interface ProviderServices {
  isRunner: string;
}
export interface ServiceableLocation {
  id: string;
  provider_services: ProviderServices;
  created_at: string;
  updated_at: string;
  provider_state: string;
  service_provider_information_id: string;
  business_license: string;
  dealer_license: string;
  dmv_contract: string;
  registration_service_license: string;
  insurance_requirement: string;
  checked: boolean;
  users_count: number;
  is_loaction_created_by_login_user: boolean;
}

export interface ProviderDetails {
  id: string;
  business_name: string;
  business_credentials: string;
  deal_type: string[];
  created_at: string;
  updated_at: string;
  business_address: BankAddressAttributes;
  shipping_address: BankAddressAttributes;
  serviceable_locations: ServiceableLocation[];
  contact_info: ContactInfoDetails;
  banking_info: BankInformation;
  service_provider_logo: string;
  w9_forms: any[];
  authorization_signation: string;
}

export interface BankInformation {
  data: BankData[];
}

export interface BankData {
  id: string;
  type: string;
  attributes: BankAttributes;
}

export interface BankAttributes {
  bank_name: string;
  account_number: string;
  bank_routing_number: string;
  branch_name: string;
  name: string;
  phone_number: string;
  job_title: string;
  created_at: string;
  updated_at: string;
  agree_e_sign: boolean;
  authirization_signature: any;
  voided_check: string;
  full_signature: string;
  initial_signature: string;
  address: BankAddress;
}

export interface BankAddress {
  data: BankAddressData;
}

export interface BankAddressData {
  id: string;
  type: string;
  attributes: BankAddressAttributes;
}

export interface BankAddressAttributes {
  address: string;
  address_type: string;
  country: any;
  city: string;
  state: string;
  zipcode: string;
  zipcode_ext: string;
}

export interface Addresses {
  data: AddressData[];
}

export interface AddressData {
  id: string;
  type: string;
  attributes: AddressAttributes;
}

export interface AddressAttributes {
  address: string;
  address_type: string;
  country: string | null;
  city: string;
  state: string;
  zipcode: string;
  zipcode_ext: string;
}

export interface User {
  id: string;
  type: string;
  attributes: UserAttributes;
}

export interface UserNormalized {
  id: string;
  type: string;
  email: string;
  name: string;
  contactNumber: string;
  dealerRole: string;
  profilePicture: string;
  joinedOn: string;
  dealerships: string;
  checked: boolean;
  locations: string[];
}

export interface UserAttributes {
  id: number;
  email: string;
  name: string;
  contact_number: string;
  dealer_role: string;
  dealerships: string;
  profile_picture: string;
  joined_on: string;
}

export interface SingleDealershipInfo {
  name?: string;
  logo?: string;
}

export interface Role {
  id: string;
  type: string;
  attributes: {
    name: string;
    label: string;
  };
}

export enum ContentView {
  DEALERSHIPS = "DEALERSHIPS",
  SERVICEABLE_LOCATIONS = "SERVICEABLE_LOCATIONS",
  SINGLE_DEALERSHIP_USERS = "SINGLE_DEALERSHIP_USERS",
  SINGLE_SERVICEABLE_LOCATION = "SINGLE_SERVICEABLE_LOCATION",
  USERS = "USERS",
}

export interface Props {
  userData: NormalizedUserData | null;
  showInviteUser: boolean;
  updateGroupName: (name: string) => void;
  setShowInviteUser: (state: boolean) => void;
  navigateToEditDealership: (dealershipData: Dealership) => void;
  navigateToEditServiceProvider: () => void;
  navigateToEditServiceableLocation: (index: string) => void;
  editDealershipSuccessfully: boolean;
  editSPSuccessfully: boolean;
  handleItemSelected: (itemId: string) => void;
  navigation:any
}
interface S {
  currentTab: ContentView;
  dealershipsData: DealershipNormalized[];
  rawDealershipsData: Dealership[];
  usersData: UserNormalized[];
  roles: Role[];
  singleDealershipInfo?: SingleDealershipInfo;
  isShowDealershipUsers: boolean;
  selectedDealership?: string;
  selectedServiceableLocation?: string;
  sort: string;
  role: string;
  dealershipsFilter: string[];
  page: number;
  totalUsers: number;
  totalUsersPages: number;
  totalDealerships: number;
  totalStates: number;
  totalDealershipsPages: number;
  totalStatesPages: number;
  itemsPerPage: number;
  search: string;
  isAllChecked: boolean;
  isDeletePromptOpened: boolean;
  usersToDelete: string[];
  errorMsg: string;
  loading: boolean;
  snackbarMessage: string;
  isDealershipsModalOpened: boolean;
  addUsersToDealershipsIds: string[];
  showGroupNameEdit: boolean;
  showEditUser: boolean;
  updatedGroupName: string;
  inviteToDealership: string[];
  inviteToServicableLocation: string[];
  invitedUserEmail: string;
  inviteUserEmailError: string;
  invitedUserRole: string;
  invitedUsers: {
    email: string;
    dealership_role_id: string;
    dealer_information_ids: string[];
  }[];
  invitationSuccessScreen: boolean;
  editedUserId: string;
  editedUserName: string;
  editedUserEmail: string;
  editedUserEmailError: string;
  editedUserRole: string;
  editedUserDealerships: string[];
  providerDetails: ProviderDetails;
  services: string[];
  states: string[];
}
interface SS {}

export default class MyTeamController extends BlockComponent<Props, S, SS> {
  apiEditUserCallId: string = "";
  apiProviderDetailCallId: string = "";
  apiServiceLocationsCallId: string = "";
  apiUserRolesCallId: string = "";
  apiDeleteUsersCallId: string = "";
  apiServiceLocationUsersCallId: string = "";
  apiDealershipsCallId: string = "";
  apiUsersCallId: string = "";
  apiInviteUsersToDealershipCallId: string = "";
  apiAddUsersCallId: string = "";
  apiUpdateDealershipGroupCallId: string = "";
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      states: [], 
      services: [],
      currentTab: this.getCurrentTab(),
      dealershipsData: [],
      rawDealershipsData: [],
      usersData: [],
      roles: [],
      singleDealershipInfo: undefined,
      isShowDealershipUsers: false,
      selectedDealership: undefined,
      isAllChecked: false,
      sort: "",
      role: "",
      dealershipsFilter: [],
      page: 1,
      totalUsers: 0,
      totalUsersPages: 1,
      totalStatesPages: 1,
      totalDealerships: 0,
      totalStates: 0,
      totalDealershipsPages: 1,
      itemsPerPage: 10,
      search: "",
      isDeletePromptOpened: false,
      usersToDelete: [],
      errorMsg: "",
      loading: false,
      snackbarMessage: "",
      isDealershipsModalOpened: false,
      addUsersToDealershipsIds: [],
      showGroupNameEdit: false,
      showEditUser: false,
      updatedGroupName: "",
      inviteToDealership: [],
      inviteToServicableLocation: [],
      invitedUserEmail: "",
      inviteUserEmailError: "",
      invitedUserRole: "1",
      invitedUsers: [],
      invitationSuccessScreen: false,
      editedUserId: "",
      editedUserName: "",
      editedUserEmail: "",
      editedUserEmailError: "",
      editedUserRole: "",
      editedUserDealerships: [],
      providerDetails: {} as ProviderDetails,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.props.userData?.attributes.role.includes("dealer")) {
      this.getDealsershipsData();
      this.getUsersData();
    } else {
      this.getProviderDetails();
      this.getServiceLocationsData();
      this.getServiceLocationUsersData();
    }
    this.getRolesData();
    const status = localStorage.getItem('status');
    if(status){
      this.setState({
        snackbarMessage: status
      }, () => localStorage.removeItem('status'))
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (
      prevProps.editDealershipSuccessfully !==
      this.props.editDealershipSuccessfully
    ) {
      this.setState({
        snackbarMessage: this.props.editDealershipSuccessfully
          ? "Dealership Updated"
          : "",
      });
    }

    this.checkUserData(prevProps.userData, this.props.userData);

    this.checkRolesState(prevState.roles, this.state.roles);

    this.checkCurrentTabState(prevState.currentTab, this.state.currentTab);

    this.checkFilters(
      prevState.page,
      this.state.page,
      prevState.sort,
      this.state.sort,
      prevState.role,
      this.state.role,
      prevState.services,
      this.state.services,
      prevState.states,
      this.state.states
    );

    this.checkSearch(prevState.search, this.state.search);

    this.checkDealerData(
      prevState.dealershipsData,
      this.state.dealershipsData,
      prevState.usersData,
      this.state.usersData
    );


    this.checkProviderData(
      prevState.providerDetails,
      this.state.providerDetails,
      prevState.usersData,
      this.state.usersData
    );


    this.checkIsDealerModalOpened(
      prevState.isDealershipsModalOpened,
      this.state.isDealershipsModalOpened
    );

    if (prevState.showEditUser === true && this.state.showEditUser === false) {
      this.setState({
        addUsersToDealershipsIds: [],
      });
    }

    this.checkShowInviteUser(
      prevProps.showInviteUser,
      this.props.showInviteUser
    );

    if (!prevState.snackbarMessage && this.state.snackbarMessage) {
      if(this.props.userData?.attributes.role.includes("dealer")){
        this.getUsersData();
        this.getDealsershipsData();
      }
      else{
        this.getServiceLocationUsersData();
        this.getServiceLocationsData();
      }
    }
  }

  getProviderDetails = (): boolean => {
    this.setState({ loading: true });

    const webHeader = {
      "Content-Type": webConfigJSON.contentType,
      token: localStorage.getItem("authToken"),
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProviderDetailCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/service_provider_informations/get_provider_details`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiGetMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  getViewByUserData = (userData: NormalizedUserData) => {
    let view = ContentView.DEALERSHIPS;
      
    if(userData.attributes.role.includes("dealer")){
      view = userData.attributes.isDealerGroup || userData.attributes.assignedDealershipIds.length > 0
      ? ContentView.DEALERSHIPS
      : ContentView.SINGLE_DEALERSHIP_USERS
    }
    if(userData.attributes.role.includes("service_provider")){
      view = this.state.providerDetails.serviceable_locations.length > 1
      ? ContentView.SERVICEABLE_LOCATIONS
      : ContentView.SINGLE_SERVICEABLE_LOCATION;
    }
    return view;
  }

  checkUserData = (
    prevUserData: NormalizedUserData | null,
    currentUserData: NormalizedUserData | null
  ) => {
    if (
      currentUserData &&
      JSON.stringify(prevUserData?.id) !== JSON.stringify(currentUserData?.id)
    ) {
      const soloDealershipId =
        currentUserData?.attributes.soloDealershipData?.id ||
        currentUserData?.attributes.assignedDealershipIds[0];
      
      this.setState({
        currentTab: this.getViewByUserData(currentUserData),
        updatedGroupName: this.props.userData?.attributes.dealerGroupName || "",
        singleDealershipInfo: soloDealershipId
          ? {
              name:
                currentUserData.attributes.soloDealershipData?.name ||
                this.state.dealershipsData[0]?.name,
              logo:
                currentUserData.attributes.soloDealershipData?.logo ||
                this.state.dealershipsData[0]?.logo,
            }
          : undefined,
        inviteToDealership:
          this.state.dealershipsData.length === 1
            ? [this.state.dealershipsData[0].id]
            : [],
        selectedDealership:
          currentUserData.attributes.assignedDealershipIds.length === 1
            ? String(currentUserData.attributes.assignedDealershipIds[0])
            : undefined,
      });
    }
  };

  checkRolesState = (prevRoles: Role[], currentRoles: Role[]) => {
    if (prevRoles !== currentRoles) {
      const userRoleId = this.state.roles.find(
        (role) => role.attributes.name === "user"
      )?.id;

      if (userRoleId) {
        this.setState({
          invitedUserRole: userRoleId,
        });
      }
    }
  };

  checkCurrentTabState = (prevTab: ContentView, currentTab: ContentView) => {
    if (prevTab !== currentTab) {
      this.setState({
        sort: "",
        role: "",
        page: 1,
        search: "",
        usersData: this.state.usersData.map((user) => ({
          ...user,
          checked: false,
        })),
        dealershipsData: this.state.dealershipsData.map((dealership) => ({
          ...dealership,
          checked: false,
        })),
      });
    }
  };

  checkFilters = (
    prevPage: number,
    currentPage: number,
    prevSort: string,
    currentSort: string,
    prevRole: string,
    currentRole: string,
    prevService: string[],
    currentService: string[],
    prevStates: string[],
    currentStates: string[],
  ) => {
    if (
      prevPage !== currentPage ||
      prevSort !== currentSort ||
      prevRole !== currentRole ||
      prevService !== currentService ||
      prevStates !== currentStates
    ) {
      if(this.state.currentTab === ContentView.DEALERSHIPS){
        this.getDealsershipsData();
      } else if(this.state.currentTab === ContentView.SERVICEABLE_LOCATIONS){
        this.getServiceLocationsData();
      } else{
        this.getUsersData(this.state.selectedDealership);
        this.getServiceLocationUsersData(this.state.selectedServiceableLocation);
      }
    }
  };

  checkSearch = (prevSearch: string, currentSearch: string) => {
    if (prevSearch !== currentSearch) {
      if(this.state.currentTab === ContentView.DEALERSHIPS){
        this.getDealsershipsDataDebounced();
      } else if(this.state.currentTab === ContentView.SERVICEABLE_LOCATIONS){
        this.getServiceLocationsDataDebounced();
      } else{
        this.getUsersDataDebounced();
        this.getServiceLocationUsersDataDebounced();
      }
    }
  };

  checkDealerData = (
    prevDealershipsData: DealershipNormalized[],
    currentDealershipsData: DealershipNormalized[],
    prevUsersData: UserNormalized[],
    currentUsersData: UserNormalized[]
  ) => {
    if (this.state.currentTab === ContentView.DEALERSHIPS) {
      if (prevDealershipsData !== currentDealershipsData) {
        this.setState({
          isAllChecked: this.state.dealershipsData.every(
            (dealership) => dealership.checked
          ),
          inviteToDealership:
            currentDealershipsData.length === 1
              ? [currentDealershipsData[0].id]
              : [],
        });
      }
    } else {
      if (prevUsersData !== currentUsersData) {
        this.setState({
          isAllChecked: this.state.usersData.length
            ? this.state.usersData.every((user) => user.checked)
            : false,
        });
      }
    }
  };

  checkProviderData = (
    prevProviderData: ProviderDetails,
    currentProviderData: ProviderDetails,
    prevUsersData: UserNormalized[],
    currentUsersData: UserNormalized[]
  ) => {
    if (this.state.currentTab === ContentView.SERVICEABLE_LOCATIONS || this.state.currentTab === ContentView.SINGLE_SERVICEABLE_LOCATION) {
      if (prevProviderData !== currentProviderData) {
        this.setState({
          providerDetails: currentProviderData,
          isAllChecked: currentProviderData.serviceable_locations.every(
            (serviceable) => serviceable.checked
          ),
          inviteToServicableLocation: currentProviderData.serviceable_locations.length === 1
              ? [currentProviderData.serviceable_locations[0].id]
              : [],
        });
      }
    } else {
      if (prevUsersData !== currentUsersData) {
        this.setState({
          isAllChecked: this.state.usersData.length
            ? this.state.usersData.every((user) => user.checked)
            : false,
        });
      }
    }
  };

  checkIsDealerModalOpened = (
    prevDealershipsModalOpened: boolean,
    currentisDealershipsModalOpened: boolean
  ) => {
    if (
      prevDealershipsModalOpened === true &&
      currentisDealershipsModalOpened === false
    ) {
      this.setState({
        addUsersToDealershipsIds: [],
      });
    }
  };

  checkShowInviteUser = (
    prevShowInvite: boolean,
    currentShowInvite: boolean
  ) => {
    if (prevShowInvite === false && currentShowInvite === true) {
      this.setState({
        inviteToDealership:
          this.state.dealershipsData.length === 1
            ? [this.state.dealershipsData[0].id]
            : [],
      });
    }
  };

  getCurrentTab = () => {
    const isDealerGroup = this.props.userData?.attributes.isDealerGroup;
    const assignedDealershipIds =
      this.props.userData?.attributes.assignedDealershipIds &&
      this.props.userData?.attributes.assignedDealershipIds.length > 1;

    if (isDealerGroup || assignedDealershipIds) {
      return ContentView.DEALERSHIPS;
    } else {
      return ContentView.SINGLE_DEALERSHIP_USERS;
    }
  };

  showDealershipUsers = (dealershipId: string) => {
    this.setState({
      usersData: [],
      isShowDealershipUsers: true,
      selectedDealership: dealershipId,
      currentTab: ContentView.SINGLE_DEALERSHIP_USERS,
    });
    this.props.handleItemSelected(dealershipId);
    this.getUsersData(dealershipId);
  };

  showServiceableLocationUsers = (servicableId: string) => {
    this.setState({
      usersData: [],
      isShowDealershipUsers: true,
      selectedServiceableLocation: servicableId,
      currentTab: ContentView.SINGLE_SERVICEABLE_LOCATION,
    });
    this.props.handleItemSelected(servicableId);
    this.getServiceLocationUsersData(servicableId);
  }


  getDealsershipsData = (): boolean => {
    this.setState({ loading: true });

    const { page, search, sort } = this.state;

    const params = new URLSearchParams({
      page: page.toString(),
      sort,
      search,
    }).toString();

    const webHeader = {
      "Content-Type": webConfigJSON.contentType,
      token: localStorage.getItem("authToken"),
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDealershipsCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.getDealershipsUrl}?${params}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiGetMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  getServiceLocationsData = (): boolean => {
    this.setState({ loading: true });

    const { page, search, sort, services } = this.state;
    const paramsObject: any = {
      page: page.toString(),
      sort,
      search,
    }

    const params = new URLSearchParams(paramsObject);

    services.forEach((service)=> params.append("services[]", service))


    const webHeader = {
      "Content-Type": webConfigJSON.contentType,
      token: localStorage.getItem("authToken"),
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiServiceLocationsCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/serviceable_locations/get_serviceable_locations?${params.toString()}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiGetMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  getUsersData = (dealershipId?: string): boolean => {
    this.setState({ loading: true });

    const { page, search, role, sort, dealershipsFilter, selectedDealership } =
      this.state;

    const beforeParams = new URLSearchParams({
      page: page.toString(),
      sort,
      search,
    });

    if (dealershipId || selectedDealership) {
      beforeParams.append(
        "dealership_id",
        (dealershipId || selectedDealership)!
      );
    }

    if (role !== "all") {
      beforeParams.append("role", role);
    }

    if (dealershipsFilter.length) {
      dealershipsFilter.forEach((id) =>
        beforeParams.append("dealership_ids[]", id)
      );
    }

    if (this.props.userData?.attributes.soloDealershipId) {
      beforeParams.append(
        "dealership_id",
        this.props.userData.attributes.soloDealershipId.toString()
      );
    }

    const params = beforeParams.toString();

    const webHeader = {
      "Content-Type": webConfigJSON.contentType,
      token: localStorage.getItem("authToken"),
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUsersCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.getUsersUrl}?${params}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiGetMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  getServiceLocationUsersData = (serviceLocationId?: string): boolean => {
    this.setState({ loading: true });

    const { page, search, role, sort, states } =
      this.state;

    const beforeParams = new URLSearchParams({
      page: page.toString(),
      sort,
      search,
    });

    states.forEach((state)=> beforeParams.append("states[]", state))

    if (serviceLocationId) {
      beforeParams.append(
        "serviceable_location_id",
        (serviceLocationId)!
      );
    }

    if (role !== "all") {
      beforeParams.append("role", role);
    }

    const params = beforeParams.toString();

    const webHeader = {
      "Content-Type": webConfigJSON.contentType,
      token: localStorage.getItem("authToken"),
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiServiceLocationUsersCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_teambuilder/provider_members/provider_user_list?${params}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiGetMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  getDealsershipsDataDebounced = debounce(this.getDealsershipsData, 1000);
  getServiceLocationsDataDebounced = debounce(this.getServiceLocationsData, 1000);
  getUsersDataDebounced = debounce(this.getUsersData, 1000);
  getServiceLocationUsersDataDebounced = debounce(this.getServiceLocationUsersData, 1000);

  getRolesData = (): boolean => {
    this.setState({ loading: true });

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUserRolesCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.props.userData?.attributes.role.includes('dealer') ?  webConfigJSON.getRolesUrl : '/bx_block_profile/service_provider_informations/get_provider_roles'
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": webConfigJSON.contentType,
        token: localStorage.getItem("authToken"),
      })
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiGetMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  handleDeleteUsers = () => {
    this.setState({ loading: true });

    const { usersData, usersToDelete } = this.state;

    const encodedEmails = encodeURIComponent(
      JSON.stringify(
        usersData
          .filter((user) => usersToDelete.includes(user.id))
          .map((user) => user.email)
      )
    );

    const webHeader = {
      "Content-Type": webConfigJSON.contentType,
      token: localStorage.getItem("authToken"),
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteUsersCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${this.props.userData?.attributes.role.includes('dealer') ? webConfigJSON.deleteUserUrl : webConfigJSON.deleteUserUrlSP}?email=${encodedEmails}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiDeleteMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  getDealershipIdByName(name: string) {
    return this.state.dealershipsData.find((dt) => dt.name.includes(name))?.id;
  }

  addUsers = () => {
    this.setState({ loading: true });

    const { usersData, addUsersToDealershipsIds } = this.state;

    if (!addUsersToDealershipsIds.length) {
      this.setState({ loading: false });
      return;
    }

    const usersToAdd = usersData.filter((user) => user.checked);

    const formData = new FormData();

    usersToAdd.forEach((user, i) => {
      formData.append(`member_update[${i}][member_id]`, user.id);
      addUsersToDealershipsIds.forEach((id) => {
        formData.append(`member_update[${i}][dealer_information_ids][]`, id);
      });
      user.dealerships.split(",").forEach((dealership) => {
        let id = this.getDealershipIdByName(dealership.trim())!;
        if (!addUsersToDealershipsIds.includes(id)) {
          formData.append(`member_update[${i}][dealer_information_ids][]`, id);
        }
      });
    });

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddUsersCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.addUserUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: localStorage.getItem("authToken"),
      })
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiPutMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  editUser = () => {
    this.setState({ loading: true });
    const {userData} = this.props;
    const {
      editedUserId,
      editedUserEmail,
      editedUserRole,
      addUsersToDealershipsIds,
    } = this.state;
    const isDealer = userData?.attributes.role.includes('dealer');
    const webHeader = {
      token: localStorage.getItem("authToken"),
    };

    if (!editedUserId) return;

    const formData = new FormData();

    formData.append("member_update[0][member_id]", editedUserId);
    if(isDealer){
      formData.append("member_update[0][dealership_role_id]", editedUserRole);
      addUsersToDealershipsIds.forEach((id) => {
        formData.append("member_update[0][dealer_information_ids][]", id);
      });
    } else{
      formData.append("member_update[0][provider_role_id]", editedUserRole);
      addUsersToDealershipsIds.forEach((id) => {
        formData.append("member_update[0][serviceable_location_ids][]", id);
      });
    }
    
    formData.append("member_update[0][email]", editedUserEmail);

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEditUserCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isDealer ? webConfigJSON.addUserUrl : webConfigJSON.editUserSPUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiPutMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  inviteUsersToDealership = () => {
    this.setState({ loading: true });

    const {
      invitedUserEmail,
      invitedUserRole,
      inviteToDealership,
      inviteToServicableLocation,
      invitedUsers, 
    } = this.state;
    const isDealer = this.props.userData?.attributes.role.includes('dealer');
    const data = isDealer ? inviteToDealership : inviteToServicableLocation;
    let body;
    if(isDealer){
      body = {
        dealership_members: invitedUsers,
      };

      if (isEmail(invitedUserEmail).status && data.length) {
        body.dealership_members.push({
          email: invitedUserEmail,
          dealership_role_id: invitedUserRole,
          dealer_information_ids: data,
        });
      }
    } else{
      body = {
        provider_members: invitedUsers.map(
          (member) => ({
            email: member.email,
            serviceable_location_ids: member.dealer_information_ids,
            provider_role_id: member.dealership_role_id,
          })
        )
      }

      if (isEmail(invitedUserEmail).status && data.length) {
        body.provider_members.push({
          email: invitedUserEmail,
          provider_role_id: invitedUserRole,
          serviceable_location_ids: data,
        });
      }
    }

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiInviteUsersToDealershipCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isDealer ? webConfigJSON.inviteUserToDealershipUrl : webConfigJSON.inviteUserToServiceableLocationUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": webConfigJSON.contentType,
        token: localStorage.getItem("authToken"),
      })
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiPostMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  updateDealerGroupName = () => {
    this.setState({ loading: true });

    const { updatedGroupName } = this.state;

    const formData = new FormData();

    formData.append("account[dealer_group_name]", updatedGroupName);
    formData.append("account[is_dealer_group]", "true");

    const webHeader = {
      token: localStorage.getItem("authToken"),
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateDealershipGroupCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.updateDealerGroupUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiPutMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        this.handleDealershipsApiResponse(webApiRequestCallId, webResponseJson);

        this.handleServiceLocationsApiResponse(webApiRequestCallId, webResponseJson);

        this.handleUsersApiResponse(webApiRequestCallId, webResponseJson);

        this.handleDeleteUserApiResponse(webApiRequestCallId);

        this.handleAddUsersApiResponse(webApiRequestCallId);

        this.handleUplateDealershipGroupApiResponse(webApiRequestCallId);

        this.handleInviteUsersToDealership(webApiRequestCallId);

        this.handleRolesApiResponse(webApiRequestCallId, webResponseJson);

        this.handleEditUserApiResponse(webApiRequestCallId);

        this.handleProviderDetailsApiResponse(
          webApiRequestCallId,
          webResponseJson
        );
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiUsersCallId) {
          this.setState({
            usersData: [],
            errorMsg: webErrorReponse,
            loading: false,
          });
        }
      }
    }
  }

  isAdmin = () => {
    return this.props.userData?.attributes.role.includes("admin")!;
  };

  setTab = (event: ChangeEvent<{}>, value: ContentView) => {
    this.setState({ currentTab: value });
  };

  setSort = (value: string) => {
    this.setState({ sort: value });
  };

  setRole = (value: string) => {
    this.setState({ role: value });
  };

  setPage = (page: number) => {
    this.setState({ page });
  };

  setSearch = (value: string) => {
    this.setState({ search: value });
  };

  setServices = (value: string[]) => {
    this.setState({ services: value });
  };

  setStates = (value: string[]) => {
    this.setState({ states: value });
  };

  setDeletePromptState = () => {
    this.setState({ isDeletePromptOpened: !this.state.isDeletePromptOpened });
  };

  handleDeleteUserPrompt = (userId: string) => {
    this.setState({
      usersToDelete: [userId],
      isDeletePromptOpened: true,
    });
  };

  handleBulkDeleteUserPrompt = () => {
    this.setState({
      usersToDelete: this.state.usersData
        .filter((user) => user.checked)
        .map((user) => user.id),
      isDeletePromptOpened: true,
    });
  };

  setUpdatedGroupName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ updatedGroupName: event.target.value });
  };

  setInviteUserEmail = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({
      invitedUserEmail: event.target.value,
    });
  };

  setInviteUserRole = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      invitedUserRole: event.target.value,
    });
  };

  checkInviteEmail = () => {
    const { invitedUserEmail } = this.state;
    const testEmailResults = isEmail(invitedUserEmail);

    this.setState({
      inviteUserEmailError: testEmailResults.message,
    });
  };

  checkEditEmail = () => {
    const { editedUserEmail } = this.state;
    const testEmailResults = isEmail(editedUserEmail);

    this.setState({
      editedUserEmailError: testEmailResults.message,
    });
  };

  isAllowedToAddMore = () => {
    const { inviteToDealership, inviteToServicableLocation, invitedUserEmail } = this.state;
    const data = this.props.userData?.attributes.role.includes('dealer') ? inviteToDealership :inviteToServicableLocation; 
    return (
      isEmail(invitedUserEmail).status && Boolean(data.length)
    )
  };

  isAllowedToInvite = () => {
    const { inviteToDealership, inviteToServicableLocation, invitedUserEmail, invitedUsers } = this.state;
    const data = this.props.userData?.attributes.role.includes('dealer') ? inviteToDealership :inviteToServicableLocation; 
    return (
      (!invitedUsers.length &&
        isEmail(invitedUserEmail).status &&
        Boolean(data.length)) ||
      Boolean(
        invitedUsers.length &&
          isEmail(invitedUserEmail).status &&
          data.length
      )
    );
  };

  isAllowedToEdit = () => {
    const { addUsersToDealershipsIds, editedUserEmail } = this.state;
    return (
      isEmail(editedUserEmail).status &&
      Boolean(addUsersToDealershipsIds.length)
    );
  };

  setEditUserEmail = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      editedUserEmail: event.target.value,
    });
  };

  setEditUserRole = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      editedUserRole: event.target.value,
    });
  };

  handleDealershipsModal = () => {
    this.setState({
      isDealershipsModalOpened: !this.state.isDealershipsModalOpened,
    });
  };

  handleGroupEdit = () => {
    this.setState({
      updatedGroupName: this.props.userData?.attributes.dealerGroupName || "",
      showGroupNameEdit: !this.state.showGroupNameEdit,
    });
  };

  handleDealershipSelect = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = event.target.value as string[];
    const { dealershipsData, providerDetails } = this.state;
    const isDealer = this.props.userData?.attributes.role.includes('dealer')
    const isDataEqual = (data: any) => {
      return data.length === value.filter((id) => id !== "all").length
    }
    if(isDealer){
      if (value.includes("all")) {
        if (isDataEqual(dealershipsData)) {
          this.setState({ inviteToDealership: []});
        } else {
          this.setState({
            inviteToDealership: dealershipsData.map(
              (dealership) => dealership.id
            ),
          });
        }
      } else {
        this.setState({ inviteToDealership: event.target.value as string[] });
      }
    } else{
      if (value.includes("all")) {
        if (isDataEqual(providerDetails.serviceable_locations)) {
          this.setState({ inviteToServicableLocation: [] });
        } else {
          this.setState({
            inviteToServicableLocation: providerDetails.serviceable_locations.map(
              (location) => location.id
            ),
          });
        }
      } else {
        this.setState({ inviteToServicableLocation: event.target.value as string[] });
      }
    }
  };

  handleDealershipsFilterSelect = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.setState({ dealershipsFilter: event.target.value as string[] || [] });
  };

  handleDealershipsFilterClose = () => {
    this.getUsersData();
  };

  setInviteUsers = () => {
    const { invitedUserEmail, invitedUserRole, inviteToDealership, inviteToServicableLocation } =
      this.state;
    const isDealer = this.props.userData?.attributes.role.includes('dealer');
    const data = isDealer ? inviteToDealership : inviteToServicableLocation;
    const userRoleId = this.state.roles.find(
      (role) => role.attributes.name === "user"
    )?.id;
    this.setState({
      invitedUsers: [
        ...this.state.invitedUsers,
        {
          email: invitedUserEmail,
          dealership_role_id: invitedUserRole,
          dealer_information_ids: data,
        },
      ],
      invitedUserEmail: "",
      invitedUserRole: userRoleId || "3",
    });
    if(isDealer){
      this.setState({inviteToDealership:
        this.state.dealershipsData.length === 1
          ? [this.state.dealershipsData[0].id]
          : [],})
    } else{
      this.setState({inviteToServicableLocation:
        this.state.providerDetails.serviceable_locations.length === 1
          ? [this.state.providerDetails.serviceable_locations[0].id]
          : [],})
    }
  };

  setShowEditUser = (state: boolean) => {
    this.setState({
      showEditUser: state,
    });
  };

  onShowEditUserClose = () => {
    this.setState({
      showEditUser: false,
    });
  };

  onShowInviteUserClose = () => {
    this.props.setShowInviteUser(false);

    const userRoleId = this.state.roles.find(
      (role) => role.attributes.name === "user"
    )?.id;

    this.setState({
      invitedUsers: [],
      invitedUserEmail: "",
      inviteToDealership:
        this.state.dealershipsData.length === 1
          ? [this.state.dealershipsData[0].id]
          : [],
      invitedUserRole: userRoleId || "3",
    });
  };

  stopPropagation = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleEditUser = (userId: string) => {
    const { userData } = this.props;
    const { roles, dealershipsData, usersData } = this.state;
    const user = usersData.find((user) => user.id === userId);
    this.setState({
      editedUserId: user?.id || "",
      editedUserName: user?.name || "",
      editedUserEmail: user?.email || "",
      editedUserRole:
        roles.find((role) => role.attributes.name === user?.dealerRole)?.id ||
        "",
      
      editedUserDealerships: userData?.attributes.soloDealershipId
        ? [dealershipsData[0]?.name]
        : [],
    });
    if(userData?.attributes.role.includes('dealer')){
      this.setState({
        addUsersToDealershipsIds: userData?.attributes.soloDealershipId
      ? [String(userData?.attributes.soloDealershipId)]
      : dealershipsData
          .filter((dealership) =>
            user?.dealerships
              .split(",")
              .map((dealershipName) => dealershipName.trim())
              .includes(dealership.name)
          )
          .map((dealership) => dealership.id),
      })
    } else{
      this.setState({
        addUsersToDealershipsIds: user?.locations ? user.locations.map(String) : []
      })
    }
    this.setShowEditUser(true);
  };

  editInvitedUser = (userToEdit: {
    email: string;
    dealership_role_id: string;
    dealer_information_ids: string[];
  }) => {
    const isDealer = this.props.userData?.attributes.role.includes('dealer');
    if(isDealer){
      this.setState({inviteToDealership: userToEdit.dealer_information_ids});
    } else{
      this.setState({inviteToServicableLocation: userToEdit.dealer_information_ids});
    }
    this.setState({
      invitedUserEmail: userToEdit.email,
      invitedUserRole: userToEdit.dealership_role_id,
      invitedUsers:
        this.state.invitedUserEmail === ""
          ? this.state.invitedUsers.filter(
              (user) => user.email !== userToEdit.email
            )
          : [
              ...this.state.invitedUsers.filter(
                (user) => user.email !== userToEdit.email
              ),
              {
                email: this.state.invitedUserEmail,
                dealership_role_id: this.state.invitedUserRole,
                dealer_information_ids: isDealer ? this.state.inviteToDealership : this.state.inviteToServicableLocation,
              },
            ],
    });
  };

  deleteInvitedUser = (userEmail: string) => {
    this.setState({
      invitedUsers: this.state.invitedUsers.filter(
        (user) => user.email !== userEmail
      ),
    });
  };

  closeInvitationSuccess = () => {
    const userRoleId = this.state.roles.find(
      (role) => role.attributes.name === "user"
    )?.id;

    this.setState({
      invitationSuccessScreen: false,
      invitedUserEmail: "",
      invitedUserRole: userRoleId || "3",
      inviteToDealership:
        this.state.dealershipsData.length === 1
          ? [this.state.dealershipsData[0].id]
          : [],
      invitedUsers: [],
    });
  };

  handleAddUserToDealershipIds = (
    event: MouseEvent<HTMLButtonElement>,
    dealershipId: string
  ) => {
    event.stopPropagation();
    const { addUsersToDealershipsIds } = this.state;

    this.setState({
      addUsersToDealershipsIds: addUsersToDealershipsIds.includes(dealershipId)
        ? addUsersToDealershipsIds.filter((id) => id !== dealershipId)
        : [...addUsersToDealershipsIds, dealershipId],
    });
  };

  handleAddUserToDealership = () => {
    this.addUsers();
  };

  setSnackbarMessage = (message: string) => {
    this.setState({
      snackbarMessage: message,
    });
  };

  onSnackbarClose = () => {
    this.setState({
      snackbarMessage: "",
    });
  };

  handleCheckAll = () => {
    if (this.state.currentTab === ContentView.DEALERSHIPS) {
      this.state.dealershipsData.every((dealership) => dealership.checked)
        ? this.setState({
            dealershipsData: this.state.dealershipsData.map((dealership) => ({
              ...dealership,
              checked: false,
            })),
          })
        : this.setState({
            dealershipsData: this.state.dealershipsData.map((dealership) => ({
              ...dealership,
              checked: true,
            })),
          });
    } else {
      this.state.usersData.every((user) => user.checked)
        ? this.setState({
            usersData: this.state.usersData.map((user) => ({
              ...user,
              checked: false,
            })),
          })
        : this.setState({
            usersData: this.state.usersData.map((user) => ({
              ...user,
              checked: true,
            })),
          });
    }
  };

  handleCheck = (itemId: string) => {
    if (this.state.currentTab === ContentView.DEALERSHIPS) {
      this.setState({
        dealershipsData: this.state.dealershipsData.map((dealership) => {
          if (dealership.id === itemId) {
            return {
              ...dealership,
              checked: !dealership.checked,
            };
          }

          return dealership;
        }),
      });
    } else {
      this.setState({
        usersData: this.state.usersData.map((user) => {
          if (user.id === itemId) {
            return {
              ...user,
              checked: !user.checked,
            };
          }

          return user;
        }),
      });
    }
  };

  getDealershipById = (id: string) => {
    return this.state.dealershipsData.find(
      (dealership) => dealership.id === id
    )!;
  };

  handleDealershipEdit = (dealershipId: string) => {
    const dealership = this.state.rawDealershipsData.find(
      (dealership) => dealership.id === dealershipId
    )!;

    this.props.navigateToEditDealership(dealership);
  };

  handleServiceableLocationEdit = (index: string) => {
    this.props.navigateToEditServiceableLocation(index);
  }

  editSingleDealership = () => {
    this.props.navigateToEditDealership(this.state.rawDealershipsData[0]);
  };

  handleProviderDetailsApiResponse = (
    webApiRequestCallId: string,
    webResponseJson: { [key: string]: any }
  ) => {
    if (webApiRequestCallId === this.apiProviderDetailCallId) {
      const { data } = webResponseJson;
      const { attributes } = data;
      
      this.setState({
        providerDetails: {
          ...this.state.providerDetails,
          id: data.id,
          business_name: attributes.business_name,
          business_credentials: attributes.business_credentials,
          deal_type: attributes.deal_type,
          created_at: attributes.created_at,
          updated_at: attributes.updated_at,
          business_address: attributes.business_address,
          shipping_address: attributes.shipping_address,
          contact_info: attributes.contact_info,
          banking_info: attributes.banking_info,
          w9_forms: attributes.w9_forms,
          service_provider_logo: attributes.service_provider_logo,
          authorization_signation: attributes.authorization_signation,
        },
        loading: false,
      });
    }
  };

  handleDealershipsApiResponse = (
    webApiRequestCallId: string,
    webResponseJson: { [key: string]: any }
  ) => {
    if (webApiRequestCallId === this.apiDealershipsCallId) {
      const soloDealershipId =
        this.props.userData?.attributes.soloDealershipId ||
        (this.props.userData?.attributes.assignedDealershipIds.length === 1 &&
          this.props.userData?.attributes.assignedDealershipIds[0]);
      const data = soloDealershipId
        ? webResponseJson.dealerships.data.filter(
            (dealership: Dealership) =>
              dealership.id === String(soloDealershipId)
          )
        : webResponseJson.dealerships.data;
      this.setState({
        dealershipsData: data.map((dealership: Dealership) => ({
          id: dealership.id,
          logo: dealership.attributes.dealership_logo || "",
          name: dealership.attributes.dealership_name || "",
          createdAt: dealership.attributes.created_at,
          users: dealership.attributes.dealership_users,
          addresses: dealership.attributes.addresses.data,
          checked: false,
        })),
        rawDealershipsData: data,
        totalDealershipsPages:
          Math.ceil(webResponseJson.total / this.state.itemsPerPage) || 1,
        singleDealershipInfo: soloDealershipId
          ? {
              name: data[0]?.attributes.dealership_name,
              logo: data[0]?.attributes.dealership_logo,
            }
          : undefined,
        errorMsg: "",
        loading: false,
      });
    }
  };

  handleServiceLocationsApiResponse = (
    webApiRequestCallId: string,
    webResponseJson: { [key: string]: any }
  ) => {
    if (webApiRequestCallId === this.apiServiceLocationsCallId) {
      let serviceable_locations: ServiceableLocation[] = [];
      if("data" in webResponseJson){
        webResponseJson.data.forEach((location: any) => {
          serviceable_locations.push({
            id: location.id,
            provider_services: location.attributes.provider_services,
            created_at: location.attributes.created_on,
            updated_at: location.attributes.updated_at,
            provider_state: location.attributes.provider_state,
            service_provider_information_id: location.attributes.provider_provider_information_id,
            business_license: location.business_license,
            dealer_license: location.dealer_license,
            dmv_contract: location.dmv_contract,
            registration_service_license: location.registration_service_license,
            insurance_requirement: location.insurance_requirement,
            users_count: location.attributes.users_count,
            is_loaction_created_by_login_user: location.attributes.is_loaction_created_by_login_user,
            checked: false,
          })
        });
        this.setState({
          totalStates: webResponseJson.total_count,
          totalStatesPages: Math.ceil(webResponseJson.total_count / this.state.itemsPerPage || 1)
        })
      }
      this.setState({
        providerDetails:{
          ...this.state.providerDetails,
          serviceable_locations: serviceable_locations
        },
        currentTab: serviceable_locations.length === 1 && webResponseJson.total_count === 1 ? ContentView.SINGLE_SERVICEABLE_LOCATION : ContentView.SERVICEABLE_LOCATIONS
      });
    }
  };

  handleUsersApiResponse = (
    webApiRequestCallId: string,
    webResponseJson: { [key: string]: any }
  ) => {
    if (webApiRequestCallId === this.apiUsersCallId) {
      const { members: { data } = { data: [] }, dealership_info } =
        webResponseJson;

      this.setState({
        usersData: data.map((user: User) => ({
          id: user.id,
          type: user.type,
          email: user.attributes.email,
          name: user.attributes.name,
          contactNumber: user.attributes.contact_number,
          dealerRole: user.attributes.dealer_role,
          profilePicture: user.attributes.profile_picture,
          joinedOn: user.attributes.joined_on,
          dealerships: user.attributes.dealerships,
          checked: false,
        })),
        totalUsersPages:
          Math.ceil(webResponseJson.total / this.state.itemsPerPage) || 1,
        singleDealershipInfo:
          dealership_info && Object.keys(dealership_info).length
            ? dealership_info
            : { ...this.state.singleDealershipInfo },
        errorMsg: "",
        loading: false,
      });
    }

    if (webApiRequestCallId === this.apiServiceLocationUsersCallId) {
      const { members: { data } = { data: [] } } =
        webResponseJson;

      this.setState({
        usersData: data.map((user: any) => ({
          id: user.id,
          type: user.type,
          email: user.attributes.email,
          name: user.attributes.name,
          contactNumber: user.attributes.contact_number,
          dealerRole: user.attributes.provider_role,
          profilePicture: user.attributes.profile_picture,
          joinedOn: user.attributes.joined_on,
          dealerships: user.attributes.dealerships,
          checked: false,
          locations: user.attributes.serviceable_location_ids
        })),
        totalUsersPages:
          Math.ceil(webResponseJson.total_count / this.state.itemsPerPage) || 1,
        errorMsg: "",
        loading: false,
      });
    }
  };

  handleDeleteUserApiResponse = (webApiRequestCallId: string) => {
    if (webApiRequestCallId === this.apiDeleteUsersCallId) {
      this.setState({
        errorMsg: "",
        loading: false,
        isDeletePromptOpened: false,
        snackbarMessage: `User${
          this.state.usersToDelete.length > 1 ? "s" : ""
        } Deleted`,
      });
    }
  };

  handleAddUsersApiResponse = (webApiRequestCallId: string) => {
    if (webApiRequestCallId === this.apiAddUsersCallId) {
      this.setState({
        errorMsg: "",
        loading: false,
        isDealershipsModalOpened: false,
        snackbarMessage: "User(s) added to Dealership(s)",
      });
    }
  };

  handleUplateDealershipGroupApiResponse = (webApiRequestCallId: string) => {
    if (webApiRequestCallId === this.apiUpdateDealershipGroupCallId) {
      this.setState({
        errorMsg: "",
        loading: false,
        showGroupNameEdit: false,
        snackbarMessage: "Group Updated",
      });

      this.props.updateGroupName(this.state.updatedGroupName);
    }
  };

  handleInviteUsersToDealership = (webApiRequestCallId: string) => {
    if (webApiRequestCallId === this.apiInviteUsersToDealershipCallId) {
      this.setState({
        invitationSuccessScreen: true,
        errorMsg: "",
        loading: false,
      });
      if(this.props.userData?.attributes.role.includes('dealer')){
        this.getUsersData();
        this.getDealsershipsData();
      } else {
        this.getServiceLocationUsersData();
        this.getServiceLocationsData();
      }
    }
  };

  handleRolesApiResponse = (
    webApiRequestCallId: string,
    webResponseJson: { data: Role[] }
  ) => {
    if (webApiRequestCallId === this.apiUserRolesCallId) {
      this.setState({
        roles: Array.isArray(webResponseJson.data)
          ? this.getReversedRoles(webResponseJson.data)
          : [],
        errorMsg: "",
        loading: false,
      });
    }
  };

  getReversedRoles = (roles: Role[]) => {
    return roles.reverse();
  };

  handleEditUserApiResponse = (webApiRequestCallId: string) => {
    if (webApiRequestCallId === this.apiEditUserCallId) {
      this.setState({
        editedUserId: "",
        editedUserName: "",
        editedUserEmail: "",
        editedUserRole: "",
        addUsersToDealershipsIds: [],
        editedUserDealerships: [],
        errorMsg: "",
        showEditUser: false,
        loading: false,
      });

      this.setSnackbarMessage("User Updated");
      if(this.props.userData?.attributes.role.includes("dealer")){
        this.getUsersData();
      }
      else{
        this.getServiceLocationUsersData();
      }
    }
  };
}
// Customizable Area End
