import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Dealership } from "./MyTeamController.web";

export enum DashboardView {
  HOME = "HOME",
  ALL_DEALS = "ALL_DEALS",
  MY_TEAM = "MY_TEAM"
}

export interface UserData {
  id: string
  type: string
  attributes: UserAttributes
}

export interface UserAttributes {
  activated: boolean
  country_code: string
  email: string
  full_phone_number: string
  phone_number: string
  type: string
  full_name: string
  created_at: string
  updated_at: string
  is_dealer_group: boolean
  dealer_group_name: string | null
  role: string
  profile_picture: string | null
  group_created_on: string | null
  is_dealership_pending: boolean
  dealership_data: string | null
  solo_dealership_id: number
  is_any_dealership: boolean
  solo_dealership_data: SoloDealershipData
  assigned_dealership_ids: number[] | null
}

export interface SoloDealershipData {
  data: DealershipData
}

export interface DealershipData {
  id: string
  type: string
  attributes: DealershipAttributes
}

export interface DealershipAttributes {
  dealership_name: string
  business_credentials: string
  deal_type: string[]
  created_at: string
  updated_at: string
  dealership_users: number
  addresses: DealershipAddresses
  bank_information: BankInformation
  dealership_logo: string
  w9_forms: W9Form[]
}

export interface DealershipAddresses {
  data: DealershipAddressData[]
}

export interface DealershipAddressData {
  id: string
  type: string
  attributes: AddressAttributes
}

export interface AddressAttributes {
  address: string
  address_type: string
  country: any
  city: string
  state: string
  zipcode: string
  zipcode_ext: string
}

export interface BankInformation {
  data: any[]
}

export interface W9Form {
  url: string
}

export interface NormalizedUserData {
  id: string
  type: string
  attributes: {
    activated: boolean
    countryCode: string
    email: string
    fullPhoneNumber: string
    phoneNumber: string
    type: string
    fullName: string
    createdAt: string
    updatedAt: string
    isDealerGroup: boolean
    dealerGroupName: string | null
    role: string
    profilePicture: string | null
    groupCreatedOn?: string
    isDealershipPending: boolean
    dealershipData: string | null
    soloDealershipId: number | null
    isAnyDealership: boolean
    assignedDealershipIds: number[]
    soloDealershipData: {
      id: string
      name: string
      logo: string
    } | null
  }
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  currentView?: DashboardView;
  userData: NormalizedUserData | null;
  showInviteUser: boolean;
  errorMsg: string;
  loading: boolean;
  editDealershipSuccessfully: boolean;
  editSPSuccessfully: boolean;
  selectedItemId: string;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiUserDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.handleItemSelected = this.handleItemSelected.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    const currentView = window.location.pathname.includes("/MyTeam") ? DashboardView.MY_TEAM : DashboardView.HOME;
    this.state = {
      currentView: currentView,
      userData: null,
      showInviteUser: false,
      errorMsg: "",
      loading: false,
      editDealershipSuccessfully: false,
      editSPSuccessfully: false,
      selectedItemId: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.setState({loading: true})

    const webHeader = {
      "Content-Type": webConfigJSON.contentType,
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUserDataCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getUserUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.ApiGetMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiUserDataCallId) {
          const userData: UserData = webResponseJson.data;
          const {attributes} = userData;

          this.updateUserData(userData, attributes)
        }
      } else if (webResponseJson && webResponseJson.errors) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const passingPramData = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.updateStateFromParam(passingPramData);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleItemSelected(itemId: string){
    this.setState({
      selectedItemId: itemId
    })
  }
  updateStateFromParam(passingPramData: any){
    const currentView = passingPramData.view
    if(currentView){
      this.setCurrentView(currentView)
    }
    if(passingPramData.editDealership){
      this.setState({
        editDealershipSuccessfully: true
      })
    }
    if(passingPramData.editSP){
      this.setState({
        editSPSuccessfully: true
      })
    }
  }
  updateUserData(userData: any, attributes: any){
    this.setState({
      userData: {
        ...userData,
        attributes: {
          activated: attributes.activated,
          countryCode: attributes.country_code,
          email: attributes.email,
          fullPhoneNumber: attributes.full_phone_number,
          phoneNumber: attributes.phone_number,
          type: attributes.type,
          fullName: attributes.full_name,
          createdAt: attributes.created_at,
          updatedAt: attributes.updated_at,
          isDealerGroup: attributes.is_dealer_group,
          dealerGroupName: attributes.dealer_group_name || "",
          role: attributes.role,
          isDealershipPending: attributes.is_dealership_pending,
          dealershipData: attributes.dealership_data,
          soloDealershipId: attributes.solo_dealership_id,
          isAnyDealership: attributes.is_any_dealership,
          soloDealershipData: attributes.solo_dealership_data?.data && {
            id: attributes.solo_dealership_data.data.id,
            name: attributes.solo_dealership_data.data.attributes.dealership_name,
            logo: attributes.solo_dealership_data.data.attributes.dealership_logo
          },
          profilePicture: attributes.profile_picture,
          groupCreatedOn: attributes.group_created_on || undefined,
          assignedDealershipIds: attributes.assigned_dealership_ids || []
        }
      },
      errorMsg: "",
      loading: false
    });
  }
  
  setCurrentView = (view: DashboardView) => {
    this.setState({
      currentView: view
    })
  }

  setShowInviteUser = (state: boolean) => {
    this.setState({
      showInviteUser: state,
    })
  }

  updateGroupName = (name: string) => {
    this.getDashboardData();   
  }

  navigateToCreateNewDealership = ()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CustomisableUserProfiles');
    this.send(msg);
    if(this.state.userData?.attributes.soloDealershipId){
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {createDealershipInSoloMode: true});
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      runEngine.sendMessage("MergeEngineUtilities", msg);
    }
    localStorage.setItem('action', 'add-dealership');
  }

  navigateToCreateNewServiceableLocation = () => {
    this.navigateToServiceProviderProfiles({action: "add"});
    localStorage.setItem('action', 'add-serviceable-location');
  }

  handleNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EditServiceLocation");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), "new");
    runEngine.sendMessage(message.id, message)
  };


  navigateToAllDeals = ()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CreateDealMain');
    this.send(msg);
  }

  navigateToMyTeam = ()=>{
    const {currentView} = this.state;
    if(currentView === DashboardView.MY_TEAM){
      window.location.reload();
      return;
    }
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'MyTeam');
    this.send(msg);
  }

  navigateToDashboard = ()=>{
    const {currentView} = this.state;
    if(currentView === DashboardView.HOME){
      window.location.reload();
      return;
    }
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard');
    this.send(msg);
  }

  navigateToEditDealership = (dealershipData: Dealership)=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CustomisableUserProfiles');
    this.send(msg);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {dealershipData});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage("MergeEngineUtilities", msg);
    localStorage.setItem('action', 'edit-dealership');
    localStorage.setItem('edit-dealership', JSON.stringify(dealershipData));
  }
  navigateToServiceProviderProfiles = (payloadMessage: any)=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ServiceProviderProfiles');
    this.send(msg);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), payloadMessage);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage("MergeEngineUtilities", msg);
  }
  navigateToEditServiceableLocation = (index: string) => {
    this.navigateToServiceProviderProfiles({action: 'edit-serviceable-location', index: index});
    localStorage.setItem('action', 'edit-serviceable-location');
    localStorage.setItem('serviceable-location-id', index);
  }
  
  navigateToEditServiceProvider = ()=>{
    this.navigateToServiceProviderProfiles({action: 'edit-service-provider'});
    localStorage.setItem('action', 'edit-service-provider');
  }

  

  getStyle = (condition: boolean, whenTrue: string | number, whenFalse: string | number) => {
    return condition ? whenTrue : whenFalse;
  }
  // Customizable Area End
}
