import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Checkbox,
  withStyles,
  createStyles,
  createTheme,
  ThemeProvider,
  styled,
} from "@material-ui/core";
import OrderSummaryController, {
  Props,
  configJSON,
} from "./OrderSummaryController";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

const styles = createStyles({
  totalFee: {
    color: "#013D4F",
    fontWeight: 500,
    textAlign: "end"
  },
  premiumPlan: {
    color: "#013D4F",
    fontWeight: 600,
  },
  paynowBtnEnabled: {
    width: "377px",
    borderRadius: "8px",
    height: "56px",
    color: "#FFFFFF",
    backgroundColor: "#4FC8EC",
    boxShadow: "none",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    textTransform: "initial",

    "&:hover": {
      backgroundColor: "#4FC8EC",
    }
  },
  paynowBtnDisabled: {
    width: "377px",
    borderRadius: "8px",
    height: "56px",
    color: "#75D3F0 !important",
    backgroundColor: "#E8F8FD !important",
    boxShadow: "none",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    textTransform: "initial"
  },
  termsAndConditions: {
    color: "#013D4F",
    textDecoration: "underline",
    cursor: 'pointer'
  },
  checkBox: {
    padding: 0,

    "&.Mui-checked": {
      color: "#4FC8EC",
    },
  },
  borderBox: {
    width: "640px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "24px",
    border: "1px solid #F0FAFA",
  },
  container: {
    minWidth: "1366px",
  }
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "30px",
      fontWeight: 300,
      lineHeight: "40px",
      color: "#013D4F",
      letterSpacing: 0
    },
    h2: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "32px",
      color: "#013D4F",
      letterSpacing: 0
    },
    h3: {
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "24px",
      textAlign: "left",
      color: "#013D4F",
    },
    h4: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      textAlign: "left",
      color: "#4B4C4B",
    },

    h5: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      textAlign: "left",
      color: "#4B4C4B",
    },

    h6: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "left",
      color: "#4B4C4B",
    },
    body1: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "18px",
      fontWeight: 300,
      lineHeight: "26px",
      textAlign: "center",
      color: "#4B4C4B",
      letterSpacing: 0
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "left",
      color: "#4B4C4B",
    },
    subtitle2: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      textAlign: "left",
      color: "#C5CBC9",
      marginTop: "8px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  header: {
    height: "72px",
    borderBottom: "1px solid #F0FAFA",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "111px",
  },
  formFee: {
    width: "640px",
    opacity: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  formFeeHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  formFeeContent: {
    width: "544px",
    height: "354px",
  },
  formFeeDetails: {
    marginTop: "40px",
    height: "250px",
    gap: "24px",
    borderRadius: "12px",
    opacity: "0px",
  },
  formsInfo: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
  },
  formFeeSalePrice: {
    display: "flex",
  },
  formFeePay: {
    height: "104px",
    gap: "24px",
    borderRadius: "12px",
    opacity: "0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalFeeInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  formFeeFooter: {
    padding: "48px",
    width: "544px",
    gap: "0px",
    borderRadius: "24px",
    opacity: "0px",
  },
  termAndConditionCheckBox: {
    margin: "24px 0px",
    height: "48px",
    gap: "10px",
    opacity: "0px",
    display: "flex",
    alignItems: "center",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  backSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "86px",
    height: "40px",
    padding: "10px 12px 10px 12px",
    gap: "6px",
    borderRadius: "50px",
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backIcon: {
    width: "20px",
    height: "20px",
  },
  backText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "36px"
  },
};

export const CustomCheckboxIcon = styled("span")(({ theme }) => ({
  width: "20px",
  height: "20px",
  borderRadius: "6px",
  opacity: "0px",
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
}));

export const CustomCheckedCheckboxIcon = styled(CustomCheckboxIcon)({
  backgroundColor: "#4FC8EC",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});
// Customizable Area End

export class OrderSummary extends OrderSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      isAgreed: false,
    };
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, formType } = this.props;
    const { isAgreed } = this.state;
    let formItem = "Forms";
    let {formFeeTitle, formFeeThanksLine1, formFeeThanksLine2, attentionDetail} = configJSON;
    if(formType === "blankForm"){
      formFeeTitle = configJSON.blankFormsFee;
      formItem = configJSON.blankFormsFee;
      formFeeThanksLine1 = configJSON.blankFormsFeeThanksLine1;
      formFeeThanksLine2 = configJSON.blankFormsFeeThanksLine2;
      attentionDetail = configJSON.blankFormsFeeAttentionDetail;
    }
    else if(formType === "checklist"){
      formFeeTitle = configJSON.checklistFee;
      formItem = configJSON.checklistFee;
      formFeeThanksLine1 = configJSON.checklistFeeThanksLine1;
      formFeeThanksLine2 = configJSON.checklistFeeThanksLine2;
      attentionDetail = configJSON.checklistFeeAttentionDetail;
    }
    else if(formType === "calculateRegistration"){
      formFeeTitle = configJSON.calculateRegistrationFee;
      formItem = configJSON.calculateRegistrationFee;
      formFeeThanksLine1 = configJSON.calculateRegistrationFeeThanksLine1;
      formFeeThanksLine2 = configJSON.calculateRegistrationFeeThanksLine2;
      attentionDetail = configJSON.calculateRegistrationFeeAttentionDetail;
    }
    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.container}>
          <Box sx={webStyle.header}>
            <Box data-test-id="back-button" sx={webStyle.backSection} onClick={this.navigateToDealDashboard}>
              <ArrowBackIos style={webStyle.backIcon} />
              <span style={webStyle.backText}>Back</span>
            </Box>
            <Box />
          </Box>
          <Box sx={webStyle.main}>
            <Box sx={webStyle.formFee}>
              <Box sx={webStyle.formFeeHeader}>
                <Typography variant="h1">{formFeeTitle}</Typography>
                <Box sx={{ marginTop: "12px", marginBottom: "60px" }}>
                  <Typography variant="body1">
                    {formFeeThanksLine1}
                  </Typography>
                  <Typography variant="body1">
                    {formFeeThanksLine2}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.borderBox}>
                <Box sx={webStyle.formFeeContent}>
                  <Box sx={webStyle.formFeeDetails}>
                    <Typography variant="h2">Fee Details</Typography>
                    <Box sx={webStyle.formsInfo}>
                      <Typography variant="h4">{formItem}</Typography>
                      <Box sx={webStyle.formFeeSalePrice}>
                        <Typography variant="h4" style={{ color: "#C5CBC9" }}>
                          <del>$60.00</del>
                        </Typography>
                        <Typography variant="h4" style={{ marginLeft: "11px" }}>
                          $50.00
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="h6" style={{marginTop: "8px"}}>
                      10% off due to having a
                      <span className={classes.premiumPlan}> Premium Plan</span>
                    </Typography>

                    <Box sx={webStyle.termAndConditionCheckBox}>
                      <Checkbox
                        icon={<CustomCheckboxIcon />}
                        checkedIcon={<CustomCheckedCheckboxIcon />}
                        data-test-id="checkbox"
                        checked={isAgreed}
                        className={classes.checkBox}
                        onChange={() => this.handleChangeAgreeState(!isAgreed)}
                      />
                      <Typography variant="h5">
                        I agree to the&nbsp;
                        <span data-test-id="termsAndConditions" className={classes.termsAndConditions} onClick={this.navigateToTermsAndConditions}>
                          Terms and Conditions{" "}
                        </span>
                      </Typography>
                    </Box>

                    <Box sx={webStyle.formFeePay}>
                      <Button
                        data-test-id="payNowBtn"
                        variant="contained"
                        disabled={!isAgreed ? true : false}
                        className={
                          isAgreed
                            ? classes.paynowBtnEnabled
                            : classes.paynowBtnDisabled
                        }
                        onClick={this.navigateToAutoForm}
                      >
                        Pay Now
                      </Button>
                      <Box sx={webStyle.totalFeeInfo}>
                        <Typography variant="h6" className={classes.totalFee}>
                          Total Fee
                        </Typography>
                        <Typography variant="h2">$50.00</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.borderBox} sx={{marginTop: "24px"}}>
                <Box sx={webStyle.formFeeFooter}>
                  <Typography variant="h3">{configJSON.attention}</Typography>
                  <Typography variant="subtitle2">
                    {attentionDetail}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(OrderSummary);