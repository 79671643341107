import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities"
import { ChangeEvent } from "react";

export interface GetUserResponseSuccess {
    data: Data;
  }
  export interface Data {
    id: string;
    type: string;
    attributes: Attributes;
  }
  export interface Attributes {
    activated: boolean;
    country_code: string;
    email: string;
    full_phone_number: string;
    phone_number: string;
    type: string;
    full_name: string;
    created_at: string;
    updated_at: string;
    is_dealer_group: boolean;
    dealer_group_name?: null;
    role: string;
    profile_picture?: null;
    group_created_on?: null;
    is_deal_pending: boolean;
    deal_data?: null;
    solo_dealership_id: number;
  }
  
  interface GetUserError {
    errors:[
      {
        token:string;
      }
    ]
  }
export   interface TableDataAcceptedSp {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateReceived: string;
  dateAccepted: string;
  service: string;
  fees: string;
  payout: string;
  status: string;
}

export interface TableRows {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateAccepted?: string;
  service?: string;
  fees: string;
  serviceProvider: string;
  status: string;
}

export interface TablePendingDataRow {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateSent: string;
  service: string;
  fees: string;
  serviceProvider: string;
  status: string;
}

export interface TableDataDeletedorCompleted{
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateCancelled?: string | null;
  dateCompleted?:string | null;
  service?: string;
  fees: string;
  serviceProvider: string;
  status: string;
}

export interface TableDraftDataRow {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  typeOfVehicle: string;
  applicationType: string;
}

export interface TablePendingDataSp {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateReceived: string;
  service: string;
  payout: string;
  status: string;
  selected_service_provider_id: string;
}


export interface DeletedDatatableSp {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateDeleted: string;
  service: string;
  fees: string;
  payout: string;
  status: string;
}
export interface RejectedOrExpiredData {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateRejectedOrExpired: string;
  service: string;
  payout: string;
  status: string; 
  pending_tab_status: string;
}

export interface ResponseBESp{
  accepted_total?: number,
  rejected_or_expired_total?: number,
  pending_total?: number,
  deleted_total?: number,
  total_deals: number;

  accepted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  rejected_or_expired: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  deleted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  pending: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
}
export interface ResponseDataBE {
  total_deals: number;
  ongoing_total?: number,
  pending_hire_total?: number,
  deleted_total?: number,
  draft_total?: number,
  completed_total?: number,

  ongoing: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  pending_hire: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  deleted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  completed: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  draft: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
}
export interface OngoingOrPendingHireOrDeletedOrCompletedOrDraft {
  data?: (DataEntity)[] | null;
}
export interface DataEntity {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  id: number;
  updated_at: string;
  application_type: string;
  registration_type: string;
  vehicle_vin: string;
  vehicle_type: string;
  vehicle_year: string;
  vehicle_weight_empty: string;
  vehicle_weight_gross: string;
  sale_type: string;
  sold_as: string;
  msrp: string;
  tsp: string;
  seller_zip: string;
  owner_first_name: string;
  owner_last_name: string;
  purchaser_zip: string;
  first_owner?: null;
  second_owner?: null;
  make?: null;
  odometer?: null;
  body_type?: null;
  is_draft: boolean;
  deal_documents?: (null)[] | null;
  date_created: string;
  deal_status: string;
  from_state: string;
  to_state: string;
  dealership_detail: DealershipDetail;
  account: Account;
  deal_documents_ids?: (null)[] | null;
  fees_data?: null;
  service_provider?: null;
  service?: null;
  date_accepted?: null;
}
export interface DealershipDetail {
  data: Dataa;
}
export interface Dataa {
  id: string;
  type: string;
  attributes: Attributes1;
}
export interface Attributes1 {
  dealership_name: string;
  business_credentials: string;
  deal_type?: (string)[] | null;
  created_at: string;
  updated_at: string;
  dealership_users: number;
  addresses: Addresses;
  bank_information: BankInformation;
  dealership_logo?: null;
  w9_forms?: (null)[] | null;
}
export interface Addresses {
  data?: (DataEntity1)[] | null;
}
export interface DataEntity1 {
  id: string;
  type: string;
  attributes: Attributes2;
}
export interface Attributes2 {
  address: string;
  address_type: string;
  country: string;
  city: string;
  state: string;
  zipcode: string;
  zipcode_ext: string;
}
export interface BankInformation {
  data?: (null)[] | null;
}
export interface Account {
  data: Data1;
}
export interface Data1 {
  id: string;
  type: string;
  attributes: Attributes3;
}
export interface Attributes3 {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name: string;
  role: string;
  profile_picture?: null;
  group_created_on: string;
  is_dealership_pending: boolean;
  dealership_data?: null;
  solo_dealership_id?: null;
  is_any_dealership: boolean;
  solo_dealership_data?: null;
  assigned_dealership_ids?: null;
}

interface TableType{
  id: number,
  page:number,
  totalRecords:number
}

interface ResponseError{
  errors:[
    {message: string}
  ]
}

type FilterOptions = {
  services: string[];
  states: string[];
  dealership: string[];
  serviceProvider: string[];
  dateCreated: string[];
  status: string[];
};

interface GetDealsParams {
  page: number;
  rowperpage: number;
  currentTab: string;
  startdate?: string;
  enddate?: string;
  services?: string[];
  statuss?: string[];
  dealerid?: string[];
  mainSearch?: string;
  service_provider?: string[];
  application_type?: string[];
  vehicle_info_type?: string[];
  pending_hire_status?: string[];
  sort_by?: string;
  sort_direction?: string;
  to_state?: string[];
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profilePhoto:string | null |undefined;
  tabvalue: number;
  searchValue: string,
  sortColumn: string,
  filterState: string,
  page: number,
  rowsPerPage:number,
  role:string,
  totalRecords: number,

  sortOption:string,
  totaldeals:number,
  loading: boolean,
  currentTab:string,
  tables: TableType[],
  ongoing_total: number | undefined,
  pending_hire_total: number| undefined,
  deleted_total: number| undefined,
  draft_total: number| undefined,
  completed_total: number| undefined,

  accepted_total: number | undefined,
  rejected_or_expired_total: number | undefined

  tableData: TableRows[],
  tablePendingData: TablePendingDataRow[],
  tableDataCompleted: any,
  tableDataDeleted: TableDataDeletedorCompleted[],
  tableDataDraft: TableDraftDataRow[],

  tableDataAcceptedSp:TableDataAcceptedSp[],
  tablePendingDataSp: TablePendingDataSp[],
  deletedDatatableSp:DeletedDatatableSp[],
  tableRejectedorExpiredDataSp: RejectedOrExpiredData[],

  anchorEl: null | HTMLElement , 
  selectedTabFilter: any, 
  selectedFilters: any,
  filterOptions:any,
  dealerShipList:any,
  filterSearchTerm:string,
  isAppliedClick:boolean,
  dateRange: [Date|undefined|null , Date |undefined|null]; 

  sortdirection:string,

  saveddeals:any,
  dealerships:any
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AlldealsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  changeNameApiCallId:string="";
  getuserDataApiCallId:string="";
  sendContactUsApiCallId:string="";
  getDealDataApiCallId:string="";
  getSpApiCallId:string="";
  getToStatesApiCallId:string="";
  getDealershipListApiCallId:string="";
  withDrawDealCallId: string = "";

  apiGetDealershipsId:string="";
  apiGetSavedDealsId:string="";
  acceptOrRejectdealApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      profilePhoto:'',
      tabvalue:0,
      searchValue: '',
      sortColumn: '',
      filterState: '',
      page:0,
      rowsPerPage:8,
      totalRecords: 0,
      role:'',

      sortOption:'',
      totaldeals:0,
      loading: true,
      currentTab:'',
      ongoing_total: 0,
      pending_hire_total: 0,
      deleted_total: 0,
      draft_total: 0,
      completed_total: 0,

      accepted_total: 0,
      rejected_or_expired_total: 0,
      tables: [
        {id:1,  page: 0, totalRecords: 1 },
        {id:2,   page: 0, totalRecords: 1 },
        {id:3,   page: 0, totalRecords: 1 },
        {id:4,   page: 0, totalRecords: 1 },
        { id:5,  page: 0, totalRecords: 1 },
      ],

    tableDataAcceptedSp : [],
    tableData : [],
    tablePendingData : [],
    tableDataCompleted:[],
    tableDataDeleted : [],
    tableDataDraft : [],
    tablePendingDataSp : [],
    deletedDatatableSp : [],
    tableRejectedorExpiredDataSp : [],


    anchorEl: null, 
    selectedTabFilter: 'dealership',
    selectedFilters: {
      ongoing: { dealership: [], services: [], toStates: [], serviceProvider: [], dateCreated: [], status: [] },
      pending_hire: { dealership: [], services: [], toStates: [], serviceProvider: [], dateCreated: [], status: [] },
      deleted: { dealership: [], services: [], toStates: [], serviceProvider: [], dateCreated: []},
      completed: {dealership: [], services: [], toStates: [], serviceProvider: [], dateCreated: []},
      draft: {dealership: [], toStates: [], dateCreated:[] ,typeOfVehicle: [], applicationType: []},

      pending: { dealership: [], services: [], toStates: [], dateReceived: [] },
      accepted: { dealership: [], services: [], toStates: [],dateAccepted: [],status: []},
      rejected_or_expired:{dealership: [], services: [], toStates: [], dateRejectedOrExpired:[], status:[]},
    },
    filterOptions: {
      ongoing: { dealership: [],services: ["Runner", "Full Service", "Onsite", "White Glove"], toStates: [], serviceProvider: [], dateCreated: ["Last week"], status: ["In Progress", "Issue Sent To Dealer", "Resolved, In Dealer Review", "Dealer Flags Incompete"] },
      pending_hire: {dealership: [],services: ["Runner", "Full Service", "Onsite", "White Glove"], toStates: [], serviceProvider: [], dateCreated: ["Last week"], status: ["Rejected", "No Response"]  },
      deleted: {dealership: [],services: ["Runner", "Full Service", "Onsite", "White Glove"], toStates: [], serviceProvider: [], dateCreated: ["Last week"]},
      completed: {dealership: [],services: ["Runner", "Full Service", "Onsite", "White Glove"], toStates: [], serviceProvider: [], dateCreated: ["Last week"]},
      draft:{dealership: [], toStates: [],dateCreated: ["Last week"], typeOfVehicle: ["Motor Vehicle or Motorcycle", "Motorhomes or Trailers"], applicationType:["Title Only", "Title and Registration"]},

      pending:{dealership: ["Dealership 1"],services: ["Runner", "Full Service", "Onsite", "White Glove"], toStates: [], dateReceived: ["Last week"]},
      accepted: {dealership: ["Dealership 3"], services: ["Runner", "Full Service", "Onsite", "White Glove"], toStates: [],  dateAccepted: ["Today"],status: ["In Progress", "Issue Sent To Dealer", "Resolved, In Dealer Review", "Dealer Flags Incompete", "Completed"]},
      rejected_or_expired:{dealership: ["Dealership 1"],services: ["Runner", "Full Service", "Onsite", "White Glove"], toStates: [], dateRejectedOrExpired: ["Last week"],status: ["Rejected", "Expired"]},
    },
    dealerShipList:[],
    filterSearchTerm :'',
    isAppliedClick:false,
    dateRange: [new Date(), null],
    sortdirection:'asc',

    saveddeals:[],
    dealerships:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.getuserDataApiCallId){
            this.handleUserData(responseJson);
        }
        else if(apiRequestCallId===this.getDealDataApiCallId){
          this.handleDealdata(responseJson)
        }
        else if(apiRequestCallId===this.getSpApiCallId){
          this.handleSpNames(responseJson)
        }
        else if(apiRequestCallId===this.getToStatesApiCallId){
          this.handleToStatesGet(responseJson)
        }
        else if(apiRequestCallId===this.getDealershipListApiCallId){
          this.handleDealershipList(responseJson)
        }
        else if(apiRequestCallId===this.apiGetDealershipsId){
          this.handlegetdealership(responseJson)
         }
         else if(apiRequestCallId===this.apiGetSavedDealsId){
          this.handledaveddeals(responseJson)
         }
         else if(apiRequestCallId===this.withDrawDealCallId){
          this.getDealsFromBe({page: this.state.tables[this.state.tabvalue].page, rowperpage:this.state.rowsPerPage, currentTab:this.state.currentTab, startdate:'', enddate:'', services:[], statuss:[], dealerid:[], mainSearch:'', service_provider:[], application_type:[],vehicle_info_type:[],pending_hire_status:[], sort_by:'', sort_direction:'', to_state:[]});
         }
         else if(apiRequestCallId===this.acceptOrRejectdealApiCallId){
          this.getDealsFromBe({page: this.state.tables[this.state.tabvalue].page, rowperpage:this.state.rowsPerPage, currentTab:this.state.currentTab, startdate:'', enddate:'', services:[], statuss:[], dealerid:[], mainSearch:'', service_provider:[], application_type:[],vehicle_info_type:[],pending_hire_status:[], sort_by:'', sort_direction:'', to_state:[]});
         }
      }
    } 

    // Customizable Area End
  }

  // Customizable Area Start
  acceptOrRejectdeal=async(spId:any, action:string)=>{
    const authtokn = await getStorageData('authToken')
    const Webheader = {
      "token": authtokn
    };
    const formData = new FormData()
    formData.append("status", action)

    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptOrRejectdealApiCallId = WebrequestMessage.messageId;
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/select_service_providers/update_deal_status/${spId}`
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  withdrawDeal = async (id: string) =>{
    const authtok = await getStorageData('authToken')
    const Webheader = {
      "token": authtok
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData = new FormData();
    formData.append("status", 'withdrawn');

    this.withDrawDealCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/select_service_providers/update_deal_status/' + id
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }
  hireSP = (states: string, id: string) =>{
    const fromState = states.split(">")[1].trim();
    localStorage.setItem('state_code', fromState);
    localStorage.setItem('createdDealId', id);
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ServiceProviderDashboard");
    this.send(msg);
  }

  continueCreateDeal = (id: string)=>{
    localStorage.setItem("dId",id);
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CreateDealForm");
    this.send(msg);
  }
  handlegetdealership=(responseJson:any)=>{
    this.setState({dealerships:responseJson.dealerships?.data ? responseJson.dealerships.data : [] })
  }
  handledaveddeals=(responseJson:any)=>{
    this.setState({ saveddeals: responseJson.data?responseJson.data:[] });
  }

  handleDealdata=(responseJson:ResponseDataBE & ResponseBESp & ResponseError)=>{
    if(responseJson.errors){
      this.setState({totaldeals: 0, loading: false})
    }
    else if(this.checkRole()){
      this.setState({totaldeals: responseJson.total_deals, loading: false,
        ongoing_total: responseJson.ongoing_total,
        pending_hire_total: responseJson.pending_hire_total,
        deleted_total: responseJson.deleted_total,
        draft_total: responseJson.draft_total,
        completed_total: responseJson.completed_total,
      })
      this.setArgs(responseJson.ongoing_total, responseJson.pending_hire_total, responseJson.deleted_total,responseJson.completed_total, responseJson.draft_total)
      this.setOngoingdata(responseJson)
      this.setPendingData(responseJson)
      this.setDeleteddata(responseJson)
      this.setCompletedData(responseJson)
      this.setDraftdata(responseJson)

    }else if(this.state.role.includes('service_provider')){
      this.setState({
        totaldeals: responseJson.total_deals, 
        loading: false,
        accepted_total: responseJson.accepted_total,
        pending_hire_total: responseJson.pending_total,
        deleted_total: responseJson.deleted_total,
        rejected_or_expired_total: responseJson.rejected_or_expired_total
      })
      this.setArgs(responseJson.accepted_total, responseJson.pending_total, responseJson.rejected_or_expired_total, responseJson.deleted_total, 0)
      this.setAcceptedSpData(responseJson)
      this.setPendingDataSp(responseJson)
      this.setRejectedExpiredDataSp(responseJson)
      this.setDeletedDataSp(responseJson)
    }
  }

  navigateToDealDashboard = (id: string) =>{
    localStorage.setItem("createdDealId", id);
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectTemplatesDealDashboard"
    );
    this.send(msg);
  }

  setArgs = (value1: number|undefined, value2:number|undefined, value3:number|undefined, value4:number|undefined, value5:number|undefined) => {
    const valueMap: (number | undefined)[] = [value1, value2, value3, value4, value5];
    const updatedTables: TableType[] = this.state.tables.map((table): TableType => {
      const newValue = valueMap[table.id - 1];
      return { ...table, totalRecords: newValue ?? table.totalRecords };
  });

  this.setState({ tables: updatedTables });
  };

  convertToReadableFormat(input:string) {
    return input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); 
  }

  setOngoingdata=(responseJson:ResponseDataBE)=>{
    if(responseJson.ongoing.data) {
      const transformedData = responseJson.ongoing.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(),
        dateAccepted: deal.attributes.date_accepted ? new Date(deal.attributes.date_accepted).toLocaleDateString() : "-",
        service: deal.attributes.select_provider_service || "-",
        fees: deal.attributes.fees_data?.total_fee || "N/A",
        serviceProvider: deal.attributes.service_provider || "-",
        status: this.convertToReadableFormat(deal.attributes.deal_status) 
      }));
      this.setState({tableData: transformedData})
  }
  }

  setPendingData = (responseJson:ResponseDataBE) => {
    if(responseJson.pending_hire.data) {
      const transformedPendingdata = responseJson.pending_hire.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateSent: new Date(deal.attributes.date_created).toLocaleDateString(), 
      service: deal.attributes.select_provider_service,
      fees: deal.attributes.fees_data ? '$123' : "-", 
      serviceProvider: deal.attributes.service_provider || "N/A",
      status: deal.attributes.pending_tab_status,
    }));
    this.setState({tablePendingData: transformedPendingdata})
  }
  };

  setDeleteddata = (responseJson:ResponseDataBE)=>{
    if(responseJson.deleted.data){
    const transformedDeleteddata = responseJson.deleted.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(), 
      dateCancelled: new Date(deal.attributes.date_cancelled).toLocaleDateString(),
      service: deal.attributes.select_provider_service || '-',
      fees: deal.attributes.fees_data ? '$123' : "-", 
      serviceProvider: deal.attributes.service_provider || "N/A",
      status: 'Deleted'
    }));
    this.setState({tableDataDeleted: transformedDeleteddata})
  }
  };

  setCompletedData=(responseJson:ResponseDataBE)=>{
    if (responseJson.completed.data) {
      const transformedCompletedData = responseJson.completed.data.map((deal:any) => ({
        id: deal.attributes.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail.data.attributes.dealership_name || "N/A",
        states: `${deal.attributes.from_state || "N/A"} > ${deal.attributes.to_state || "N/A"}`,
        dateCreated: deal.attributes.date_created ? new Date(deal.attributes.date_created).toLocaleDateString() : "N/A",
        dateCompleted: deal.attributes.date_completed ? new Date(deal.attributes.date_completed).toLocaleDateString() : "N/A",
        service: deal.attributes.select_provider_service,
        fees: deal.attributes.fees_data ? '$123' : "-",
        serviceProvider: deal.attributes.service_provider?.data?.attributes?.business_name || "N/A",
        status: 'Completed'
      }));
      this.setState({ tableDataCompleted: transformedCompletedData });
    }
  }

  setDraftdata=(responseJson:ResponseDataBE)=>{
    if(responseJson.draft.data){
    const transformedDraftddata = responseJson.draft.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(), 
      typeOfVehicle: deal.attributes.vehicle_type,
      applicationType: deal.attributes.application_type,
    }));
    this.setState({tableDataDraft: transformedDraftddata})
  }
  }

  setAcceptedSpData=(responseJson: ResponseBESp)=>{
    if(responseJson.accepted.data) {
      const transformedacceptedData = responseJson.accepted.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateReceived: new Date(deal.attributes.date_received).toLocaleDateString(),
        dateAccepted: deal.attributes.date_accepted ? new Date(deal.attributes.date_accepted).toLocaleDateString() : "-",
        service: deal.attributes.select_provider_service || "-",
        fees: deal.attributes.fees_data[0]?.total_fee || "-",
        payout: deal.attributes.payout || "-",
        status: this.convertToReadableFormat(deal.attributes.deal_status) 
      }));
      this.setState({tableDataAcceptedSp: transformedacceptedData})
  }
  }

  setPendingDataSp=(responseJson: ResponseBESp)=>{
    if(responseJson.pending.data) {
      const transformedPendingSpData = responseJson.pending.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateReceived: new Date(deal.attributes.date_received).toLocaleDateString(),
        service: deal.attributes.select_provider_service,
        payout: deal.attributes.payout || "N/A",
        status: 'Available',
        selected_service_provider_id: deal.attributes.selected_service_provider_id
      }));
      this.setState({tablePendingDataSp: transformedPendingSpData})
  }
  }

  setRejectedExpiredDataSp=(responseJson: ResponseBESp)=>{
    if(responseJson.rejected_or_expired.data) {
      const transformedrejectedExpiredData = responseJson.rejected_or_expired.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(),
        dateRejectedOrExpired: deal.attributes.date_rejected_or_expired ? new Date(deal.attributes.date_rejected_or_expired).toLocaleDateString() : "-",
        service: deal.attributes.select_provider_service || "-",
        payout: deal.attributes.payout || "N/A",
        status: this.convertToReadableFormat(deal.attributes.deal_status),
        pending_tab_status: deal.attributes.pending_tab_status === 'Rejected' ? 'Rejected' : 'Expired'
      }));
      this.setState({tableRejectedorExpiredDataSp: transformedrejectedExpiredData})
  }
  }

  setDeletedDataSp=(responseJson: ResponseBESp)=>{
    if(responseJson.deleted.data) {
      const transformedDeletedSpData = responseJson.deleted.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(),
        dateDeleted: deal.attributes.date_deleted ? new Date(deal.attributes.date_deleted).toLocaleDateString() : "-",
        service: deal.attributes.select_provider_service || "-",
        fees: deal.attributes.fees_data[0]?.total_fee || "N/A",
        payout: deal.attributes.payout || "N/A",
        status: this.convertToReadableFormat(deal.attributes.deal_status) 
      }));
      this.setState({deletedDatatableSp: transformedDeletedSpData})
  }
  }

  handlefilterSearchTerm=(event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    this.setState({ filterSearchTerm: event.target.value })
  }

  handleSpNames=(responseJson: {business_names:string[]})=>{
    const { business_names } = responseJson;
    const uniqueBusinessNames = new Set(business_names);
    const uniqueBusinessNamesArray = Array.from(uniqueBusinessNames);

    if (this.state.role === 'dealer' || this.state.role === 'dealer_admin') {
      this.setState((prevState) => {
        const updatedFilterOptions = { ...prevState.filterOptions };
        const tabsWithServiceProvider = ['ongoing', 'pending_hire', 'deleted', 'completed'];
        tabsWithServiceProvider.forEach((tab) => {
          if (updatedFilterOptions[tab]) {
            updatedFilterOptions[tab].serviceProvider = uniqueBusinessNamesArray;
          }
        });
        return { filterOptions: updatedFilterOptions };
      });
    }
  }

  handleToStatesGet=(responseJson:{to_states:string[]})=>{
    const { to_states } = responseJson;
      this.setState((prevState) => {
        const updatedFilterOptions = { ...prevState.filterOptions };
        const tabsWithToStates = [
          'ongoing', 'pending_hire', 'deleted', 
          'completed', 'draft', 'accepted', 'pending', 'rejected_or_expired'
        ];
        tabsWithToStates.forEach((tab) => {
          if (updatedFilterOptions[tab]) {
            updatedFilterOptions[tab].toStates = to_states;
          }
        });
        return { filterOptions: updatedFilterOptions };
      });
  }

  handleDealershipList=(responseJson: {dealership_data: any[]})=>{
    const dealershipArray = responseJson.dealership_data.map(dealer => ({id: dealer.id,name: dealer.name}));
    if(dealershipArray) this.setState({dealerShipList: dealershipArray});

    const namesArray = responseJson.dealership_data.map(dealer => dealer.name);
    this.setState((prevState) => {
      const updatedFilterOptions = { ...prevState.filterOptions };
      const tabsWithdealerships = [
        'ongoing', 'pending_hire', 'deleted', 
        'completed', 'draft', 'pending', 'accepted', 'rejected_or_expired'
      ];
      tabsWithdealerships.forEach((tab) => {
        if (updatedFilterOptions[tab]) {
          updatedFilterOptions[tab].dealership = namesArray;
        }
      });
      return { filterOptions: updatedFilterOptions };
    });
  }

  handleDateChange = (value: any, event: React.SyntheticEvent) => {
    if(value){
      this.setState({ dateRange: value });
    }
    if (value && value.length === 2) {
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      const formattedDateRange = `${value[0].toLocaleDateString('en-GB', options)} - ${value[1].toLocaleDateString('en-GB', options)}`;
  
      const deleteddateField = this.checkRole() ? 'dateCreated' : 'dateDeleted'
      const dateFieldMapping: { [key: string]: string } = {
        ongoing: "dateCreated",
        pending_hire: "dateCreated",
        deleted: deleteddateField,
        completed: "dateCreated",
        draft: "dateCreated",
        pending: "dateReceived",
        accepted: "dateAccepted",
        rejected_or_expired: "dateRejectedOrExpired"
      };

      const dateFieldKey = dateFieldMapping[this.state.currentTab];
      if (dateFieldKey) {
        this.setState((prevState) => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            [prevState.currentTab]: {
              ...prevState.selectedFilters[prevState.currentTab],
              [dateFieldKey]: [formattedDateRange] 
            }
          }
        }));
      }
    }
  };

  handleUserData=(responseJson:GetUserResponseSuccess & GetUserError)=>{
    if(!responseJson.errors){
      this.setState({role:responseJson.data.attributes.role}, ()=>{

        if(this.state.role==='service_provider' || this.state.role==='service_provider_admin'){
          this.setState((prevState) => ({
            selectedFilters: {
              ...prevState.selectedFilters,
              deleted: { dealership: [], services: [], toStates: [], dateDeleted: [] }
            },
            filterOptions: {
              ...prevState.filterOptions,
              deleted: { dealership: [], services: ["Runner", "Full Service", "Onsite", "White Glove"], toStates: [], dateDeleted: [] }
            }
          }));
        }

        this.setCurrentTab(0);

        this.getServiceProviderList();
        this.getToStates();
        this.getDealershipList();
        this.getDealsFromBe({page: this.state.tables[this.state.tabvalue].page, rowperpage:this.state.rowsPerPage, currentTab:this.state.currentTab, startdate:'', enddate:'', services:[], statuss:[], dealerid:[], mainSearch:'', service_provider:[], application_type:[],vehicle_info_type:[],pending_hire_status:[], sort_by:'', sort_direction:'', to_state:[]});
      })
    }
  }


  getDealershipsData = ()=> {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDealershipsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/dealer_informations/get_dealerships?page=1&per_page=10'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSavedDealsData = () => {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSavedDealsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_posts/deals/get_saved_deals'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getToStates=async()=>{
    const authtokenn = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": authtokenn
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getToStatesApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_posts/deals/fetch_to_states_filter'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  getServiceProviderList=async()=>{
    const authtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": authtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSpApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_posts/deals/fetch_service_provider'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  getDealershipList=async()=>{
    const authtok = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": authtok
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealershipListApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_posts/deals/dealership_data'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  parseServiceString = (serviceString: string): string => {
    let parsedValue = "-"; 
    if (serviceString) {
      const validJsonString = serviceString.replace(/=>/g, ":");
      if (validJsonString.startsWith("{") && validJsonString.endsWith("}")) {
        const serviceObject = JSON.parse(validJsonString);
        const firstKey = Object.keys(serviceObject)[0];
        parsedValue = firstKey || "-";
      }
    }
    return parsedValue;
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ 
        tabvalue: newValue, 
        searchValue: '', 
        sortColumn: '',
        filterState: '',
        selectedTabFilter:'dealership',
        filterSearchTerm:'',
        dateRange:[undefined,undefined]
    },
    ()=>{this.setCurrentTab(this.state.tabvalue);});
};

setCurrentTab=(tabval:number)=>{
  if(this.checkRole()){
   switch (tabval) {
    case 0:
      this.setState({currentTab: 'ongoing'})
      break;
    case 1:
      this.setState({currentTab: 'pending_hire'})
      break;
    case 2:
      this.setState({currentTab: 'deleted'})
      break;
    case 3:
      this.setState({currentTab: 'completed'})
      break;
    case 4:;
      this.setState({currentTab: 'draft'})
      break;
   }

  }else if(this.state.role === 'service_provider' || this.state.role === 'service_provider_admin'){
    switch (tabval) {
      case 0:
        this.setState({currentTab: 'accepted'})
        break;
      case 1:
        this.setState({currentTab: 'pending'})
        break;
      case 2:
        this.setState({currentTab: 'rejected_or_expired'})
        break;
      case 3:
        this.setState({currentTab: 'deleted'})
        break;
     }
  }
}

  a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value},()=>{
      this.getDealsFromBe(
        {
        rowperpage:this.state.rowsPerPage,
        page:this.state.tables[this.state.tabvalue].page,   
        startdate:this.getStartandEndDate('start'), 
        enddate:this.getStartandEndDate('end'), 
        currentTab:this.state.currentTab,
         
        statuss:this.state.selectedFilters[this.state.currentTab].status, 
        services:this.state.selectedFilters[this.state.currentTab].services,
        dealerid:[] , 
        mainSearch:this.state.searchValue,  
        
        application_type:this.state.selectedFilters[this.state.currentTab].applicationType, 
        service_provider:this.state.selectedFilters[this.state.currentTab].serviceProvider, 
        vehicle_info_type:this.state.selectedFilters[this.state.currentTab].typeOfVehicle,
        
        pending_hire_status:[], 
        sort_by:this.getsortoption(), 
        sort_direction:this.getsortdirection(), 
        to_state:this.state.selectedFilters[this.state.currentTab].toStates
      } );

    });

};


handleSort = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  this.setState({ sortOption: event.target.value as string }, ()=>{
    this.getDealsFromBe({
        page:this.state.tables[this.state.tabvalue].page, 
        rowperpage:this.state.rowsPerPage, 
        currentTab:this.state.currentTab,  
        enddate:this.getStartandEndDate('end'), 
        startdate:this.getStartandEndDate('start'), 
        services:this.state.selectedFilters[this.state.currentTab].services, 
        statuss:this.state.selectedFilters[this.state.currentTab].status, 
        dealerid:[], 
        mainSearch:this.state.searchValue,  
        service_provider:this.state.selectedFilters[this.state.currentTab].serviceProvider, 
        application_type:this.state.selectedFilters[this.state.currentTab].applicationType, 
        vehicle_info_type:this.state.selectedFilters[this.state.currentTab].typeOfVehicle, 
        sort_by:this.getsortoption(), 
        pending_hire_status:[],
        sort_direction:this.getsortdirection() , 
        to_state:this.state.selectedFilters[this.state.currentTab].toStates
    });
  });
};

handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  this.setState({
    anchorEl: event.currentTarget
  });
};

handleClose = (event:{stopPropagation:()=>void}) => {
  event.stopPropagation();
  this.setState({
    anchorEl: null
  });
};

handleApply=(event:{stopPropagation:()=>void})=>{
  event.stopPropagation();
  this.setState({anchorEl:null, loading:true})

  const { dealerShipList } = this.state;
  const selectedDealershipNames = this.state.selectedFilters[this.state.currentTab].dealership;
  const selectedDealershipIds = dealerShipList.filter((dealer:any) => selectedDealershipNames.includes(dealer.name)).map((dealer:any) => dealer.id);
 this.getDealsFromBe({
  page:this.state.tables[this.state.tabvalue].page, 
  rowperpage:this.state.rowsPerPage, 
  currentTab:this.state.currentTab,  
  startdate:this.getStartandEndDate('start'), 
  enddate:this.getStartandEndDate('end'), 
  services:this.state.selectedFilters[this.state.currentTab].services, 
  statuss:this.state.selectedFilters[this.state.currentTab].status, 
  dealerid:selectedDealershipIds, 
  mainSearch:this.state.searchValue,  
  service_provider:this.state.selectedFilters[this.state.currentTab].serviceProvider, 
  application_type:this.state.selectedFilters[this.state.currentTab].applicationType, 
  vehicle_info_type:this.state.selectedFilters[this.state.currentTab].typeOfVehicle, 
  pending_hire_status:[], 
  sort_by:this.getsortoption(), 
  sort_direction:this.getsortdirection(), 
  to_state:this.state.selectedFilters[this.state.currentTab].toStates
});
}

getsortoption = ()=>{
  const role = this.state.role
  const sortoption = this.state.sortOption 

  if(role==='dealer' || role==='dealer_admin'){
    switch (sortoption) {
      case 'Date Created':
        return this.state.tabvalue==0 ? 'date_sent' : 'date_created'

      case 'Date Sent':
        return 'date_sent'

      case 'Date Cancelled':
        return 'date_cancelled'

      case 'Date Completed':
        return 'date_completed'
      
      case "Customer's last name":
        return 'customer_last_name'
    
      default:
        return this.state.sortOption.toLowerCase().replace(/\s+/g, '_');
    }
  }
  else{
    switch (sortoption) {
      case 'Date Received':
        return'date_sent'

      case 'Date Rejected or Expired':
        return 'date_rejected_expired'

      case 'Date Deleted':
        return 'date_deleted'

      case "Customer's last name":
        return 'customer_last_name'
    
      default:
        return this.state.sortOption.toLowerCase().replace(/\s+/g, '_');
    }
  }
}

getsortdirection = ()=>{
  const role = this.state.role

  const sortoption = this.state.sortOption 

  if(role==='dealer' || role==='dealer_admin'){
    switch (sortoption) {
      case 'Date Created':
        return 'desc'

      case 'Date Sent':
        return 'desc'

      case 'Date Cancelled':
        return 'desc'

      case 'Date Completed':
        return 'desc'
    
      default:
        return 'asc'
    }
  }
  else{
    switch (sortoption) {
      case 'Date Received':
        return'desc'

      case 'Date Rejected or Expired':
        return 'desc'

      case 'Date Deleted':
        return 'desc'
    
      default:
        return 'asc'
    }
  }
}

handleTabSwitch = (tab:string) => {
  this.setState({ selectedTabFilter: tab, filterSearchTerm:'' });
};

clearAllFilters = () => {
  const { currentTab, selectedFilters } = this.state;
  const currentFiltersKeys = Object.keys(selectedFilters[currentTab]);
  const clearedFilters = currentFiltersKeys.reduce((acc:any, key) => {
    acc[key] = [];
    return acc;
  }, {});
  this.setState((prevState) => ({
    selectedFilters: {
      ...prevState.selectedFilters, 
      [currentTab]: clearedFilters
    }
  }));
};

handleFilterChange = (mainTab: string, category: string, value: string) => {
  const { selectedFilters } = this.state;
  const newSelection = selectedFilters[mainTab][category].includes(value)
    ? selectedFilters[mainTab][category].filter((valu:any) => valu !== value)
    : [...selectedFilters[mainTab][category], value];

  this.setState((prevState) => ({
    selectedFilters: {
      ...prevState.selectedFilters,
      [mainTab]: {
        ...prevState.selectedFilters[mainTab],
        [category]: newSelection
      }
    }
  }));
};

handleClearCategory = (mainTab: string, category: string) => {
  this.setState((prevState) => ({
    selectedFilters: {
      ...prevState.selectedFilters,
      [mainTab]: {
        ...prevState.selectedFilters[mainTab],
        [category]: []
      }
    }
  }));
};

handleSelectAll = (mainTab: string, tabFilter: string) => {
  const { filterOptions, selectedFilters } = this.state;
  const options = filterOptions[mainTab][tabFilter];
  const allSelected = selectedFilters[mainTab][tabFilter].length === options.length;
  this.setState((prevState) => ({
    selectedFilters: {
      ...prevState.selectedFilters,
      [mainTab]: {
        ...prevState.selectedFilters[mainTab],
        [tabFilter]: allSelected ? [] : options
      }
    }
  }));
};


formatString = (input:string)=>{
  return input
    .replace(/([A-Z])/g, ' $1') 
    .replace(/^./, str => str.toUpperCase()); 
}

formatStringToLowerCase(input:string) {
  return input
    .replace(/([A-Z])/g, ' $1') 
    .toLowerCase()               
    .trim();                   
}

checkDisablitiy = (status:string, field:string)=>{
    if(status==='Rejected' && field==='withdraw'){
        return true
    }
    else  if(status==='Rejected' && field==='hirenew'){
        return false
    }
    else  if(status==='No Response' && field==='withdraw'){
        return false
    } 
    else return true
}


  handleChangePage = (event: unknown, newPage: number) => {
    const { tabvalue } = this.state;
    this.setState((prevState) => {
      const updatedTables = prevState.tables.map((table, index) => {
        if (index === tabvalue) {
          return { ...table, page: newPage };
        }
        return { ...table, page: 0 };
      });
      return { tables: updatedTables, loading: true };
    }, () => {
      const { tables, rowsPerPage, currentTab } = this.state;
      this.getDealsFromBe({
        page:tables[tabvalue].page, 
        rowperpage:rowsPerPage, currentTab,  
        startdate:this.getStartandEndDate('start'), 
        enddate:this.getStartandEndDate('end'), 
        services:this.state.selectedFilters[this.state.currentTab].services, 
        dealerid:[],
        pending_hire_status:[],
        statuss:this.state.selectedFilters[this.state.currentTab].status,  
        mainSearch:this.state.searchValue,  
        service_provider:this.state.selectedFilters[this.state.currentTab].serviceProvider, 
        application_type:this.state.selectedFilters[this.state.currentTab].applicationType, 
        vehicle_info_type:this.state.selectedFilters[this.state.currentTab].typeOfVehicle,  
        sort_by:this.getsortoption(), 
        sort_direction:this.getsortdirection(), 
        to_state:this.state.selectedFilters[this.state.currentTab].toStates});
    });
  };
  
  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { tabvalue } = this.state;
    const rowsPerPage = parseInt(event.target.value, 8);
    this.setState((prevState) => {
      const updatedTables = prevState.tables.map((table, index) => {
        if (index === tabvalue) {
          return { ...table, page: 0 };
        }
        return { ...table, page:0 };
      });
      return { tables: updatedTables, rowsPerPage, loading: true };
    }, () => {
      const { tables, rowsPerPage, currentTab } = this.state;
      this.getDealsFromBe({
        page:tables[tabvalue].page, 
        rowperpage:rowsPerPage, currentTab ,  
        startdate:this.getStartandEndDate('start'), 
        enddate:this.getStartandEndDate('end'), 
        services:this.state.selectedFilters[this.state.currentTab].services, 
        statuss:this.state.selectedFilters[this.state.currentTab].status, 
        dealerid:[], 
        mainSearch:this.state.searchValue,  
        service_provider:this.state.selectedFilters[this.state.currentTab].serviceProvider , 
        application_type:this.state.selectedFilters[this.state.currentTab].applicationType, 
        vehicle_info_type:this.state.selectedFilters[this.state.currentTab].typeOfVehicle , 
        pending_hire_status:[], 
        sort_by:this.getsortoption() , 
        sort_direction:this.getsortdirection() , 
        to_state:this.state.selectedFilters[this.state.currentTab].toStates});
    });
  };

  async componentDidMount(){
    this.getUserDataFromBe();
    this.getDealershipsData()
    this.getSavedDealsData()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(prevState.role !== this.state.role){
        const dealType = localStorage.getItem('dealType');
        const event : React.ChangeEvent<{}> = {} as ChangeEvent;
        if(dealType){
          if(dealType === 'draft'){
            this.handleChange(event, 4);
          }
          if(dealType === 'pending'){
            this.handleChange(event, 1);
          }
        }
        localStorage.removeItem('dealType');
      }
  }

  getDealsFromBe=async({
    page,
    rowperpage,
    currentTab,
    startdate = '',
    enddate = '',
    services = [],
    statuss = [],
    dealerid = [],
    mainSearch = '',
    service_provider = [],
    application_type = [],
    vehicle_info_type = [],
    pending_hire_status = [],
    sort_by = '',
    sort_direction = '',
    to_state = [],
  }: GetDealsParams)=>{
    if (statuss && (statuss.includes('No Response') || statuss.includes('Rejected'))) {
      pending_hire_status = [...statuss]; 
      statuss = []; 
    }
    services=services===undefined?[]:services
    statuss = statuss === undefined ? [] : statuss.map(status =>
      status.toLowerCase().replace(/\s+/g, '_')
    ); 
    startdate = startdate===undefined ? '' : startdate
    enddate = enddate===undefined ? '' : enddate
    service_provider = service_provider===undefined ? [] : service_provider
    application_type = application_type===undefined?[]:application_type
    vehicle_info_type=vehicle_info_type===undefined?[]:vehicle_info_type
    sort_by=sort_by ? sort_by : ''
    sort_direction = sort_direction ? sort_direction : ''
    to_state = to_state === undefined ? [] : to_state

    
    const authtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": authtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/deals?page=${page + 1}&per_page=${rowperpage}&flag=${currentTab}&start_date=${startdate}&end_date=${enddate}&services=${services}&status=${statuss}&dealer_information_id=${dealerid}&search=${mainSearch}&service_provider=${service_provider}&application_type=${application_type}&vehicle_info_type=${vehicle_info_type}&pending_hire_status=${pending_hire_status}&sort_by=${sort_by}&sort_direction=${sort_direction}&to_state=${to_state}`
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  getUserDataFromBe=async()=>{
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  getStartandEndDate=(whichDate:string)=>{
    let value;
    if(this.state.role==='dealer' || this.state.role==='dealer_admin'){
     value = this.state.selectedFilters[this.state.currentTab].dateCreated
    }
    else if(this.state.currentTab==='accepted'){
      value = this.state.selectedFilters[this.state.currentTab].dateAccepted
    }
    else if(this.state.currentTab==='pending'){
      value = this.state.selectedFilters[this.state.currentTab].datePending
    }
    else if(this.state.currentTab==='deleted'){
      value = this.state.selectedFilters[this.state.currentTab].dateDeleted
    }
    else if(this.state.currentTab==='rejected_or_expired'){
      value = this.state.selectedFilters[this.state.currentTab].dateRejectedOrExpired
    }
    if(!value) return ''

    const [startDateStr, endDateStr] = String(value).split(" - ");
    return whichDate==='start' ? startDateStr : endDateStr
  }

checkRole = ()=>{
  return (this.state.role === 'dealer' || this.state.role === 'dealer_admin')
}

 getSortOptions = (tabIndex : number) => {
  if(this.checkRole()){
    switch(tabIndex) {
      case 0: 
        return ['Deal Id', "Customer's last name", 'Dealership', 'Date Created'];
      case 1: 
        return ['Deal Id', "Customer's last name", 'Dealership', 'Date Sent'];
      case 2: 
        return ['Deal Id', "Customer's last name", 'Dealership', 'Date Cancelled'];
      case 3: 
        return ['Deal Id', "Customer's last name", 'Dealership', 'Date Completed'];
      case 4: 
        return ['Deal Id', "Customer's last name", 'Dealership', 'Date Created'];
      default:
        return [];
    }
  }else{
  switch(tabIndex) {
    case 0: 
      return ['Dealership', 'Date Received', 'Payout'];
    case 1: 
      return ['Dealership', 'Date Received', 'Payout'];
    case 2: 
      return ['Dealership', 'Date Rejected or Expired', 'Payout'];
    case 3: 
      return ['Dealership', 'Date Deleted', 'Payout'];
    default:
      return [];
  }
  }
}
  // Customizable Area End

}