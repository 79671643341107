// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { ContentView, DealershipNormalized } from "./MyTeamController.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../packages/framework/src/Message";
import { runEngine } from "../../../../packages/framework/src/RunEngine";
export const webConfigJSON = require("./config.js");

export interface Props {
  data: any[]
  page: number
  itemsPerPage: number
  handleCheck: (itemId: string) => void
  isAdmin: () => boolean
  handleServiceableLocationEdit?: (index: string) => void
  handleDealershipEdit?: (dealershipId: string) => void
  showDealershipUsers?: (dealershipId: string) => void
  showLocationUsers?: (locationId: string) => void
  view: ContentView
  navigation?:any
}
interface S {
  pageData: DealershipNormalized[]
}
interface SS {}

export default class DealershipsTableController extends BlockComponent<Props, S, SS> {
  apiAllDealeshipsCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.state = {
      pageData: []
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const {page, itemsPerPage, data} = this.props;

    if(prevProps.data !== data || prevProps.page !== page || prevProps.itemsPerPage !== itemsPerPage) {
      this.setCurrentPageData()
    }
  }

  setCurrentPageData = () => {
    const {itemsPerPage, page} = this.props;
    const currentPageData = this.props.data.slice(page * itemsPerPage - itemsPerPage, page * itemsPerPage)

    this.setState({pageData: currentPageData})
  }

  handleNavigation = (pathname: string, param?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), pathname);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    param && message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    runEngine.sendMessage(message.id, message)
  };

}
// Customizable Area End