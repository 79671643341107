// Customizable Area Start
import React from "react";
import { withStyles, createStyles } from "@material-ui/core";
import ReviewModalController, {
  Props,
} from "./ReviewModalController.web";
import Modal from "react-modal";

const styles = createStyles({
  container: {},
});


export class ReviewModal extends ReviewModalController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { modalOpen } = this.props;

    return <Modal isOpen={modalOpen || false}
    onAfterOpen={this.afterOpenModal}
    onAfterClose={this.afterCloseModal}
    ariaHideApp={false}>
    <div ref={this.modalContainerRef}></div>
</Modal>;
  }
}

export default withStyles(styles)(ReviewModal);
// Customizable Area End