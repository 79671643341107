import React from "react";
// Customizable Area Start
import {
  Box,
  Avatar,
  withStyles,
  createStyles,
  Button,
  FormControl,
  Input,
  InputAdornment,
  Badge,
  Card,
  Typography,
  createTheme,
  ThemeProvider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  SvgIcon,
  IconButton,
  CircularProgress,
  Tooltip,
  responsiveFontSizes,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProjectTemplatesDealDashboardController, {
  Props,
  configJSON,
} from "./ProjectTemplatesDealDashboardController.web";
import CustomInfoComponent from "../../../components/src/CustomInfoComponent";
import { vitu, notifications, filterIcon } from "./assets";
import DealDashboardNote from "../../projectnotes/src/DealDashboardNote.web";
import DealDashboardTaskList from "../../tasks/src/DealDashboardTaskList.web";
import SearchIcon from "@material-ui/icons/Search";
import ProjectTemplatesDocumentsUpload from "../../bulkuploading/src/ProjectTemplatesDocumentsUpload.web";
import ServiceProviderMenu from "./ServiceProviderMenu";
import ServiceProviderHired from "./ServiceProviderHired";
import ServiceProviderHiring from "./ServiceProviderHiring";
import RegistrationFeeDrawer from "./RegistrationFeeDrawer";
import { Menu } from "@material-ui/icons";
import RegistrationFee from "./RegistrationFee";
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
import HamburgerMenuFull from "../../customisableuserprofiles/src/HamburgerMenuFull.web";

const classNames = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    minWidth: "1366px",
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 32px",
    borderBottom: "1px solid #F0FAFA",
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "48px",
  },

  headerMenuLogo: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px",
  },

  menuButton: {
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
    minWidth: "0",

    "& > img": {
      width: "100%",
      height: "100%",
    },
  },

  logo: {
    width: "52px",

    "& > img": {
      width: "100%",
    },
  },

  searchFilter: {
    width: "768px",
    height: "52px",
    padding: "14px 24px 14px 24px",
    borderRadius: "12px",
    backgroundColor: "#F0FAFA",
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
  },


  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",

    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(-40%, 30%)",
    },
  },

  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",

    "& > img": {
      width: "100%",
    },
  },

  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: "24px",
  },

  main: {
    marginTop: "25px",
    display: "flex",
    flexWrap: "nowrap",
    position: "relative",
    padding: "0px 35px",
    justifyContent: "space-between",
  },

  cardContainer: {
    display: "flex",
    padding: "25px 30px 25px 30px",
    justifyContent: "space-between",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px 0px #8989895C",
  },

  cardPanel: {
    boxShadow: "none",
  },
  progressMenuContainer: {
    display: "flex",
    alignItems: "center",
  },

  progressMenu: {
    padding: "6px 14px 6px 14px",
    gap: "4px",
    borderRadius: "26px",
    height: "32px",
    content: "none",

    "& .MuiSelect-selectMenu": {
      overflow: "initial",
    },

    "& .MuiSelect-select": {
      padding: 0,
    },

    "&::before": {
      content: "none",
    },

    "&::after": {
      content: "none",
    },

    "&:hover": {
      content: "none",
    },
  },
  inProgress: {
    color: "#346472",
    border: "1px solid #346472",
  },

  completed: {
    color: "#26BBB3",
    border: "1px solid #26BBB3",
  },

  deleted: {
    color: "#C43937",
    border: "1px solid #C43937",
  },

  mainLeftSide: {
    width: "70%",
    marginRight: "26px",
  },

  mainRightSide: {
    width: "30%",
  },

  hireSPSection: {
    padding: "35px 24px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px 0px #8989895C",
    background: "#FFFFFF",

    "& .MuiAccordionSummary-root": {
      padding: 0,
      height: "20px",
      minHeight: "0",
    },

    "& .MuiAccordionDetails-root": {
      padding: "0",
    },
  },
  findProviderBtn: {
      marginTop: "30px",
      width: "100%",
      height: "56px",
      background: "#013D4F !important",
      borderRadius: "8px",

      "& > span": {
        textTransform: "initial",
      },
  },

  disabledBtn:{
    background: "#F1F4F3 !important",
    color: "#8FA39C",
  },

  documentAddOns: {
    padding: "35px 30px 35px 30px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px 0px #8989895C",
    background: "#F1F4F3CC",
  },

  addOns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "30px",
  },

  addOn: {
    padding: "30px",
    borderRadius: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 12px 0px #C3C3C330",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  firstAddOn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  secondAddOn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  buyNowBtn: {
      marginTop: "26px",
      width: "100%",
      background: "#4FC8EC !important",
      borderRadius: "8px",
      textTransform: "initial",
      fontWeight: 700
  },

  documentsSection: {
      marginTop: "25px",
      marginBottom: "174px",
      padding: "40px 30px 35px 30px",
      height: "1008px",
      gap: "30px",
      borderRadius: "10px",
      background: "#FFFFFF",
      boxShadow: "0px 0px 4px 0px #8989895C"
  },

  documentSectionContent: {
      marginTop: "30px", display: "flex", justifyContent: "space-between", alignItems: "center"
  },

  leftSideSection: {
      width: '70%',
      height: "880px",
      borderRadius: "20px",
      border: "1px solid #F1F4F3",
      padding: "40px 24px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
  },

  dragAndDrop: {
      marginTop: "8px",
      fontFamily: "Gotham, sans-serif",
      fontSize: "24px",
      fontWeight: 300,
      lineHeight: "32px",
      letterSpacing: "-0.005em",
      textAlign: "center"
  },

  allowableStatement: {
      marginTop: "8px",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "18px",
      textAlign: "left",
      color: "#4B4C4B"
  },

  orStatement: {
      marginTop: "8px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      textAlign: "center"
  },

  uploadTitle: {
      color: "#C5CBC9",
      fontWeight: 300,
      fontSize: "20px",
      lineHeight: "18px",
      fontFamily: "Gotham, sans-serif",
  },

  rightSideSection: {
      marginLeft: "30px",
      height: "890px",
      borderRadius: "10px",
      border: "1px solid #E5E6E5",
      padding: "35px 30px",
      overflow: "auto"
  },

  basicInfoTypo: {
      fontSize: "18px",
      marginBottom: "30px",
      lineHeight:"18px" 
  },

  basicInfoValue: {
      fontSize: "20px",
      fontWeight: 500,
      marginLeft: "10px",
      color: "#4B4C4B"
  },

  boxInfoText: {
      fontSize: 16, 
      color: "#4B4C4B", 
      lineHeight: "20px", 
      textAlign: "left",
      fontWeight: 500,
      fontFamily: 'Roboto',
  },

  documentUploadDialog: {
      "& > div:nth-child(3) > div:nth-child(1)": {
          height: "450px",
          width: "600px",
          borderRadius: "8px",
          background: "#FFFFFF",
          boxShadow: "none",
          margin: 0,
          display: "block",
          flex: "none",
      }
  },

  documentUploadDialogHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "16px 16px 12px 0px",
  },

  closeIcon: {
      width : "32px",
      height: "32px",
  },

  documentUploadDialogTitle: {
      padding: 0,
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "32px",
      textAlign: "left",
      color: "rgba(1, 61, 79, 1)",
      letterSpacing: "-0.005em",
  },

  documentUploadDialogContent: {
      padding: "0px 44.5px"
  },

  browseFiles:{
      marginTop: "26px",
      marginBottom: "32px",
      gap: "8px",
      borderRadius: "8px",
      background: "#FFFFFF",
      border: "1px solid #C5CBC9",
      display: "flex",
      justifyContent: "center",
      padding: "11px 24px"
  },

  documentUploadDialogActions: {
      textTransform: "initial",
      padding: "24px 35px",
      borderTop: "1px solid rgba(242, 243, 242, 1)",

      "& > button": {
          padding: "16px 53px",
          gap: "8px",
          borderRadius: "8px",
      },

      "& > button > span": {
          fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "24px",
          textAlign: "left",
          textTransform: "initial"
      }
  },

  serviceSent:{
    display: 'flex',
    justifyContent: 'space-between', 
    marginTop: '20px', 
    alignItems: 'center'
  },

  serviceSentText:{
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#C5CBC9"
  },

  serviceProviderHired:{
    display: 'block',
  },

  circularProgressContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
    display: "inline-block",
  },
});

const customArrowIcon = () => {
  return (
    <SvgIcon style={{ width: "20px", height: "20px" }}>
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
    </SvgIcon>
  );
};

// Customizable Area End

export class ProjectTemplatesDealDashboard extends ProjectTemplatesDealDashboardController {
  constructor(props: Props) {
    super(props);
        // Customizable Area Start
        // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
        // Customizable Area Start
    const { selectedStatus, dealInfo, user, hiredSPInfo, sidebar, sentServiceProviders, isLoading} = this.state;
    const { classes } = this.props;
    let applytheme=responsiveFontSizes(theme)
    const getProgressStyle = () => {
      let statusClass = "";
      if (selectedStatus === "In Progress") {
          statusClass = classes.inProgress;
      } else if (selectedStatus === "Completed") {
          statusClass = classes.completed;
      } else if (selectedStatus === "Deleted") {
          statusClass = classes.deleted;
      }
      return `${classes.progressMenu} ${statusClass}`;
    };
    const formatMoney = (number: number) => {
      return number.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
      })
    }

    const ToolTip = withStyles({
      tooltip: {
        padding: '16px',
        radius: '8px',
        background: '#FFFFFF',
        boxShadow:"0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
        color: '#4B4C4B',
        fontSize: '15px'
      },
    })(Tooltip);

    const isSPHired = "serviceProviderName" in hiredSPInfo;
    let basicInfos: { [key: string]: any } = [];
    let cardPanelInfos: { [key: string]: any } = [];
    if (dealInfo.vehicle) {
      const {
        vehicleVin,
        vehicleType,
        make,
        odometer,
        bodyType,
        vehicleYear,
        vehicleWeightEmpty,
        vehicleWeightGross,
      } = dealInfo.vehicle;
      const {
        dealId,
        fromState,
        toState,
        applicationType,
        firstOwner,
        secondOwner,
        ownerFirstName,
        ownerLastname,
        saleType,
        soldAs,
        msrp,
        tsp,
        dateOfSale
      } = dealInfo;
      basicInfos = {
        VIN: vehicleVin,
        STATES: fromState + " > " + toState,
        "VEHICLE TYPE": vehicleType,
        "APPLICATION TYPE": applicationType,
        "FIRST OWNER": firstOwner,
        "SECOND OWNER": secondOwner,
        MAKE: make,
        ODOMETER: odometer,
        "BODY TYPE": bodyType,
        YEAR: vehicleYear,
        "OWNER'S NAME": ownerFirstName + " " + ownerLastname,
        "WEIGHT - EMPTY": vehicleWeightEmpty + " lbs",
        "WEIGHT - GROSS": vehicleWeightGross + " lbs",
        MSRP: !isNaN(+msrp) && "$ " + formatMoney(+msrp),
        TSP: !isNaN(+tsp) && "$ " + formatMoney(+tsp),
        "SALE TYPE": saleType,
        "SOLD AS": soldAs,
        "DATE OF SALE": dateOfSale
      };
      cardPanelInfos = {
        "DEAL ID": dealId,
        "OWNER'S LAST NAME": ownerLastname,
        STATES: fromState + " > " + toState,
        APPLICATION: applicationType,
      };
    }
    return (
      <ThemeProvider theme={applytheme}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box className={classes.headerLeftSide}>
              <Box className={classes.headerMenuLogo}>
              <ThemeProvider theme={themeNew}>
                <HamburgerMenuFull navigation={this.props.navigation} id={""} data-test-id="hamburgerMenuTestId"/>
              </ThemeProvider>

                <Box className={classes.logo}>
                  <img src={vitu} alt="vitu logo" />
                </Box>
              </Box>

              <FormControl variant="standard">
                <Input
                  value="Smith's/ 328493"
                  disableUnderline={true}
                  className={classes.searchFilter}
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={filterIcon}></img>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>

            <Box className={classes.headerRightSide}>
              <Box
                data-test-id="notifications"
                className={classes.notificationsButton}
              >
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={false}
                  overlap="rectangular"
                >
                  <img src={notifications} alt="notifications button" />
                </Badge>
              </Box>

              <ProfileDropdown navigation={this.props.navigation} id={""} data-test-id="profileDropdownTestId"/>
            </Box>
          </Box>
          {isLoading ? <Box className={classes.circularProgressContainer}><CircularProgress /></Box> :
          <Box className={classes.main}>
            <Box className={classes.mainLeftSide}>
              <Box>
                <Box className={classes.cardContainer}>
                  {Object.entries(cardPanelInfos).map(([key, value]) => (
                    <Card className={classes.cardPanel} key={key}>
                      <Typography variant="body1">{key}</Typography>
                      <ToolTip title={value} placement="top-end">
                        <Typography variant="body2" className={classes.truncate}>{value}</Typography>
                      </ToolTip>
                    </Card>
                  ))}
                  <Box className={classes.progressMenuContainer}>
                    <Select
                      data-test-id="status-select-box"
                      id="demo-simple-select"
                      className={getProgressStyle()}
                      value={selectedStatus}
                      IconComponent={customArrowIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem
                        data-test-id="in-progress-status"
                        value="In Progress"
                        onClick={() => {
                          this.handleChangeSelectedStatus("In Progress");
                        }}
                      >
                        In Progress
                      </MenuItem>
                      <MenuItem
                        data-test-id="completed-status"
                        value="Completed"
                        onClick={() => {
                          this.handleChangeSelectedStatus("Completed");
                        }}
                      >
                        Completed
                      </MenuItem>
                      <MenuItem
                        data-test-id="deleted-status"
                        value="Deleted"
                        onClick={() => {
                          this.handleChangeSelectedStatus("Deleted");
                        }}
                      >
                        Deleted
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Box>
              <Box style={{ marginTop: "25px" }}>
                <Accordion
                  defaultExpanded={true}
                  className={classes.documentAddOns}
                  data-test-id="document-add-ons"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ padding: 0 }}
                  >
                    <Typography variant="h1">
                      {configJSON.documentAddOns}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "0px" }}>
                    <Box className={classes.addOns}>
                      <Box className={classes.addOn} sx={{ width: "40%" }}>
                        <Box className={classes.firstAddOn}>
                          <Typography
                            variant="h6"
                            style={{ marginRight: "20px" }}
                          >
                            {configJSON.prePopulatedFormsAndDocumentChecklist}
                          </Typography>
                          <CustomInfoComponent>
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontSize: 16, color: "#013D4F" }}
                              >
                                Pre-polulated Forms
                              </Typography>
                              <p className={classes.boxInfoText}>
                                All pre populated fillable forms that are
                                required to meet your states registration
                                requirements.
                              </p>
                            </Box>
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontSize: 16, color: "#013D4F" }}
                              >
                                Document Checklist
                              </Typography>
                              <p className={classes.boxInfoText}>
                                A list of all documents required to meet your
                                states registration requirements.
                              </p>
                            </Box>
                          </CustomInfoComponent>
                        </Box>
                        <Button
                          data-test-id="prepolulated-pay-now-btn"
                          variant="contained"
                          color="primary"
                          disableElevation
                          className={classes.buyNowBtn}
                          onClick={this.navigateToOrderSummary}
                        >
                          Buy Now
                        </Button>
                      </Box>
                      <Box className={classes.addOn} sx={{ width: "20%" }}>
                        <Box className={classes.secondAddOn}>
                          <Typography
                            variant="h6"
                            style={{ marginRight: "20px" }}
                          >
                            {configJSON.blankForms}
                          </Typography>
                          <CustomInfoComponent>
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontSize: 16, color: "#013D4F" }}
                              >
                                Blank Forms
                              </Typography>
                              <p className={classes.boxInfoText}>
                                Fillable forms that are required to meet your
                                states registration requirements.
                              </p>
                            </Box>
                          </CustomInfoComponent>
                        </Box>
                        <Button
                          data-test-id="blank-form-pay-now-btn"
                          variant="contained"
                          color="primary"
                          disableElevation
                          className={classes.buyNowBtn}
                          onClick={this.navigateToOrderSummaryBlankFormFee}
                        >
                          Buy Now
                        </Button>
                      </Box>
                      <Box className={classes.addOn} sx={{ width: "30%" }}>
                        <Box className={classes.secondAddOn}>
                          <Typography
                            variant="h6"
                            style={{ marginRight: "20px" }}
                          >
                            {configJSON.documentChecklistOnly}
                          </Typography>
                          <CustomInfoComponent>
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontSize: 16, color: "#013D4F" }}
                              >
                                Document Checklist
                              </Typography>
                              <p className={classes.boxInfoText}>
                                A list of all documents required to meet your
                                states registration requirements.
                              </p>
                            </Box>
                          </CustomInfoComponent>
                        </Box>
                        <Button
                          data-test-id="checklist-pay-now-btn"
                          variant="contained"
                          color="primary"
                          disableElevation
                          className={classes.buyNowBtn}
                          onClick={this.navigateToOrderSummaryChecklistFee}
                        >
                          Buy Now
                        </Button>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {dealInfo.dealDocuments !== undefined && 
                <ProjectTemplatesDocumentsUpload
                  isSPHired={isSPHired}
                  user={user}
                  basicInfos={basicInfos}
                  id=""
                  navigation=""
                  documents={dealInfo.dealDocuments}
                  getDealInformation={this.getDealInformation}
                  fetchDocuments={this.getDealInformation}
                />
              }
            </Box>
            <Box className={classes.mainRightSide}>
              <Accordion
                className={classes.hireSPSection}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {isSPHired ? 
                  <Box>
                    <Typography
                    style={{textTransform: "uppercase"}}
                    >
                      {user?.role?.includes("dealer") ? "Service Provider" : "Dealership"}
                    </Typography>
                  </Box>
                  :
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      style={{ marginRight: "10px", lineHeight: "32px" }}
                      variant="h1"
                    >
                      Hire a Service Provider
                    </Typography>
                    <CustomInfoComponent>
                      <p className={classes.boxInfoText}>
                        We can connect you to a service provider that can handle
                        the title and registration process for your state. This
                        can include submitting paperwork and delivering indicia.
                      </p>
                    </CustomInfoComponent>
                  </Box> 
                  }
                </AccordionSummary>
                <AccordionDetails style={{flexDirection: 'column'}}>
                  <Box className={classes.serviceProviderHired}>
                  {
                    isSPHired ?
                    <ServiceProviderHired navigation={this.props.navigation} id={""} data-test-id="service-provider-hired" serviceProvider={hiredSPInfo} user={user} dealInfo={dealInfo}/> 
                    : ( 
                      sentServiceProviders.length !== 0 && <>
                        <Box className={classes.serviceSent}>
                          <Typography className={classes.serviceSentText}>{`${sentServiceProviders.length} out of 3 Services Sent`}</Typography>
                          <ServiceProviderMenu onSelectAction={()=>{}} data-test-id="service-provider-menu"/>
                        </Box>
                        <Box className={classes.serviceProviderList} data-test-id="service-provider-list">
                          {
                            sentServiceProviders.map((serviceProvider, index) => (                    
                              <ServiceProviderHiring data-test-id="service-provider-hiring" serviceProvider={serviceProvider} key={index}/>
                            ))
                          }
                        </Box>
                      </>)
                  }
                    </Box>
                  {!isSPHired && <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={sentServiceProviders.length === 3}
                    className={`${classes.findProviderBtn} ${sentServiceProviders.length === 3 && classes.disabledBtn}`}
                    data-test-id='find-a-provider-btn'
                    onClick={this.navigateToServiceProviderDashboards}
                  >
                    Find a Provider
                  </Button>}
                </AccordionDetails>
              </Accordion>

              <RegistrationFee 
                data-test-id="registration-fee-component"
                role={user.role}
                hiredSPInfo={hiredSPInfo} 
                calculateNowHandler={this.navigateToOrderSummaryCalculateRegistrationFee}
                sendToServiceProvider={this.sendToServiceProvider}
              />

              <DealDashboardTaskList id="" navigation="" />

                <DealDashboardNote isSPHired={isSPHired} dealInfo={dealInfo} id="" navigation="" />
            </Box>
          </Box>
          }
        </Box>
        <RegistrationFeeDrawer open={sidebar} onCloseDrawer={this.closeDrawerHandler}/>
      </ThemeProvider>
    );
        // Customizable Area End
  }
}
// Customizable Area Start
const themeNew = createTheme({
  typography: {
    fontFamily: "Roboto",

    h1: {
    },

    h2: {
    },

    h3: {
    },

    h6: {
    },

    body1: {
    },

    body2: {
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        color: "yellow",
        backgroundColor: "red"
      },
      arrow:{
        color: "white",

      }
    }
  }
});

const theme = createTheme({
  typography: {
    fontFamily: "Roboto",

    h1: {
      fontFamily: "Gotham, sans-serif",
      fontSize: screen.width <= 1440 ? "24px" : "28px",
      fontWeight: 400,
      // lineHeight: "18px",
      color: "#013D4F",
    },

    h2: {
      fontFamily: "Gotham, sans-serif",
      fontSize: screen.width <= 1440 ? "22px" : "24px",
      fontWeight: 400,
      // lineHeight: "37px",
      color: "#013D4F",
    },

    h3: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "18px",
      fontWeight: 300,
      // lineHeight: "25px",
      color: "#4B4C4B",
    },

    h5: {
      fontFamily: "Roboto",
      fontSize: screen.width <= 1440 ? "13px" : "16px",
      fontWeight: 700,
      // lineHeight: "25px",
      color: "#013D4F",
    },

    h6: {
      fontFamily: "Roboto",
      fontSize: screen.width <= 1440 ? "14px" : "18px",
      fontWeight: 500,
      // lineHeight: "18px",
      textAlign: "left",
      color: "#4B4C4B",
    },

    body1: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      // lineHeight: "31px",
      color: "#939F9B",
    },

    body2: {
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 600,
      // lineHeight: "31px",
      textAlign: "left",
    },
  },
});

export default withStyles(classNames)(ProjectTemplatesDealDashboard);
// Customizable Area End