import {
  WithStyles,
  createStyles,
  withStyles,
  Box,
  Typography,
  Popover,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
export interface Props extends WithStyles {
  type: string;
  onTypeChange(event: React.ChangeEvent<HTMLInputElement>): void;
  isSPHired: boolean;
}

const styles = createStyles({
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '210px',
    height: '28px',

    '& > p': {
      fontFamily: 'Gotham, sans-serif',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '18px',
      textAlign: 'left',
    },
  },
  infoIcon: {
    marginLeft: '5px',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    marginLeft: '-15px',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  },
  infoBox: {
    width: '315px',
    height: '96px',
    padding: '15px',
    gap: '12px',
    borderRadius: '8px',
    opacity: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&  p': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      color: 'rgba(75, 76, 75, 1)',
    },
  },
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: '48px',
    height: '28px',
  },
  input: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  slider: {
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ccc',
    transition: 'background-color 0.4s, box-shadow 0.4s',
    borderRadius: '34px',
    '&:before': {
      content: '""',
      position: 'absolute',
      height: '24px',
      width: '24px',
      left: '2px',
      bottom: '2px',
      backgroundColor: 'white',
      transition: 'transform 0.4s',
      borderRadius: '50%',
    },
  },
  checkedSlider: {
    backgroundColor: '#013D4F',
    '&:before': {
      transform: 'translateX(20px)',
    },
  },
  focusSlider: {
    boxShadow: '0 0 1px #2196F3',
  },
});

export class CustomNoteTypeChange extends React.Component<
  Props,
  {
    anchorEl: HTMLElement | null;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  render(): React.ReactNode {
    const { classes, type, isSPHired } = this.props;
    const { anchorEl } = this.state;
    const handlePopoverOpen = (event: any) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    const handlePopoverClose = () => {
      this.setState({ anchorEl: null });
    };

    const open = Boolean(this.state.anchorEl);
    const isExternal = type === 'external';
    return (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
          <Box className={classes.switchContainer}>
            <Typography style={{color: isExternal ? 'rgba(197, 203, 201, 1)' : 'rgba(75, 76, 75, 1)'}}>Internal</Typography>
            <label className={classes.switch} >
              <input
                data-test-id="type-switch"
                type="checkbox"
                className={classes.input}
                checked={isExternal}
                onChange={this.props.onTypeChange}
                disabled={!isSPHired}
              />
              <span
                className={`${classes.slider} ${
                  isExternal ? classes.checkedSlider : ''
                }`}
                style={{ boxShadow: isExternal ? '0 0 1px #2196F3' : 'none' }}
              >
              </span>
            </label>
            <Typography style={{color: isExternal ? 'rgba(75, 76, 75, 1)' : 'rgba(197, 203, 201, 1)'}}>External</Typography>
          </Box>
          <Typography
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Typography>
        </Box>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box className={classes.infoBox}>
            <Typography
              style={{ color: '#C5CBC9', fontWeight: 600}}
            >
              Service Provider not enabled.
            </Typography>
            <Typography>
              External notes are visible to&nbsp;
              <span style={{ background: '#FEEA9A' }}>
                all parties on this deal.
              </span>
            </Typography>
            <Typography>
              Internal notes are only&nbsp;
              <span style={{ background: '#FEEA9A' }}>
                visible to your company.
              </span>
            </Typography>
          </Box>
        </Popover>
      </>
    );
  }
}

export default withStyles(styles)(CustomNoteTypeChange);
