import React from "react";
// Customizable Area Start
import { Box, Typography, Button, Checkbox, TextField, Modal, Divider, Chip, IconButton, Tooltip, MenuItem, FormControl, Backdrop, } from "@material-ui/core";
import { MuiThemeProvider, styled, createTheme } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';

const tooltipTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: "254px",
        padding: "16px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 18px 32px 0 rgba(0, 0, 0, 0.25)",

        "& .heading": {
          fontSize: "14px",
          color: "013D4F",
          fontWeight: 500
        },
        "& .text": {
          fontSize: "12px",
          color: "#4B4C4B",
          fontWeight: 500
        },
        "@media only screen and (max-width: 768px)": {
          maxWidth: "254px",
        }
      },
    },
    MuiCheckbox: {
      root: {
        "&$checked": {
          "color": "#4FC8EC"
        }
      },
    }
  }
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});


import EditServiceLocationController, {
  Props,
  SpecificLocation,
} from "./EditServiceLocationController.web";
import LicenseDropzone from "../../../../packages/components/src/LicenseDropzone.web";
import { arrowTop, emptyCheckbox, filledCheckbox, infoIcon, logo, notifications } from "./assets";
import HamburgerMenuFull from "./HamburgerMenuFull.web";
import ProfileDropdown from "./ProfileDropdown.web";
import { configJSON } from "./CustomisableUserProfilesController";
import { Autocomplete } from "@material-ui/lab";
import { CityType } from "./ServiceProviderProfilesController";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Styles {
  [key: string]: any;
}
const commonInputLabelProps = {
  style: { marginLeft: "6px", lineHeight: "18px" },
};

// Customizable Area End


export default class EditServiceLocation extends EditServiceLocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  headerr = () => {
    return (
      <Box sx={webStylesHeader.header}>
        <Box sx={webStylesHeader.headerLeftSide}>
          <HamburgerMenuFull
            navigation={this.props.navigation}
            id={""}
            data-test-id="hamburgerMenuTestId" />
          <Box sx={webStylesHeader.logo}>
            <img
              style={{ marginBottom: '5px', height: '24px', width: '52px' }}
              src={logo}
              alt="vitu logo" />
          </Box>
        </Box>
        <Box sx={webStylesHeader.headerRightSide}>
          <Button className="logout"
            data-test-id="backToDashboardButton"
            style={{ width: '66%', paddingLeft: "10px", paddingRight: "10px" }}
            variant="outlined"
            onClick={this.state.isChanged ? this.toggleDashboardModalNewSp : () => this.handleNavigation('Dashboard')}>
            Back to Dashboard
          </Button>
          <Box
            data-test-id="notifications"
            sx={webStylesHeader.notificationsButton}
          >
            <img
              src={notifications}
              alt="notifications button" />
          </Box>
          <ProfileDropdown
            navigation={this.props.navigation}
            id={""}
            data-test-id="profileDropdownTestId" />
        </Box>
      </Box>
    )
  };

 
  

  backToDashboardModal = () => {
    return (
      <Modal
        open={this.state.isOpenBackToDashboardModal}
        onClose={this.toggleDashboardModalNewSp}
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledModalBox >
          <Box display={'flex'} style={{ width: '545px' }} justifyContent={'end'} >
            <Button onClick={this.toggleDashboardModalNewSp} data-test-id="closebtn"><CloseIcon /></Button>
          </Box>

          <Box style={{ width: '520px', height: '126px' }}>
            <Box><Typography style={{ color: '#0F172A', fontWeight: 500, fontSize: '24px', marginBottom: '12px', fontFamily: 'Roboto' }}>Exit without saving?</Typography></Box>
            <Typography style={{ color: '#013D4F', fontSize: '18px', fontWeight: 300, fontFamily: 'Gotham light' }}>Are you sure you want to exit the edit serviceable location process and return to the dashboard? Your progress will be lost.</Typography>
          </Box>

          <Divider style={{ height: '2px', width: '113%', marginLeft: '-33px', backgroundColor: '#F0FAFA', marginTop: '30px' }} />

          <Box style={{ display: 'flex', marginTop: '20px', justifyContent: 'end', }}>
            <Button data-test-id="cancellogout" variant="outlined" onClick={this.toggleDashboardModalNewSp} style={{ height: '56px', border: '1px solid #013D4F', width: '156px', marginRight: '15px', borderRadius: '8px' }}>
              <Typography style={{ color: '#013D4F', fontSize: '16px', fontWeight: 700, textTransform: 'none', fontFamily: 'Roboto' }}>
                Cancel
              </Typography>
            </Button>
            <Button data-test-id="finalDashboard" variant="contained" onClick={() => this.handleNavigation("Dashboard")} style={{ width: '230px', height: '56px', backgroundColor: '#4FC8EC', borderRadius: '8px' }}>
              <Typography style={{ color: 'white', textTransform: 'none', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 700, }}>
                Go to Dashboard
              </Typography>
            </Button>
          </Box>
        </StyledModalBox>
      </Modal>
    )
  }

  step2 = () => {
    const { idFromParam } = this.state
    return (<>
      <LocationBody>
        {this.headerr()}
        {this.backToDashboardModal()}
        <Box className="headerForm">
          <Box className="headingForm">
            {idFromParam === "new" ?
              <>
                <Typography className="titleForm gothamText" data-test-id="serviceableLoacation">{"Create Serviceable Locations"}</Typography>
                <Typography className="subtitleForm" style={{ fontFamily: "Roboto" }}>
                  States you are authorized to provide services in
                </Typography>
              </> : <>
                <Typography className="titleForm gothamText" data-test-id="serviceableLoacation">{`Edit ${this.state.specificLocation.stateName}`}</Typography>
                <Typography className="subtitleForm" style={{ fontFamily: "Roboto" }}>
                  Edit the details of your serviceable location
                </Typography>
              </>}
          </Box>
        </Box>
        <Box className="spInfoForm">
          <Box className="form" component="form">
            <MuiThemeProvider theme={tooltipTheme}>
              <Box className="serviceDetailsBox">
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography className="ServiceProviderText">Provider State</Typography>
                  {this.state.idFromParam === "new" && <FormControl variant="outlined" fullWidth style={{ width: '100%' }}>
                    <Autocomplete
                      data-test-id="searchTextBox"
                      options={this.getServicibleLocationStateName()}
                      value={{ label: this.state.specificLocation.stateName, stateISOCode: "" }}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={this.state.label}
                          placeholder="State(s)"
                          variant="outlined"
                          onFocus={this.handleFocus}
                          InputLabelProps={commonInputLabelProps}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <img
                                src={arrowTop}
                                style={{
                                  width: "24px",
                                  height: "22px",
                                  transform: 'rotate(180deg)',
                                  marginRight: "9px",
                                  color: "#C5CBC9"
                                }}
                                alt="arrow"
                              />
                            ),
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        this.setState((prevState) => ({
                          specificLocation: {
                            ...prevState.specificLocation,
                            stateName: newValue?.label || "",
                          },
                        }));
                        this.createServicableLocationFormData()
                      }}
                      disableClearable
                      renderOption={(props) => (
                        <li {...props}>
                          <MenuItem key={props.stateISOCode} value={props.label}>
                            {props.label}
                          </MenuItem>
                        </li>
                      )}
                    />
                    <Tooltip
                      title={
                        <>
                          <p className="heading">State</p>
                          <p className="text">State(s) you are licensed to provide registration services in</p>
                        </>
                      }
                      className="infoText"
                      arrow
                    >
                      <IconButton aria-label="info">
                        <img src={infoIcon} alt="info" />
                      </IconButton>
                    </Tooltip>
                  </FormControl>}
                  <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "24px", gap: "38px" }}>
                    <LicenseDropzone
                      licenseType="businessLicense"
                      onDrop={this.onUploadDocument}
                      file={this.state.uploadBusinessLicense}
                      fileString={this.state.specificLocation.businessLicense}
                      removeFileData={(e) => this.removeDocumentFile(e, "businessLicense")}
                      fileLabel="Business License"
                    />
                    <LicenseDropzone
                      licenseType="dealerLicense"
                      onDrop={this.onUploadDocument}
                      file={this.state.uploadDealerLicense}
                      fileString={this.state.specificLocation.dealerLicense}
                      removeFileData={(e) => this.removeDocumentFile(e, "dealerLicense")}
                      fileLabel="Dealer License"
                    />
                  </Box>
                  <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "24px", gap: "38px" }}>
                    <LicenseDropzone
                      licenseType="DMVContract"
                      onDrop={this.onUploadDocument}
                      file={this.state.uploadDMVContract}
                      fileString={this.state.specificLocation.DMVContract}
                      removeFileData={(e) => this.removeDocumentFile(e, "DMVContract")}
                      fileLabel="DMV Contract"
                    />
                    <LicenseDropzone
                      licenseType="registrationServiceLicense"
                      onDrop={this.onUploadDocument}
                      file={this.state.uploadRegistrationServiceLicense}
                      fileString={this.state.specificLocation.registrationServiceLicense}
                      removeFileData={(e) => this.removeDocumentFile(e, "registrationServiceLicense")}
                      fileLabel="Registration Service License"
                    />
                  </Box>
                  <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "24px", gap: "38px" }}>

                    <LicenseDropzone
                      licenseType="bondingRegistration"
                      onDrop={this.onUploadDocument}
                      file={this.state.uploadBondingRegistration}
                      fileString={this.state.specificLocation.bondingRegistration}
                      removeFileData={(e) => this.removeDocumentFile(e, "bondingRegistration")}
                      fileLabel="Bonding/Insurance Requirements "
                    />
                  </Box>
                </Box>
                <Box style={{ marginTop: "20px" }}>
                  <Typography className="ServiceProviderText">Provider Services</Typography>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <Box component="div"
                      style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        width: '44%',
                        border: '1px solid #C5CBC9',
                        borderRadius: '8px',
                        height: '36px',
                        padding: '10px 10px',
                        alignItems: "center"
                      }}>
                      <Typography style={{ fontWeight: 500, fontSize: "14px" }} >
                        <Checkbox checked={this.state.specificLocation.isRunner} className="usedCars"
                          data-test-id="runner_check"
                          color="primary" onChange={this.handleRunnerCheckBox} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                        Runner
                      </Typography>
                      <Box>
                        <TextField
                          data-test-id="runner"
                          hiddenLabel
                          placeholder="Enter Price"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          style={{
                            width: "90px",
                            textAlign: "center"
                          }}
                          inputProps={{
                            maxLength: 10
                          }}
                          disabled={!this.state.specificLocation.isRunner}
                          value={this.state.specificLocation.runnerPrice}
                          onChange={this.handleRunnerOnChange}
                        />
                      </Box>
                    </Box>
                    <Box component="div"
                      style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        width: '44%',
                        border: '1px solid #C5CBC9',
                        borderRadius: '8px',
                        height: '36px',
                        padding: '10px 10px',
                        alignItems: "center"
                      }}>
                      <Typography style={{ fontWeight: 500, fontSize: "14px" }} >
                        <Checkbox checked={this.state.specificLocation.isFullService} className="usedCars" color="primary"
                          onChange={this.handleFullServiceCheckBox}
                          data-test-id="fullService_check"
                          icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                        Full Service
                      </Typography>
                      <Box>
                        <TextField
                          data-test-id="fullService"
                          hiddenLabel
                          placeholder="Enter Price"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          style={{
                            width: "90px",
                            textAlign: "center"
                          }}
                          inputProps={{
                            maxLength: 10
                          }}
                          disabled={!this.state.specificLocation.isFullService}
                          value={this.state.specificLocation.fullServicePrice}
                          onChange={this.handleFullServiceOnChange}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                    <Box component="div"
                      style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        width: '44%',
                        border: '1px solid #C5CBC9',
                        borderRadius: '8px',
                        height: '36px',
                        padding: '10px 10px',
                        alignItems: "center"
                      }}>
                      <Typography style={{ fontWeight: 500, fontSize: "14px" }} >
                        <Checkbox checked={this.state.specificLocation.isWhiteGlove} className="usedCars" color="primary"
                          onChange={this.handleWhiteGloveCheckBox}
                          data-test-id="white_glove_check"
                          icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                        White Glove
                      </Typography>
                      <Box>
                        <TextField
                          data-test-id="white_glove"
                          hiddenLabel
                          placeholder="Enter Price"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          style={{
                            width: "90px",
                            textAlign: "center"
                          }}
                          inputProps={{
                            maxLength: 10
                          }}
                          disabled={!this.state.specificLocation.isWhiteGlove}
                          value={this.state.specificLocation.whiteGlovePrice}
                          onChange={this.handleWhiteGloveOnChange}
                        />
                      </Box>
                    </Box>
                    <Box component="div"
                      style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        width: '44%',
                        border: '1px solid #C5CBC9',
                        borderRadius: '8px',
                        height: '36px',
                        padding: '10px 10px',
                        alignItems: "center"
                      }}>
                      <Typography style={{ fontWeight: 500, fontSize: "14px" }} >
                        <Checkbox checked={this.state.specificLocation.isOnSite} className="usedCars" color="primary"
                          onChange={this.handleOnSiteCheckBox}
                          data-test-id="onSite_check"
                          icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                        On Site
                      </Typography>
                      <Box>
                        <TextField
                          data-test-id="onSite"
                          hiddenLabel
                          placeholder="Enter Price"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          style={{
                            width: "90px",
                            textAlign: "center"
                          }}
                          inputProps={{
                            maxLength: 10
                          }}
                          disabled={!this.state.specificLocation.isOnSite}
                          value={this.state.specificLocation.onSitePrice}
                          onChange={this.handleOnSiteOnChange}

                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {this.state.idFromParam === 'new' &&
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "end", width: "100%", marginTop: "20px" }}>
                    <Button
                      data-test-id="add_location"
                      variant="outlined"
                      disabled={!this.isAddNewStateButton()}
                      onClick={this.addAnotherStateButtonClick}
                      style={{ padding: "10px", lineHeight: "24px", fontWeight: 700, fontSize: "16px", textTransform: "none", borderRadius: "8px", borderColor: this.isAddNewStateButton() ? "#013D4F" : "#C5CBC9", color: this.isAddNewStateButton() ? "#0F172A" : "#C5CBC9" }}
                    >
                      {configJSON.addState}
                    </Button>
                  </Box>
                }
                {this.state.idFromParam === 'new' &&
                  <>
                    {this.state.servicableLocations &&
                      <Box style={{ marginTop: "35px" }}>
                        <Typography className="ServiceProviderText">Added States</Typography>
                        {this.state.locations.map((serviceLocation: any, index: React.Key | null | undefined) => (
                          !serviceLocation.isEditServiceable && <Box key={index} style={{ marginTop: "28px", padding: "20px", border: "1px solid #e0e0e0", borderRadius: "8px" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography variant="h6" style={{ marginBottom: "15px" }}>{serviceLocation.stateName}</Typography>
                            </Box>
                            <Typography variant="body2" style={{ color: "#C5CBC9", marginBottom: "10px" }}>Documents Uploaded</Typography>
                            <Box style={{ width: "75%", display: "flex", flexWrap: "wrap" }}>
                              <Box component="div">
                                <Chip
                                  icon={this.getIcon(serviceLocation.businessLicense)}
                                  label="Business License"
                                  style={{
                                    background: "#fff",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    lineHeight: "28px",
                                    fontSize: "14px"
                                  }}
                                />
                                <Chip
                                  icon={this.getIcon(serviceLocation.dealerLicense)}
                                  label="Dealer License"
                                  style={{
                                    background: "#fff",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    lineHeight: "28px",
                                    fontSize: "14px"
                                  }}
                                />
                                <Chip
                                  icon={this.getIcon(serviceLocation.DMVContract)}
                                  label="DMV Contract"
                                  style={{
                                    background: "#fff",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    lineHeight: "28px",
                                    fontSize: "14px"
                                  }}
                                />
                                <Chip
                                  icon={this.getIcon(serviceLocation.registrationServiceLicense)}
                                  label="Registration Service License"
                                  style={{
                                    background: "#fff",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    lineHeight: "28px",
                                    fontSize: "14px"
                                  }}
                                />
                                <Chip
                                  icon={this.getIcon(serviceLocation.bondingRegistration)}
                                  label="Bonding/Insurance Requirements "
                                  style={{
                                    background: "#fff",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    lineHeight: "28px",
                                    fontSize: "14px"
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    }
                  </>
                }
                {this.state.idFromParam === 'new' ?
                  <>
                    <Box className="nextStep">
                      <Button variant="contained"
                        onClick={this.createServiceableLocations}
                        disabled={this.state.locations.length === 0}
                        data-test-id="finish_btn" id="nextStepBtn">{configJSON.finishBtn}</Button>
                    </Box>
                  </> :
                  <>
                    <Box className="nextStep">
                      <Button variant="contained"
                        onClick={this.editServiceableLocations}
                        disabled={!this.state.isChanged}
                        data-test-id="save_btn" id="nextStepBtn">{configJSON.saveBtn}</Button>
                    </Box>
                    <Box className="deleteStep">
                      <Button variant="contained"
                        onClick={this.handleDeleteState}
                        data-test-id="delete_btn" id="nextStepBtn">{configJSON.deleteBtn}</Button>
                    </Box>
                  </>

                }
              </Box>
            </MuiThemeProvider>
          </Box>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          </div>
        </Box>
      </LocationBody>
      <Backdrop style={webStylesHeader.backDrop} open={this.state.pageLoader}>
        <CircularProgress />
      </Backdrop>
    </>
    );
  };

  render() {
    return (
      <>
        {this.step2()}
      </>
    )
  }

  // Customizable Area End
}

// Customizable Area Start
const LocationBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: "24px",
  boxSizing: "border-box",
  "& .spInfoForm": {
    padding: "0px 20px",
    width: "50%",
    alignSelf: 'center'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  "& .header": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative"
  },
  "& .logo": {
    width: "54px",
    height: "24px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "18.5px 20px"
  },
  "& .MuiAutocomplete-input:first-child": {
    marginLeft: "11px"
  },
  "& .MuiOutlinedInput-root fieldset": {
    padding: "0 14px"
  },
  "& .MuiAutocomplete-root": {
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: "6px",
      height: "56px",
      "& .MuiAutocomplete-input": {
        padding: "0px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "9px",
      },
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '9px',
      },
      '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
        backgroundColor: "#013D4F",
      },
      "& .MuiAutocomplete-popper": {
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            "&[data-focus='true'], &:hover": {
              backgroundColor: "##013D4F",
            },
            "&[aria-selected='true']": {
              backgroundColor: "#013D4F",
            },
          },
        },
      },
    },
  },
  "& .logout, .skip": {
    width: "98px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
    minWidth: "98px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .w9FormButton": {
    marginTop: "20px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
  },
  "& .collapsibleContent": {
    transition: "max-height 0.3s ease-in-out",
  },
  "& .heading": {
    paddingTop: "112px",
    paddingBottom: "32px",
    margin: "0 14.27%",
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      margin: "0 8%",
    },
  },
  "& .title": {
    fontSize: "30px",
    lineHeight: "40px",
    color: "#013D4F",
    fontWeight: 300
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    }
  },
  '& label.Mui-focused': {
    color: '#4B4C4B',
    fontWeight: 500
  },
  '& .MuiInputLabel-root': {
    paddingLeft: '6px',
    color: '#C5CBC9',
  },
  '& label.MuiInputLabel-shrink': {
    marginLeft: 0,
    paddingLeft: 0,
    color: '#4B4C4B',
    fontWeight: 500
  },
  "& .subtitle": {
    fontSize: "18px",
    lineHeight: "18px",
    color: "#4B4C4B",
    fontWeight: 300,
    marginTop: "10px",
    zIndex: 40,
    position: 'relative'
  },
  "& .headingForm": {
    fontFamily: "Gotham, sans-serif",
    fontWeight: 300,
  },
  "& .titleForm": {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    fontWeight: 300,
  },
  "& .radioStyle": {
    width: "20px",
    height: "20px"
  },
  "& .MuiAutocomplete-popupIndicator": {
    marginRight: "3px"
  },
  "& .spText": {
    color: "#013D4F",
    fontWeight: 400,
    marginBottom: "5px",
    fontSize: "16px",
    lineHeight: "24px"
  },
  "& .ServiceProviderText": {
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  "& .custom-placeholder": {
    paddingLeft: "10px",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .MuiFormGroup-root": {
    display: "inline",
  },
  "& .MuiFormControlLabel-label": {
    marginTop: "3.5px"
  },
  "& .serviceDetailsBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px",
    border: "2px solid #F0FAFA",
    borderRadius: "16px",
    height: "auto"
  },
  "& .serviceProviderDetailsInnerBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "16px",
    height: "auto",
  },
  "& .subtitleForm": {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "22px",
    color: "#4B4C4B",
  },
  "& .mainContent": {
    margin: "0 14.37%",
    display: "flex",
    position: "relative",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      margin: "0 8%",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "98.7%",
      borderTop: "2px solid #F0FAFA",
    }
  },
  "& .steps": {
    display: "flex",
    flexDirection: "column",
    marginRight: "24px",
    marginTop: "26px",
    padding: "20px 0px",
    width: "270px"
  },
  "& .stepContent": {
    marginTop: "24px",
    width: "100%",
    padding: "20px 0px",
    minWidth: "560px",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0",
    },
  },
  "& .stepItem": {
    marginBottom: "24px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "16px",
    color: "#C5CBC9",
  },
  "& .stepItem.active .stepTitle": {
    color: "#013D4F",
    "& .MuiTypography-body1": {
      color: "#013D4F"
    }
  },
  "& .stepItem.done .stepTitle": {
    color: "#26BBB3",
    "& .MuiTypography-body1": {
      color: "#26BBB3"
    }
  },
  "& .stepTitle": {
    display: "flex",
    flexDirection: "row",


    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
  },
  "& .stepSubtitle": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  "& .stepText": {
    textAlign: "end",
  },
  "& .stepNumber": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius: '50%',
    backgroundColor: "rgba(240, 250, 250, 0.78)",
    padding: "0px 14px"
  },
  "& .stepItem.skipped .stepTitle": {
    color: "#F05E22",
    "& .MuiTypography-body1": {
      color: "#F05E22"
    }
  },
  "& .stepItem.clickable": {
    cursor: "pointer",
  },
  "& .stepItem:not(.clickable)": {
    cursor: "not-allowed",
  },
  "& .pac-container.pac-logo": {
    width: "800px !important",
    backgroundColor: "red !important",
    padding: "10px !important",
    fontSize: "16px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .pac-item": {
    padding: "10px",
    fontSize: "14px",
    cursor: "pointer",
  },
  "& .pac-item:hover": {
    backgroundColor: "red",
  },
  "& .pac-item-query": {
    fontSize: "14px",
    color: "#red",
  },
  "& .pac-matched": {
    fontWeight: "bold",
  },
  "& .pac-icon": {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  "& .headerForm": {
    marginBottom: "32px",
    display: "flex",
    flexDirection: "row",
    width: '66%',
    alignSelf: 'center'
  },
  "& .photoForm": {
    maxWidth: "640px",
    height: "321px",
    // background: `url(${profileBackground}) no-repeat top center`,
    backgroundSize: "100% 191px",
    border: "2px solid #F0FAFA",
    borderRadius: "30px",
  },
  "& .photoFormContent": {
    padding: "48px",
    marginTop: "81px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
  "& .profilePhoto": {
    width: "120px",
    height: "120px",
    borderRadius: "30px",
  },
  "& .updatePhoto": {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  "& .uploadPhoto": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .upload, .delete": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .upload, .uploadLogo": {
    color: "#013D4F",
    border: "1px solid #013D4F",
  },
  "& .delete": {
    border: "1px solid #C43937",
    color: "#C43937",
  },
  "& .uploadLogo": {
    position: "absolute",
    top: "12px",
    right: "24px",
    height: "32px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .hints": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "10px",
    color: "#C5CBC9",
  },
  "& .nextStep": {
    marginTop: "32px",
    textAlign: "center",
  },
  "& .deleteStep": {
    marginTop: "32px",
    textAlign: "center",
  },
  "& .nextStep button": {
    width: "100%",
    height: "56px",
    marginBottom: "8px",
    backgroundColor: "#4FC8EC",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none",
    borderRadius: "8px",
    marginRight: "3px"
  },
  "& .deleteStep button": {
    width: "100%",
    height: "56px",
    marginBottom: "8px",
    backgroundColor: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#C43937",
    textTransform: "none",
    borderRadius: "8px",
    marginRight: "3px",
    "& .MuiButton-root": {
      color: 'red !important',
      // "&:hover":{
      //   color:'red !important'
      // }
    },
    border: "1px solid #C43937"
  },
  "& .nextStep p": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
  },
  "& .deleteStep p": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
    "&:hover": {
      color: 'red !important'
    },
  },
  "& .form": {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  "& .formControl": {
    position: "relative",
    height: "56px",
    padding: "0 24px 10px 24px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .Mui-checked": {
    color: "#4FC8EC",
  },
  "& .info": {
    position: "absolute",
    top: "-35px",
    right: "0",
  },
  "& .infoTextFirst": {
    position: "absolute",
    top: "53px",
    right: "24px",
  },
  "& .infoTextVoidedCheck": {
    position: "absolute",
    top: "-10px",
    right: "4px",
  },
  "& .infoTextLast": {
    position: "absolute",
    right: "2px",
    top: "0px",
  },
  "& .infoText": {
    position: "absolute",
    top: "-23px",
    right: "2px",
  },
  "& .infoTextStep3": {
    position: "absolute",
    top: "72px",
    right: "26px",
  },
  "& .infoTextBankAddress": {
    position: "absolute",
    right: "2px",
    top: "-7px",
  },
  "& .same": {
    marginTop: "-14px",
    marginLeft: "7px",
    fontWeight: 500,
  },
  "& .nextStep button:disabled": {
    color: "#75D3F0",
    backgroundColor: "#E8F8FD",
  },
  "& .MuiAutocomplete-groupUl": {
    backgroundColor: "red"
  },
  "& .MuiFormLabel-root.Mui-disabled": {
    color: "#4B4C4B",
  },
})
);

const StyledModalBox = styled(Box)({
  width: '512px',
  minWidth: '200px',
  border: '1px solid #FFFFFF',
  boxShadow: theme.shadows[5],
  backgroundColor: '#FFFFFF',
  padding: theme.spacing(2, 4, 3),
  borderRadius: '8px',
})

const webStylesHeader: Styles = {
  backDrop: {
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,

  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    minWidth: "924px",
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 0px",
    minWidth: "870px",
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "0px",
  },

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
  },

  logo: {
    width: "52px",
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
  },


  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },

  main: {
    display: "flex",
    flexWrap: "nowrap",
    position: "relative",
  },


  buttonText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "14px",
    fontWeight: 500,
  },

  content: {
    width: "100%",
    marginLeft: "120px",
  },

}
// Customizable Area End