import React from "react";
// Customizable Area Start
import {
  Box, Avatar, Button, TextField, Typography, InputAdornment, IconButton, Drawer, styled, Grid
  , Card, CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Modal, createTheme, Divider, Menu,
  ClickAwayListener
} from "@material-ui/core"
import {
  notifications
} from "../../../blocks/dashboard/src/assets";
import { userDesign, logo } from "./assets"
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { banner, usaflag } from '../../email-account-registration/src/assets'
import CloseIcon from '@material-ui/icons/Close';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Formik, Form, Field } from 'formik';
import OtpInput from 'react-otp-input';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { MailOutlineOutlined } from "@material-ui/icons";
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
import HamburgerMenuFull from "../../customisableuserprofiles/src/HamburgerMenuFull.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import BlockController, {
  configJSON,
  Props
} from "./UserProfileBasicController";
import { displayName } from "react-quill";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  optionForDeactivate = [
    'No longer need the service',
    'Dissatisfied with the platform',
    'Privacy or security concerns',
    'Found an alternative service',
    'Technical issues or bugs',
    'Other (please specify)'
  ]


  newModal = () => {
    return (
      <Modal
        open={this.state.isOpenLogoutModal}
        onClose={this.handleLogoutModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledModalBox >
          <Box display={'flex'} justifyContent={'end'} style={{ width: '105%' }}>
            <Button data-test-id="closebtn" onClick={this.handleLogoutModal}><CloseIcon /></Button>
          </Box>

          <Box>
            <Box><Typography style={{ fontWeight: 500, color: '#013D4F', fontSize: '24px', marginBottom: '12px', fontFamily: 'Roboto' }}>Log Out</Typography></Box>
            <Typography style={{ fontWeight: 300, color: '#4B4C4B', fontSize: '18px', fontFamily: 'Gotham' }}>Are you sure you want to log out?</Typography>
          </Box>

          <Divider style={{ height: '2px', backgroundColor: '#F0FAFA', width: '113%', marginLeft: '-33px', marginTop: '30px' }} />

          <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
            <Button data-test-id="cancellogout" variant="outlined" onClick={this.handleLogoutModal} style={{ height: '56px', width: '156px', border: '1px solid #013D4F', marginRight: '15px', borderRadius: '8px' }}>
              <Typography style={{ color: '#013D4F', textTransform: 'none', fontSize: '16px', fontWeight: 700, fontFamily: 'Roboto' }}>
                Cancel
              </Typography>
            </Button>
            <Button data-test-id="finallogout" variant="contained" onClick={this.gotoLogin} style={{ height: '56px', width: '156px', backgroundColor: '#4FC8EC', borderRadius: '8px' }}>
              <Typography style={{ color: 'white', textTransform: 'none', fontSize: '16px', fontWeight: 700, fontFamily: 'Roboto' }}>
                Log Out
              </Typography>
            </Button>
          </Box>

        </StyledModalBox>
      </Modal>
    )
  }

  tourModal = () => {
    return (
      <Modal
        open={this.state.tourModal}
        onClose={this.closeTourModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledModalBox >
          <Box display={'flex'} justifyContent={'end'} style={{ width: '105%' }}>
            <Button data-test-id="closebtn" onClick={this.closeTourModal}><CloseIcon /></Button>
          </Box>

          <Box>
            <Box><Typography style={{ fontWeight: 500, color: '#013D4F', fontSize: '24px', marginBottom: '12px', fontFamily: 'Roboto' }}>Let’s Take a Tour!</Typography></Box>
            <Typography style={{ fontWeight: 300, color: '#4B4C4B', fontSize: '18px', fontFamily: 'Gotham' }}>You’ll be directed now to the home dashboard to take a step-by-step tour.</Typography>
          </Box>

          <Divider style={{ height: '2px', backgroundColor: '#F0FAFA', width: '113%', marginLeft: '-33px', marginTop: '30px' }} />

          <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
            <Button data-test-id="cancel" variant="outlined" onClick={this.closeTourModal} style={{ height: '56px', width: '156px', border: '1px solid #013D4F', marginRight: '15px', borderRadius: '8px' }}>
              <Typography style={{ color: '#013D4F', textTransform: 'none', fontSize: '16px', fontWeight: 700, fontFamily: 'Roboto' }}>
                Cancel
              </Typography>
            </Button>
            <Button variant="contained"
            data-test-id="contact_us"
            onClick={this.goToDashboard} style={{ height: '56px', width: '156px', backgroundColor: '#4FC8EC', borderRadius: '8px' }}>
              <Typography style={{ color: 'white', textTransform: 'none', fontSize: '16px', fontWeight: 700, fontFamily: 'Roboto' }}>
              Take a Tour Now
              </Typography>
            </Button>
          </Box>

        </StyledModalBox>
      </Modal>
    )
  }

  errorBoxphoto = () => {
    if (this.state.errorPhoto) {
      return (
        <Typography style={{ color: '#C43937', marginTop: '7px', marginLeft: '4px', fontSize: '12px' }}>
          {this.state.errorPhoto}
        </Typography>
      );
    } else {
      return null;
    }
  };

  errorBoxDeactivate = () => {
    if (this.state.deactivateError) {
      return (
        <Typography style={{ color: '#C43937', marginTop: '7px', marginLeft: '4px', fontSize: '12px' }}>
          {this.state.deactivateError}
        </Typography>
      );
    } else {
      return null;
    }
  };

  otpPage = () => {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '30px' }}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <OtpInput
            value={this.state.otp}
            onChange={this.handleChangeOtp}
            inputType="tel"
            numInputs={4}
            data-test-id="input1"
            inputStyle={{
              border: this.state.errorMsgOtp ? '1px solid #C43937' : '0.2px solid #C5CBC9',
              height: '65px',
              width: '65px',
              fontSize: '30px',
              margin: '6px',
              borderRadius: '8px',
              fontWeight: 300,
              color: '#4B4C4B'
            }}
            renderInput={(props) => <input {...props} />}
          />

        </Box>

        <Typography data-test-id="errorMessage" style={{ textAlign: 'center', color: '#DC2626', fontSize: '12px', marginTop: '5px' }}>{this.state.errorMsgOtp ? this.state.errorMsgOtp : null}</Typography>

        <Buttondb data-test-id="otpsubmit" variant="contained" disabled={this.state.otp.length < 4} onClick={this.handleSubmitOtp} style={{ height: '56px', marginTop: '30px' }}>
          <Typography style={{ fontWeight: 700, fontSize: '16px', textTransform: 'none' }}>Verify</Typography>
        </Buttondb>


        <Box display="flex" alignItems="center" justifyContent={'end'} style={{ marginTop: '10px' }}>
          <Typography style={{ fontWeight: 500, padding: '0px', fontSize: '16px', textTransform: 'none', color: '#4B4C4B' }}>Didn't receive OTP? </Typography>
          <Button disabled={this.state.timer > 0} data-test-id="gotoemailaccregistrationtest" variant="text" onClick={this.resendOtpagain}>
            <Typography data-test-id="timerDisplay" style={{ fontWeight: 510, padding: '0px', fontSize: '16px', color: this.state.timer > 0 ? '#4B4C4B' : '#013D4F', textTransform: 'none' }}>Resend <span style={{ display: this.state.timer > 0 ? '' : 'none' }}>in {this.formatTime(this.state.timer)}</span></Typography>
          </Button>
        </Box>

      </Box>
    )
  }

  enterPhoneFieldPage = () => {
    return (
      <Formik
        initialValues={{ phoneNumber: '' }}
        onSubmit={this.handleSubmitFormik}
      >
        {({ errors, touched, handleChange, handleBlur, values, isValid }) => (
          <Form>

            <Box style={{ position: "relative", marginTop: "40px" }}>
              <Field
                as={TextField}
                label="New Phone Number"
                variant="outlined"
                fullWidth
                data-test-id="phoneNumber"
                style={{ position: 'relative' }}
                value={values.phoneNumber}
                name="phoneNumber"
                id="phoneNumber"
                error={Boolean(this.state.otpErrorFromBe)}
                helperText={this.ErrorBoxx(Boolean(this.state.otpErrorFromBe), this.state.otpErrorFromBe)}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.handleRegex(event, handleChange)}
                inputProps={{
                  maxLength: 10
                }}
                onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                  if (this.handleOnFocusFullName(event)) {
                    this.bbbb(event.target.labels)
                  }
                }}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  if (this.handleBlurr(event)) {
                    this.cccc(event.target.labels, "0px")
                  }
                  handleBlur(event);
                }}
                InputLabelProps={{
                  style: { color: "rgba(75, 76, 75, 1)", fontWeight: 500 },
                }}
                InputProps={{
                  style: { borderRadius: "8px" },
                  startAdornment: (
                    <InputAdornment position="end" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                      <img src={usaflag} style={{ height: "14px", width: "18px" }} />
                      <p style={{ marginLeft: "8px", fontSize: "16px", color: this.getColorr(values.phoneNumber) }}>+</p>
                      <p style={{ fontSize: "16px", color: this.getColorr(values.phoneNumber), marginTop: "18px" }}>1</p>
                      <p style={{ marginLeft: "12px", marginRight: "10px", color: this.getColorr(values.phoneNumber), fontSize: "16px" }}>|</p>
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box style={{ marginTop: '10px' }}>
              <Typography style={{ fontSize: '12px', fontWeight: 500 }}>A OTP message will be sent to the new number. You will need to enter the OTP to verify the new number.</Typography>
            </Box>

            <Buttondb type="submit" data-test-id="btnphone" disabled={!values.phoneNumber || values.phoneNumber.length < 10} variant="contained" style={{ height: '56px', marginTop: '40px' }}>
              <Typography style={{ fontWeight: 700, fontSize: '16px', textTransform: 'none' }}>Send OTP</Typography>
            </Buttondb>

          </Form>
        )}
      </Formik>
    )
  }

  errorPassword = (errr: string) => {
    if (errr) {
      return (
        <Box style={{ marginBottom: '20px', height: '46px', width: '420px', backgroundColor: '#FEE2E2', borderLeft: '4px solid #C43937', borderRadius: '8px' }}>
          <Typography style={{ marginLeft: '10px', fontSize: '14px', color: "#DC2626", paddingTop: '15px', fontWeight: 500 }}>
            {this.state.passwordError}
          </Typography>
        </Box>
      )
    }
    return null
  }

  getIcon = (icon: string) => {
    if (icon === "email") {
      return (<EmailOutlinedIcon style={{ color: '#013D4F' }} />)
    }
    else if (icon === "phone") {
      return (<SmartphoneIcon style={{ color: '#013D4F' }} />)
    }
    else if (icon === "name") {
      return (<PersonOutlineOutlinedIcon style={{ color: '#013D4F' }} />)
    }
    else if (icon === "password") {
      return (<LockOutlinedIcon style={{ color: '#013D4F' }} />)
    }
  }

  toastCall = (message: string, icon: string) => {
    toast(
      <>
        <style>{`
        .Toastify__toast-theme--colored.Toastify__toast--default{
          padding:0px; 
          margin: 0px ;
        }
        .Toastify__toast-body{
          padding:0px;
          min-width: 100%;
        }
        .Toastify__toast{
          border-radius:12px;
          padding:0px;
        }
      `}</style>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between', flexDirection: 'row',
            alignItems: 'center', color: ' #006064', border: '1px solid #013D4F',
            borderRadius: '12px', height: '65px', padding: '0px 24px',
            boxShadow: '0px 16px 48px 0px rgba(1, 61, 79, 0.22)'
          }}>

          <Box style={{
            display: 'flex', flexDirection: 'row',
            alignItems: 'center', gap: '5px'
          }}>
            <Box>
              {this.getIcon(icon)}
            </Box>
            <Box style={{ fontSize: '16px', color: '#013D4F' }}>{message}</Box>
          </Box>
          <Box><CloseIcon style={{ color: '#013D4F' }} /></Box>
        </Box>
      </>,
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });

    this.doItFalse();
  }

  drawerForEmailEdit = () => {
    return (
      <Drawer anchor="right" open={this.state.isEmailDrawerOpen} onClose={this.toggleDrawerEmail}>
        <Box style={{ maxWidth: '700px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box display={"flex"} flexDirection={"column"} style={{
            paddingBottom: '40px', rowGap: '0px', marginTop: '20px', width: '90%',
            marginLeft: 'auto', marginRight: 'auto'
          }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className="gothamText" style={{ fontWeight: 300, color: '#013D4F', fontSize: '24px' }} >Change Email</Typography>
              <Button onClick={this.toggleDrawerEmail}><CloseIcon /></Button>
            </Box>

            <Typography style={{ fontWeight: 500, color: '#4B4C4B', fontSize: '14px', fontFamily: 'Roboto' }}>Please enter your new email address.</Typography>

            <Box style={{ marginTop: '30px' }}>
              <TextField
                data-test-id="emailtest2"
                type="email"
                name="email"
                label={this.state.labelEmail}
                variant="outlined"
                fullWidth
                InputProps={{
                  style: { borderRadius: '8px' },
                  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key.match(' ')) {
                      event.preventDefault();
                    }
                  },
                }}
                InputLabelProps={{
                  style: { color: '#4B4C4B' }
                }}
                value={this.state.changedEmail}
                onChange={this.handleEmailChange}
                onFocus={this.handleFocusEmail}
                onBlur={this.handleBlurEmail}
                style={{ marginBottom: '20px' }}

                error={Boolean(this.state.emailErrorFromBe)}
                helperText={this.ErrorBoxx(Boolean(this.state.emailErrorFromBe), this.state.emailErrorFromBe)}
              />
            </Box>

            {this.state.openToastEmail && this.toastCall("Link sent. Please verify your email.", "email")}

            <Box>
              <Typography style={{ fontSize: '12px', fontWeight: 500 }}>A confirmation link will be sent to the new email address. You will need to click on the link to verify your new email.</Typography>
            </Box>

            <Buttondb data-test-id="emailEdit" disabled={!this.state.changedEmail} variant="contained" onClick={this.handleEmailEditApi} style={{ height: '56px', marginTop: '45px' }}>
              <Typography style={{ fontWeight: 700, fontSize: '16px', textTransform: 'none' }}>Send Confirmation</Typography>
            </Buttondb>
          </Box>

        </Box>
      </Drawer>
    )
  }

  drawerForPhoneEdit = () => {
    return (
      <Drawer anchor="right" open={this.state.isPhoneDrawerOpen} onClose={this.toggleDrawerPhone}>
        <PhoneDrawerBox style={{ maxWidth: '700px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box display={"flex"} flexDirection={"column"} style={{
            paddingBottom: '40px', rowGap: '0px', marginTop: '20px', width: '90%',
            marginLeft: 'auto', marginRight: 'auto'
          }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className="gothamText" style={{ fontWeight: 300, color: '#013D4F', fontSize: '24px' }}>{this.getHeading(this.state.isPhoneConfirmed, "Enter OTP", 'Change Number')}</Typography>
              <Button onClick={this.toggleDrawerPhone}><CloseIcon /></Button>
            </Box>

            <Box display={'flex'}>
              <Typography style={{ fontWeight: 500, color: '#4B4C4B', fontSize: '14px', fontFamily: 'Roboto' }}>{this.getHeading(this.state.isPhoneConfirmed, 'Enter the 4-digit code sent to your phone ', 'Please enter your new number.')}</Typography>
              <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#013D4F' }}> {this.state.isPhoneConfirmed && `\u00A0${this.formatMobileNumber(this.state.changedPhone)}`}</Typography>
            </Box>

            {this.state.isPhoneConfirmed ? this.otpPage() : this.enterPhoneFieldPage()}

          </Box>

        </PhoneDrawerBox>
        {this.state.openToastPhone && this.toastCall("Your phone number has been updated.", "phone")}
      </Drawer>
    )
  }

  drawerForResetPassword = () => {
    return (
      <Drawer anchor="right" open={this.state.isPasswordDrawerOpen} onClose={this.toggleDrawerPassword}>
        <Box style={{ maxWidth: '700px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box display={"flex"} flexDirection={"column"} style={{
            paddingBottom: '40px', rowGap: '0px', marginTop: '20px', width: '90%',
            marginLeft: 'auto', marginRight: 'auto'
          }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className="gothamText" style={{ fontWeight: 300, color: '#013D4F', fontSize: '24px' }} >Change Password</Typography>
              <Button onClick={this.toggleDrawerPassword}><CloseIcon /></Button>
            </Box>

            <Typography style={{ fontWeight: 500, color: '#4B4C4B', fontSize: '14px', fontFamily: 'Roboto' }}>Create your new password.</Typography>

            <Box style={{ marginTop: '35px' }}>
              <Formik
                initialValues={{ password1: '', password2: '' }}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmitPassword}
              >
                {({ errors, touched, handleChange, handleBlur, values }) => (
                  <Form >
                    <Box style={{ width: '96%' }}>

                      {this.errorPassword(this.state.passwordError)}

                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${this.isErrorborderr(true, this.state.passwordError, '#C43937', '#C5CBC9')}`, height: '56px', padding: '10px, 24px, 10px, 24px', borderRadius: '8px' }}>

                        <Field
                          as={TextField}
                          className="password login_test_password"
                          data-test-id="passwordtest"
                          name="password1"
                          type={this.state.showPassword1 ? 'text' : 'password'}
                          placeholder="Old Password"
                          InputProps={{
                            disableUnderline: true,
                            style: { border: 'none', height: '', marginTop: '5px', paddingLeft: '20px' },
                            onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
                              if (event.key === ' ') {
                                event.preventDefault();
                              }
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="eyebutton"
                                  className="password_button_type"
                                  aria-label="toggle password visibility"
                                  onClick={() => this.handleShowPassword('password1')}
                                  edge="end"
                                  style={{ marginRight: '10px', color: values.password1 ? '#4B4C4B' : '#C5CBC9' }}
                                >
                                  {this.state.showPassword1 ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          InputLabelProps={{ shrink: false }}
                          inputProps={{ maxLength: 15 }}
                          value={values.password1}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.clearPasswordError();
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box style={{ marginTop: '16px', width: '96%' }} >
                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${this.isErrorborderr(true, this.state.passwordError, '#C43937', '#C5CBC9')}`, height: '56px', padding: '10px, 24px, 10px, 24px', borderRadius: '8px' }}>

                        <Field
                          as={TextField}
                          className="password login_test_password2"
                          name="password2"
                          data-test-id="passwordtest"
                          placeholder="New Password"
                          type={this.state.showPassword2 ? 'text' : 'password'}
                          InputProps={{
                            disableUnderline: true,
                            style: { border: 'none', height: '', paddingLeft: '20px', marginTop: '5px', },
                            onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
                              if (event.key === ' ') {
                                event.preventDefault();
                              }
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="eyebutton"
                                  className="password_button_type2"
                                  aria-label="toggle password visibility"
                                  onClick={() => this.handleShowPassword('password2')}
                                  edge="end"
                                  style={{ marginRight: '10px', color: values.password2 ? '#4B4C4B' : '#C5CBC9' }}
                                >
                                  {this.state.showPassword2 ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          InputLabelProps={{ shrink: false }}
                          value={values.password2}
                          inputProps={{ maxLength: 15 }}

                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.clearPasswordError();
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </Box>

                    </Box>

                    <Box display={"flex"} flexDirection={"column"} style={{ marginTop: '15px' }}>
                      <Typography style={{ fontSize: '14px', color: '#013D4F', fontWeight: 400, }}>Password should have</Typography>
                      <Grid container spacing={1} style={{ marginTop: '15px' }}>
                        {this.passwordCriteria.map((criteria, index) => (
                          <Grid item xs={6} key={index}>
                            <Grid container alignItems="center">
                              <Grid item>
                                {criteria.test(values.password2) && (
                                  <CheckCircleIcon style={{ color: '#26BBB3', width: '16px', height: '16px', }} />
                                )}
                                {!criteria.test(values.password2) && values.password2 && (
                                  <CancelIcon style={{ color: '#C43937', width: '16px', height: '16px', }} />
                                )}
                                {!values.password2 && (
                                  <RadioButtonUncheckedIcon style={{ width: '16px', height: '16px', color: '#4B4C4B' }} />
                                )}
                              </Grid>
                              <Grid item>
                                <Typography style={{ fontSize: '12px', fontWeight: 400, color: '#4B4C4B', marginLeft: '5px', marginTop: '2px' }}>{criteria.label}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} style={{ marginTop: '30px', width: '96%' }}>
                      <Buttondb className="resetpassword" type="submit" disabled={!values.password1 || !values.password2 || Boolean(errors.password1) || Boolean(errors.password2)} variant="contained" style={{ width: '100%', height: '56px' }}>
                        <Typography style={{ fontSize: '16px', textTransform: 'none', fontWeight: 700 }}>Update Password</Typography>
                      </Buttondb>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
            {this.state.openToastpassword && this.toastCall("Your password has been updated.", "password")}

          </Box>

        </Box>
      </Drawer>
    )
  }

  drawerForDeactivateEdit = () => {
    return (
      <Drawer anchor="right" open={this.state.isDeactivateDrawerOpen} onClose={this.toggleDrawerDeactivate}>
        <Box style={{ maxWidth: '700px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box display={"flex"} flexDirection={"column"} style={{
            paddingBottom: '40px', rowGap: '0px', marginTop: '20px', width: '90%',
            marginLeft: 'auto', marginRight: 'auto'
          }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className="gothamText" style={{ fontWeight: 300, color: '#013D4F', fontSize: '24px' }} >Verify Password</Typography>
              <Button onClick={this.toggleDrawerDeactivate}><CloseIcon /></Button>
            </Box>

            <Typography style={{ fontWeight: 500, color: '#4B4C4B', fontSize: '14px' }}>Completing Your Identity</Typography>

            <Box style={{ marginTop: '35px' }}>
              <Formik
                initialValues={{ deactivatePassword: '' }}
                onSubmit={this.handleSubmitDeactivatePassword}
              >
                {({ errors, touched, handleChange, handleBlur, values }) => (
                  <Form >
                    <Box style={{ width: '96%' }}>

                      <TextField
                        className="deactivateField"
                        data-test-id=""
                        name="deactivatePassword"
                        type={this.state.showDeactivatePassword ? 'text' : 'password'}
                        label="Enter Password"
                        variant="outlined"
                        fullWidth
                        error={Boolean(this.state.deactivateError)}
                        InputProps={{
                          style: { borderRadius: '8px', height: '56px', paddingLeft: '5px' },
                          onKeyDown: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            if (event.key === ' ') {
                              event.preventDefault();
                            }
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id=""
                                className="eyebuttondeactivate"
                                aria-label="toggle password visibility"
                                onClick={this.handleShowDeactivatePassword}
                                edge="end"
                                style={{ marginRight: '10px', color: values.deactivatePassword ? '#4B4C4B' : '#C5CBC9' }}
                              >
                                {this.state.showDeactivatePassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 15 }}
                        InputLabelProps={{
                          style: {
                            color: this.handleColorr(values.deactivatePassword),
                            fontWeight: values.deactivatePassword ? 500 : 400
                          },
                        }}
                        value={values.deactivatePassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                    </Box>
                    {this.errorBoxDeactivate()}


                    <Box style={{ marginBottom: '20px', marginTop: '20px', backgroundColor: '#F0FAFA', height: '80px', width: '96%', borderLeft: '4px solid #013D4F', borderRadius: '4px' }}>
                      <Typography style={{ fontFamily: 'Gotham', marginLeft: '10px', fontSize: '13px', color: "#0F172A", paddingTop: '10px', fontWeight: 300 }} >
                        If you wish to proceed you will no longer have access to your vehicle registration details or any other data associated with your profile. Please ensure you have saved any necessary information before proceeding.
                      </Typography>
                    </Box>


                    <Box display={'flex'} flexDirection={'column'} style={{ marginTop: '30px', width: '96%' }}>
                      <Buttondb className="resetpassword" type="submit" disabled={!values.deactivatePassword || Boolean(errors.deactivatePassword)} variant="contained" style={{ width: '100%', height: '56px' }}>
                        <Typography style={{ fontSize: '16px', textTransform: 'none', fontWeight: 700 }}>Deactivate Account</Typography>
                      </Buttondb>

                      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} style={{ marginTop: '20px', width: '100%' }}>
                        <Typography style={{ fontSize: '16px', fontWeight: 500 }}>Changed your mind?</Typography>
                        <Button className="gotodashboard" onClick={this.gotoDashboard}>
                          <Typography style={{ fontSize: '16px', textTransform: 'none', fontWeight: 500 }}>Not a problem!</Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>

          </Box>

        </Box>
      </Drawer>
    )
  }
  profileMenuDropdown = () => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleCloseMenu}
        PaperProps={{
          style: {
            borderRadius: 8,
            padding: '10px 0',
            width: '270px',
          },
        }}
      >

        <Box display="flex" alignItems="center" p={2} pt={0}>
          <Avatar
            style={{
              height: '57px',
              width: '57px',
              backgroundColor: 'rgb(240, 250, 250)',
              color: '#013D4F'
            }}
            alt="Admin's Name" src={this.state.profilePhoto ? this.state.profilePhoto : ''}>
            {this.state.initials}
          </Avatar>
          <Box ml={2}>
            <Typography style={{ fontWeight: 400, fontSize: '20px', color: '#4B4C4B', fontFamily: 'Roboto' }}>
              {this.state.fullName}
            </Typography>
            <Typography style={{ fontWeight: 400, fontSize: '16px', color: '#C5CBC9', fontFamily: 'Roboto' }}>
              Dealer Group Name
            </Typography>
          </Box>
        </Box>

        <Box style={{ marginTop: '7px', gap: '15px' }}>
          <MenuItem data-test-id="editprofile" style={{ gap: '15px' }} onClick={this.handleEditProfileMenu}>
            <PersonOutlineOutlinedIcon style={{ color: '#013D4F' }} />
            <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#4B4C4B', fontFamily: 'Roboto' }}>Edit Profile</Typography>
          </MenuItem>

          <MenuItem data-test-id="contactusprofile" style={{ marginTop: '15px', gap: '15px' }} onClick={this.gotoContactUs}>
            <MailOutlineOutlined style={{ color: '#013D4F' }} />
            <Typography style={{ fontSize: '16px', color: '#4B4C4B', fontFamily: 'Roboto', fontWeight: 400 }} >Contact Us</Typography>
          </MenuItem>

          <MenuItem style={{ gap: '15px', marginTop: '15px' }}>
            <SettingsOutlinedIcon style={{ color: '#013D4F' }} />
            <Typography style={{ fontSize: '16px', color: '#4B4C4B', fontWeight: 400, fontFamily: 'Roboto' }}>Settings</Typography>
          </MenuItem>

          <Divider style={{ marginTop: '10px', width: '230px', marginLeft: '20px' }} />

          <MenuItem data-test-id="logoutprofile" style={{ marginTop: '25px', gap: '15px' }} onClick={this.handleLogoutMenu}>
            <ExitToAppOutlinedIcon style={{ transform: 'rotateY(180deg)', color: '#013D4F' }} />
            <Typography style={{ fontWeight: 400, fontSize: '16px', color: '#4B4C4B', fontFamily: 'Roboto' }}>Log Out</Typography>
          </MenuItem>
        </Box>
      </Menu>
    )
  }

  headerr = () => {
    return (
      <Box sx={webStyles.header}>
        <Box sx={webStyles.headerLeftSide}>
          <HamburgerMenuFull
            navigation={this.props.navigation}
            id={""}
            data-test-id="hamburgerMenuTestId" />

          <Box sx={webStyles.logo} style={{ marginLeft: '4px' }}>
            <img style={{ marginBottom: '5px', height: '24px', width: '52px' }} src={logo} alt="vitu logo" />
          </Box>
        </Box>

        <Box sx={webStyles.headerRightSide}>
          <Box
            data-test-id="notifications"
            sx={webStyles.notificationsButton}
          >
            <img src={notifications} alt="notifications button" />
          </Box>

          <ProfileDropdown
            navigation={this.props.navigation}
            id={""}
            data-test-id="profileDropdownTestId" />
        </Box>
      </Box>
    )
  }


  buttonSave = () => {
    if (this.state.savePhotoButtonEnable) {
      return (<Button data-test-id="savebtn" variant="outlined" onClick={this.savePhotoApi} style={{ color: '#013D4F', border: '1px solid #013D4F', borderRadius: '8px', marginTop: '10px', marginLeft: '10px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>Save</Typography></Button>)
    }
    return null
  }

  subscriptionButton = () => {
    if (this.checkRoleConditon(this.state.receivedRole)) {
      return (
        <Box style={{ display: 'flex', justifyContent: 'end', paddingTop: '50px', paddingRight: '50px' }}>
          <Button data-test-id="subsbutton" style={{ borderRadius: '26px', background: 'rgba(255, 255, 255, 0.5)' }} onClick={() => this.handleSelection('My Subscription Plan')}>
            <MotorcycleIcon style={{ color: '#013D4F' }} />
            <Typography style={{ textTransform: 'none', fontSize: '14px', fontWeight: 500, color: '#013D4F', marginLeft: '10px' }}>Free Plan</Typography>
          </Button>
        </Box>
      )
    }
    return null
  }

  profileSection = () => {
    return (
      <ProfileBox style={{ height: '590px', width: '632px', borderRadius: "24px", marginTop: '40px', boxShadow: '0px 3px 6px rgba(0,0,0,0.1)', background: 'linear-gradient(to bottom, #F0FAFA 190px, transparent 0px)', }}>

        {this.subscriptionButton()}

        <Box style={{ width: '540px', marginLeft: 'auto', marginRight: 'auto', paddingTop: this.checkRoleConditon(this.state.receivedRole) ? '52px' : '140px' }}>

          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
              <Box style={{ height: '98px', backgroundColor: '#F0FAFA', width: '98px', borderRadius: '24px', textAlign: 'center', backgroundImage: `url(${banner})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {this.state.changedPhoto ?
                  <Avatar variant="square" className="avatar" src={this.state.changedPhoto} style={{ width: '100%', borderRadius: '24px', height: '100%' }} />
                  : (<>
                    {this.state.initials ?
                      (<Avatar variant="square" style={{ height: '80px', width: '80px', borderRadius: '24px', backgroundColor: 'rgb(240, 250, 250)', color: '#013D4F', fontSize: '32px', fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.initials}
                      </Avatar>
                      ) : null}
                  </>)
                }
              </Box>
            </Box>
            <Box style={{ width: '400px', marginLeft: '20px' }}>
              <Box>
                {this.state.changedPhoto ?
                  (<Box>
                    <Button className="reupload" component="label" variant="outlined" style={{ marginTop: '10px', color: '#013D4F', border: '1px solid #013D4F', borderRadius: '8px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>Re-upload Photo</Typography>
                      <input type="file" hidden onChange={(event) => { this.handlePhotoChange(event) }} />
                    </Button>

                    <Button data-test-id="delete-picture-button" variant="outlined" onClick={this.handleDeletePicture} style={{ color: '#C43937', borderRadius: '8px', border: '1px solid #C43937', marginTop: '10px', marginLeft: '10px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>Remove Photo</Typography></Button>

                    {this.buttonSave()}
                  </Box>)
                  : (
                    <Box>
                      <Button component="label" variant="outlined" style={{ color: '#013D4F', border: '1px solid #013D4F', borderRadius: '8px', marginTop: '10px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>Upload Profile Photo</Typography>
                        <input hidden type="file" onChange={(event) => this.handlePhotoChange(event)} />
                      </Button>

                      {this.buttonSave()}
                    </Box>)
                }

                {this.errorBoxphoto()}
              </Box>
              <Box>
                <Typography color="secondary" style={{ color: '#C5CBC9', fontSize: '12px', marginTop: '5px' }} >Use images between 200x200 and 800x800 pixels in .jpg or .png format. If no picture is uploaded, your initials will be used.</Typography>
              </Box>
            </Box>
          </Box>

          <Box style={{ width: '100%', marginTop: '24px' }}>

            <TextField
              data-test-id="fullnameformik"
              disabled={!this.state.isChangeName}
              name="fullname"
              label="Full Name"
              variant="outlined"
              id="outlined-basic"
              fullWidth
              InputProps={{
                style: { borderRadius: "8px" },
                onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key.match(/\d/)) {
                    event.preventDefault();
                  }
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="changename"
                      onClick={!this.state.isChangeName ? this.handleEditName : this.handleChangeNameBe}
                      edge="end"
                    >
                      {!this.state.isChangeName ? <Typography style={{ color: '#013D4F', fontSize: '14px', fontWeight: 500 }}>Change Name</Typography>
                        : <Typography style={{ color: '#013D4F', fontSize: '14px', fontWeight: 500, textTransform: 'none' }}>Update</Typography>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: '#4B4C4B' }
              }}
              value={this.state.fullName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleNameChange(event) }}
              style={{ marginBottom: '20px' }}
            />


            <TextField
              data-test-id="emailtest"
              disabled
              type="email"
              name="email"
              label="Email Address"
              variant="outlined"
              fullWidth
              InputProps={{
                style: { borderRadius: '8px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="emailfield"
                      onClick={this.toggleDrawerEmail}
                      edge="end"
                    >
                      <Typography style={{ color: '#013D4F', fontSize: '14px', fontWeight: 500 }}>Change Email</Typography>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: '#4B4C4B' }
              }}
              value={this.state.myEmail}
              style={{ marginBottom: '20px' }}
            />

            <Box position="relative">
              <TextField
                type="tel"
                disabled
                label="Phone Number"
                variant="outlined"
                fullWidth
                data-test-id="phoneNumber"
                style={{ position: 'relative' }}
                value={this.state.myPhoneNumber}
                name="phoneNumber"
                id="phoneNumber"
                inputProps={{
                  maxLength: 10,
                  style: { borderRadius: "8px", marginLeft: '80px' }
                }}
                InputLabelProps={{
                  style: { color: '#4B4C4B' }
                }}
                InputProps={{
                  style: { borderRadius: "8px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="phonetest"
                        onClick={this.toggleDrawerPhone}
                        edge="end"
                      >
                        <Typography style={{ color: '#013D4F', fontSize: '14px', fontWeight: 500 }}>Change Number</Typography>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box position="end" style={{ cursor: "pointer", display: "flex", alignItems: "center", position: 'absolute', top: 0, left: 15 }}>
                <img src={usaflag} style={{ height: "14px", width: "18px" }} />
                <p style={{ marginLeft: "8px", fontSize: "16px", color: 'gray' }}>+</p>
                <Typography style={{ fontSize: "16px", color: 'gray', marginTop: "1px" }}>1</Typography>
                <p style={{ marginLeft: "12px", marginRight: "10px", color: 'gray', fontSize: "16px" }}>|</p>
              </Box>
            </Box>

            <TextField
              data-test-id="Password"
              disabled
              type="text"
              name="Password"
              label="Password"
              variant="outlined"
              id="outlined-basic"
              fullWidth
              InputProps={{
                style: { borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="phonechange"
                      onClick={this.toggleDrawerPassword}
                      edge="end"
                    >
                      <Typography style={{ color: '#013D4F', fontSize: '14px', fontWeight: 500 }}>Change Password</Typography>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: '#4B4C4B' }
              }}
              value={this.state.displayedPassword}
              style={{ marginTop: '20px' }}
            />

            {this.drawerForEmailEdit()}
            {this.drawerForPhoneEdit()}
            {this.drawerForResetPassword()}
            {this.state.openToastName && this.toastCall("Your name has been updated.", "name")}
          </Box>
        </Box>
      </ProfileBox>
    )
  }

  deActivateAccountSection = () => {
    return (
      <Box style={{ marginLeft: '0px', height: '590px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

        <DeactivateHeadingBox>
          <Box className="gothamText " style={{ fontWeight: 300, color: '#013D4F', fontSize: '24px' }}>Deactivate My Account</Box>
          <Typography style={{ marginTop: '10px', fontWeight: 300, color: '#4B4C4B', fontSize: '14px', fontFamily: 'Roboto' }}>Please let us know why you are deactivating your account. This will help us improve our services.</Typography>
        </DeactivateHeadingBox>

        <DeactivateMainBox className="maincontent" style={{ border: '1px solid #F0FAFA', padding: '40px', borderRadius: '16px', marginTop: '25px', display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: '1000px', minWidth: '500px' }}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-outlined-label" style={{ color: this.state.deactivateReason ? '#000' : '#C5CBC9', paddingLeft: this.state.deactivateReason ? '0px' : '10px' }}>Please choose the reason for deactivating your account.</InputLabel>
            <Select
              data-test-id="selectoption"
              labelId="deactivate-reason-label"
              id="deactivate-reason"
              value={this.state.deactivateReason}
              onChange={this.handleDeactivateReason}
              IconComponent={ExpandMoreIcon}
              label="Please choose the reason for deactivating your account."
              style={{
                borderRadius: '8px',
              }}

              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null,

                PaperProps: {
                  style: {
                    maxHeight: '800px',
                    borderRadius: '8px',
                  },
                },
              }}
              inputProps={{
                style: {
                  borderRadius: '8px',
                },
              }}
            >
              {this.optionForDeactivate.map((option, index) => (
                <MenuItemStyled key={index} value={option} style={{ height: '60px', color: 'rgba(1, 61, 79, 1)', fontSize: '16px', fontWeight: 300, fontFamily: 'Gotham' }}>
                  {option}
                </MenuItemStyled>
              ))}
            </Select>
          </FormControl>


          <Box style={{ position: 'relative', paddingTop: '3px' }}>
            <TextAreaStyledBox
              border={1}
              borderColor="#C5CBC9"
              borderRadius={8}
              p={1}
              pr={2}
              style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            >

              <StyledTextarea
                data-test-id="textarea"
                maxLength={100}
                onChange={this.handleTextAreaChange} aria-label="minimum height" minRows={3}
                placeholder="Your feedback is valuable to us."
                style={{ minHeight: '100px', outline: 'none', marginTop: '10px', height: '100px', width: '100%', border: 'none', fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', paddingLeft: '15px', paddingRight: '15px', resize: 'vertical', maxHeight: '200px' }}
              />


              <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Typography className="gothamText" style={{ fontWeight: 300, fontSize: '12px', color: 'rgba(51, 65, 85, 1)' }}>{this.state.charCount}/100</Typography>
              </Box>
            </TextAreaStyledBox>


            <Box display={'flex'} justifyContent={'start'} style={{ marginLeft: '30px', marginTop: '10px', marginBottom: '20px' }}>
              <Typography style={{ fontWeight: 500, fontSize: '12px', color: 'rgba(75, 76, 75, 1)', fontFamily: 'Roboto' }}>Please note your account will be deleted after 14 days.</Typography>
            </Box>
            {this.drawerForDeactivateEdit()}

          </Box>

        </DeactivateMainBox>


        <DeactivateButton style={{ marginLeft: '40px', maxWidth: '1000px', minWidth: '500px' }}>
          <Buttondb onClick={this.toggleDrawerDeactivate} data-test-id="deactivateaccountbutton" disabled={!this.state.deactivateReason} variant="contained" style={{ width: '100%', height: '56px', marginTop: '25px' }}>
            <Typography style={{ fontSize: '16px', textTransform: 'none', fontWeight: 700 }}>Continue</Typography>
          </Buttondb>
        </DeactivateButton>


      </Box>
    )
  }

  subscriptionPlanSection = () => {
    return (
      <Box style={{ marginLeft: '0px', marginTop: '50px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

        <HeadingBox>
          <Box className="gothamText " style={{ fontWeight: 300, color: '#013D4F', fontSize: '30px' }}>Simple pricing for all your needs</Box>
          <Typography className="gothamText" style={{ marginTop: '10px', textAlign: 'center', fontWeight: 300, color: '#4B4C4B', fontSize: '18px', marginLeft: 'auto', marginRight: 'auto' }}>Start for free, upgrade as you want.</Typography>
        </HeadingBox>

        <CardGrid className="cards" style={{ gap: '20px' }}>
          <style>{`
        .pricing-card {
          border-radius: 12px;
          border: 1px solid #E0E0E0;
          box-shadow: none;
          transition: border-color 0.3s ease;
          padding: 30px;
          max-height: 360px
        }

      `}</style>



          <CardBox style={{ width: '500px' }}>
            <Card className="pricing-card" style={{ border: '1px solid rgba(1, 61, 79, 1)' }}>
              <CardContent>
                <Typography style={{ fontWeight: 400, fontSize: '20px', color: 'rgba(75, 76, 75, 1)' }}>
                  Free Plan
                </Typography>
                <Typography style={{ marginTop: '16px', fontWeight: 500, fontSize: '30px', color: 'rgba(75, 76, 75, 1)' }}>
                  $0
                  <Typography component="span" style={{ marginLeft: '4px', fontSize: '20px', fontWeight: 400, color: 'rgba(75, 76, 75, 1)' }}>
                    /monthly
                  </Typography>
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    height: '50px',
                    marginTop: '24px',
                    textTransform: 'none',
                    borderRadius: '8px',
                    fontWeight: 500,
                    backgroundColor: 'rgba(1, 61, 79, 1)',
                    color: 'white'
                  }}
                >
                  Current Plan
                </Button>
                <Typography style={{ marginTop: '16px', fontSize: '14px', fontWeight: 400, color: 'rgba(75, 76, 75, 1)' }}>
                  Try Vitu free — then upgrade to thrive.
                </Typography>
                <Typography style={{ marginTop: '35px', fontSize: '16px', fontWeight: 500, color: 'rgba(75, 76, 75, 1)' }}>
                  3% Processing Fee
                </Typography>
                <Typography variant="body2" style={{ marginTop: '16px', fontSize: '16px', color: 'rgba(75, 76, 75, 1)' }}>
                  $60 Forms & Fees <span style={{ fontSize: '12px' }}>(free if using provider)</span>
                </Typography>
              </CardContent>
            </Card>
          </CardBox>


          <CardBox style={{ position: 'relative' }}>
            <Card className="pricing-card" style={{ zIndex: 2, height: '91%', position: 'relative' }}>
              <CardContent>
                <Typography
                  style={{ fontWeight: 400, fontSize: '20px', color: 'rgba(75, 76, 75, 1)' }}
                >
                  Premium Plan
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    marginTop: '16px',
                    fontSize: '30px',
                    color: 'rgba(75, 76, 75, 1)',
                  }}
                >
                  $15
                  <Typography
                    component="span"
                    style={{
                      fontWeight: 400,
                      fontSize: '20px',
                      marginLeft: '4px',
                      color: 'rgba(75, 76, 75, 1)',
                    }}
                  >
                    /monthly
                  </Typography>
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: 'white',
                    marginTop: '24px',
                    height: '50px',
                    textTransform: 'none',
                    fontWeight: 500,
                    borderRadius: '8px',
                    color: 'rgba(1, 61, 79)',
                    border: '1px solid rgba(1, 61, 79)',
                    boxShadow: 'none'
                  }}
                >
                  Upgrade
                </Button>
                <Typography
                  style={{
                    marginTop: '16px',
                    fontSize: '14px',

                    color: 'rgba(75, 76, 75, 1)',
                    fontWeight: 400,
                  }}
                >
                  Upgrade to Premium and Unlock Growth
                </Typography>
                <Typography
                  style={{
                    marginTop: '35px',
                    fontSize: '16px',
                    color: 'rgba(75, 76, 75, 1)',
                    fontWeight: 500,

                  }}
                >
                  2% Processing Fee
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginTop: '16px',
                    fontSize: '16px',
                    color: 'rgba(75, 76, 75, 1)',
                  }}
                >
                  $50 Forms & Fees{' '} <span style={{ fontSize: '12px' }}>(free if using provider)</span>
                </Typography>
              </CardContent>
            </Card>

            <Box
              style={{
                backgroundColor: 'rgba(240, 250, 250, 1)',
                marginTop: '-50px',
                zIndex: 1,
                height: '117px',
                borderRadius: '0 0 12px 12px',
                padding: '10px 15px',
                position: 'relative',
              }}
            >
              <Typography style={{ paddingTop: '55px', fontWeight: 500, fontSize: '16px', color: 'rgba(1, 61, 79, 1)', marginLeft: '15px', marginRight: '15px' }}>
                This is typically beneficial for users who do 10 transactions a month.
              </Typography>
            </Box>
          </CardBox>


          <CardBox style={{ position: 'relative' }}>
            <Card className="pricing-card" style={{ position: 'relative', height: '91%', zIndex: 2, }}>
              <CardContent>
                <Typography
                  style={{ fontWeight: 400, fontSize: '20px', color: 'rgba(75, 76, 75, 1)' }}
                >
                  Enterprise Plan
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    marginTop: '16px',
                    fontSize: '30px',
                    color: 'rgba(75, 76, 75, 1)',
                  }}
                >
                  $60
                  <Typography
                    component="span"
                    style={{
                      fontWeight: 400,
                      marginLeft: 4,
                      fontSize: '20px',
                      color: 'rgba(75, 76, 75, 1)',
                    }}
                  >
                    /monthly
                  </Typography>
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    marginTop: '24px',
                    height: '50px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontWeight: 500,
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    color: 'rgba(1, 61, 79)',
                    border: '1px solid rgba(1, 61, 79)',
                  }}
                >
                  Upgrade
                </Button>
                <Typography
                  style={{
                    marginTop: 16,
                    fontSize: '14px',
                    fontWeight: 400,
                    color: 'rgba(75, 76, 75, 1)',
                  }}
                >
                  Become an Industry Leader with Enterprise
                </Typography>
                <Typography
                  style={{
                    marginTop: '35px',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: 'rgba(75, 76, 75, 1)',
                  }}
                >
                  1% Processing Fee
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginTop: '16px',
                    fontSize: '16px',
                    color: 'rgba(75, 76, 75, 1)',
                  }}
                >
                  $40 Forms & Fees{' '}
                  <span style={{ fontSize: '12px' }}>(free if using provider)</span>
                </Typography>
              </CardContent>
            </Card>

            <Box
              style={{
                height: '117px',
                backgroundColor: 'rgba(240, 250, 250, 1)',
                marginTop: '-50px',
                zIndex: 1,
                borderRadius: '0 0 12px 12px',
                padding: '10px 15px',
                position: 'relative',
              }}
            >
              <Typography style={{ paddingTop: '55px', fontWeight: 500, fontSize: '16px', color: 'rgba(1, 61, 79, 1)', marginLeft: '15px', marginRight: '15px' }}>
                This is typically beneficial for users who do 20 transactions a month.
              </Typography>
            </Box>
          </CardBox>

        </CardGrid>
      </Box>
    )
  }

  renderContent = () => {
    switch (this.state.selectedItem) {
      case 'My Profile':
        return this.profileSection();
      case 'Deactivate Account':
        return this.deActivateAccountSection();
      case 'My Subscription Plan':
        return this.subscriptionPlanSection();
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box height={'100vh'}>
        <style>{`
          .MuiDrawer-paperAnchorRight{
            max-width:630px;
          }
        `}</style>

        {this.headerr()}

        <Box height={'88%'}>
          <HeadingsBox display={"flex"} flexDirection={"column"} style={{ paddingBottom: '40px', borderBottom: "1px solid #F0FAFA", rowGap: '0px', width: '70%', marginTop: '70px' }}>
            <Typography className="gothamText" style={{ fontWeight: 300, color: '#013D4F', fontSize: '30px' }} >{this.getHeader(this.state.selectedItem)}</Typography>
            <Typography className="gothamText" style={{ fontWeight: 300, color: '#4B4C4B', fontSize: '18px' }}>{this.getDescr(this.state.selectedItem)}</Typography>
          </HeadingsBox>

          <Box style={{ display: 'flex', flexDirection: 'row', height: '88%' }}>

            <MainBoxLeft style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <LeftSideBarBox className="leftsidebar" style={{ width: '200px', marginTop: '40px' }}>
                {this.state.loading ? (
                  <Typography style={{ textAlign: 'center', marginTop: '40px', color: '#C5CBC9' }}>
                    Loading...
                  </Typography>
                ) : (
                  <>
                    <Box style={{ marginTop: '30px', textAlign: 'right' }}>
                      <Button data-test-id="myprofile" onClick={() => this.handleSelection('My Profile')} style={{ textTransform: 'none' }}>
                        <Typography style={this.state.selectedItem === 'My Profile' ? { fontWeight: 500, color: '#013D4F' } : { fontWeight: 400, color: '#C5CBC9' }}>
                          My Profile
                        </Typography>
                      </Button>
                    </Box>

                    {this.checkRoleConditon(this.state.receivedRole) && (
                      <Box style={{ marginTop: '30px', textAlign: 'right' }}>
                        <Button data-test-id="subscription" onClick={() => this.handleSelection('My Subscription Plan')} style={{ textTransform: 'none' }}>
                          <Typography style={this.state.selectedItem === 'My Subscription Plan' ? { fontWeight: 500, color: '#013D4F' } : { fontWeight: 400, color: '#C5CBC9' }}>
                            My Subscription Plan
                          </Typography>
                        </Button>
                      </Box>
                    )}

                    <Box style={{ marginTop: '30px', textAlign: 'right' }}>
                      <Button data-test-id="deactivate" onClick={() => this.handleSelection('Deactivate Account')} style={{ textTransform: 'none' }}>
                        <Typography style={this.state.selectedItem === 'Deactivate Account' ? { fontWeight: 500, color: '#013D4F' } : { fontWeight: 400, color: '#C5CBC9' }}>
                          Deactivate Account
                        </Typography>
                      </Button>
                    </Box>

                    <Box style={{ textAlign: 'right', marginTop: '30px' }}>
                      <Button data-test-id="logout" style={{ textTransform: 'none' }} onClick={this.handleLogoutModal}>
                        <Typography style={{ fontWeight: 400, color: '#C5CBC9' }}>Log Out</Typography>
                      </Button>
                    </Box>
                  </>
                )}
              </LeftSideBarBox>

              <ImageBox>
                <img style={{ height: '100%', width: '87%' }} src={userDesign} />
              </ImageBox>
            </MainBoxLeft>

            <Box style={{ borderLeft: "2px solid #F0FAFA", left: "0%", height: this.state.selectedItem === "Deactivate Account" ? "480px" : '590px', top: "0%", marginTop: '40px', marginRight: '50px', marginLeft: '40px' }}></Box>

            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
              <Box className="maincontent">
                {this.renderContent()}
                {this.newModal()}
                {this.tourModal()}
                {this.state.openToastPhoto && this.toastCall("Profile picture updated", "name")}
              </Box>

              <Box style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '15px', justifyContent: 'end', marginRight: '15px', marginTop: '45px', minWidth: '620px' }}>
                <Button data-test-id="tcbutton" onClick={this.gotoTermsCondition} variant="text">
                  <Typography style={{ color: '#C5CBC9', fontSize: '14px', textDecoration: 'underline', fontWeight: 500, textTransform: 'none' }}>Terms and Conditions</Typography>
                </Button>

                <Button data-test-id="privacy" variant="text" >
                  <Typography style={{ marginLeft: '10px', textDecoration: 'underline', color: '#C5CBC9', textTransform: 'none', fontSize: '14px', fontWeight: 500 }}>Privacy Policy</Typography>
                </Button>

                {this.state.selectedItem === "My Profile" ?
                <Box position={'relative'}>
                  <Avatar  onClick={this.openProfilePopup} style={{marginLeft:"15px",marginRight:'5px',background:'#013D4F',color:'#fff',fontWeight:700,width:56,height:56}} data-test-id="profile_icon">{this.state.initials}</Avatar>
                  {this.state.profilePopup&&
                  <>
                  <ClickAwayListener onClickAway={this.openProfilePopup} data-test-id="click_Away">
                    <Box style={webStyles.profileLayout}>
                      <Box padding={'0.5rem'}>
                        <MenuItem style={webStyles.profileButton} onClick={this.openTourModal} data-test-id="dashboard_page_button">{configJSON.tourTest}</MenuItem>
                        <MenuItem style={webStyles.profileButton} onClick={this.gotoContactUs} data-test-id="contact_us_button">{configJSON.contactText}</MenuItem>
                      </Box>
                    </Box>
                  </ClickAwayListener>
                  </>
                  }
                  </Box>

                  :
                  <Button onClick={this.gotoContactUs} data-test-id="contactbutton" variant="text" >
                    <Typography style={{ marginLeft: '10px', textDecoration: 'underline', textTransform: 'none', color: '#C5CBC9', fontSize: '14px', fontWeight: 500 }}>Contact Us</Typography>
                  </Button>
                }



              </Box>
            </Box>

          </Box>
        </Box>

        <ToastContainer
          style={{ width: '450px' }}
        />


      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const TextAreaStyledBox = styled(Box)({
  "@media (max-width:1200px)": {
    width: '95%'
  },
  "@media (min-width:1201px)": {
    width: '97%'
  }
})

const PhoneDrawerBox = styled(Box)({
  "@media (min-width:300px) and (max-width:399px)": {
    minWidth: '320px'
  },
  "@media (min-width:400px) and (max-width:570px)": {
    minWidth: '420px'
  },
  "@media (min-width:571px)": {
    minWidth: '630px'
  }
})

const StyledTextarea = styled(TextareaAutosize)({
  '&::placeholder': {
    color: '#C5CBC9',
    opacity: 1,
  }
})

const DeactivateHeadingBox = styled(Box)({
  "@media (max-width: 1030px)": {
    width: '650px',
  },
});

const ImageBox = styled(Box)({
  "@media (max-width: 1030px) and (max-height: 650px)": {
    marginBottom: '-189px',
  },
});

const DeactivateMainBox = styled(Box)({
  "@media (max-width:1200px)": {
    width: '60%'
  },
  "@media (min-width:1201px)": {
    width: '80%'
  }
})

const DeactivateButton = styled(Box)({
  "@media (max-width:1200px)": {
    width: '60%'
  },
  "@media (min-width:1201px)": {
    width: '80%'
  }
})

const MenuItemStyled = styled(MenuItem)({
  '&:hover': {
    backgroundColor: 'rgba(240, 250, 250, 1)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(240, 250, 250, 1)',
  },
})



const Buttondb = styled(Button)({
  '&:disabled': {
    color: '#75D3F0',
    width: '100%',
    backgroundColor: '#F0FAFA', height: '50px',
    '@media (max-width: 600px)': {
      width: "100%",
    },
    '@media (max-width: 700px)': {
      width: "100%",
    },
  },
  '&:enabled': {
    color: 'white',
    width: '100%',
    backgroundColor: '#4FC8EC', height: '50px',
    '@media (max-width: 600px)': {
      width: "100%",
    },
    '@media (max-width: 700px)': {
      width: "100%",
    },
  },

});

const CardGrid = styled(Box)({
  "@media (max-width:1300px)": {
    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', width: '100%'
  },
  "@media (min-width:1301px)": {
    display: 'flex', flexDirection: 'row'
  }
})

const StyledModalBox = styled(Box)({
  "@media (min-width:100px) and (max-width:299px)": {
    width: '200px',
  },
  "@media (min-width:300px) and (max-width:399px)": {
    width: '300px',
  },
  "@media (min-width:400px) and (max-width:529px)": {
    width: '430px',
  },
  "@media (min-width:530px)": {
    width: '512px',
  },
  border: '1px solid #FFFFFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  borderRadius: '8px',
  backgroundColor: '#FFFFFF'
})

const HeadingBox = styled(Box)({
  "@media (max-width:1300px)": {
    display: 'flex', flexDirection: 'column', width: '500px', marginBottom: '30px', justifyContent: 'start'
  },
  "@media (min-width:1301px)": {
    display: "flex", flexDirection: 'column', width: '512px', marginBottom: '30px', marginLeft: 'auto', marginRight: 'auto'
  }
})

const CardBox = styled(Box)({
  "@media (max-width:1100px)": {
    maxWidth: '500px',
  },
  "@media (min-width:1101px) and (max-width:1600px)": {
    maxWidth: '300px',
  },
  "@media (min-width:1601px)": {
    maxWidth: '350px'
  }
})


const ProfileBox = styled(Box)({
  "@media (max-width:1060px)": {
    marginLeft: '5px'
  },
  "@media (min-width:1061px)": {
    marginLeft: '50px'
  }
})

const LeftSideBarBox = styled(Box)({
  "@media (max-width:1500px)": {
    marginLeft: '50px'
  },
  "@media (min-width:1501px)": {
    marginLeft: '260px'
  }
})

const MainBoxLeft = styled(Box)({
  "@media (max-width:1500px)": {
    width: '230px'
  },
  "@media (min-width:1501px)": {
  }
})

const HeadingsBox = styled(Box)({
  "@media (max-width:1500px)": {
    marginLeft: '100px',
  },
  "@media (min-width:1501px)": {
    marginLeft: '300px',
  }
})

interface Styles {
  [key: string]: any;
}

const webStyles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  profileButton:{
    height:'56px',
    display:'flex',
    alignItems:'center',
    color:"#013D4F",
    fontWeight:400,
    fontsize:'16px'
  },
  profileLayout:{
    background:"#ffffff",
    borderRadius:"8px",
    position:'absolute',
    width:"206px",
    bottom:'50px',
    right:'0px',
    boxShadow: "4px 5px 15px 0px #00000012"

  },
  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 32px",
    borderBottom: "1px solid #F0FAFA",
    minWidth: '900px'
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "0px",
  },

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
  },

  logo: {
    width: "52px",
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
  },


  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },

  main: {
    display: "flex",
    flexWrap: "nowrap",
    position: "relative",
  },


  buttonText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "14px",
    fontWeight: 500,
  },

  content: {
    width: "100%",
    marginLeft: "120px",
  },

}
// Customizable Area End
