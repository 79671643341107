import React from "react";
// Customizable Area Start
import {
  TextField,
  Checkbox,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  InputAdornment,
  Grid,
  Avatar,
  InputLabel,
  Select,
  MenuItem,
  Drawer,
  Paper,
  Dialog, DialogActions, DialogContent, 
  Modal,
  FormHelperText,
  Backdrop,
  ListItemText,
  Divider} from "@material-ui/core";
import { MuiThemeProvider, styled, createTheme } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import { arrow, infoIcon, logo, mapIcon, profileBackground, searchIcon, emptyProfile, emptyCheckbox, filledCheckbox, flagUsa, arrowTop, fileUpload, pdfFile, closeBtn, finalbg, jpegFile, pngFile, invitationSuccess, pencilGrey, trashGrey, blackClose, arrowRight, radioButtonActive, radioButtonInactive} from "./assets";
export const configJSON = require("./config");
import 'react-phone-input-2/lib/style.css';
import { Formik, Form, FormikTouched, FormikErrors} from 'formik';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import FastForwardOutlinedIcon from '@material-ui/icons/FastForwardOutlined';
import CreateIcon from '@material-ui/icons/Create';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import PDFViewer from './../../../components/src/PDFViewer.web'
import SignatureCanvas from 'react-signature-canvas';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import {notifications} from "../../../blocks/dashboard/src/assets";
import CloseIcon from '@material-ui/icons/Close';
 
interface CityType {
  value: string;
  displayValue: string;
}
 
interface Styles {
  [key: string]: any;
}
 
const tooltipTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: "254px",
        padding: "16px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 18px 32px 0 rgba(0, 0, 0, 0.25)",
 
        "& .heading": {
          fontSize: "14px",
          color: "013D4F",
          fontWeight:500
        },
        "& .text": {
          fontSize: "12px",
          color: "#4B4C4B",
          fontWeight:500
        },
        "@media only screen and (max-width: 768px)": {
          maxWidth: "254px",
        }
      },
    },
    MuiCheckbox: {
      root: {
        "&$checked": {
          "color": "#4FC8EC"
        }
      },
    }
  }
})
 
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
 
import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";
import { Autocomplete } from "@material-ui/lab";
import { CloseRounded } from "@material-ui/icons";
import ProfileDropdown from "./ProfileDropdown.web";
import HamburgerMenuFull from "./HamburgerMenuFull.web";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  isDragActiveFunction = (isDragActive: boolean, isShowToolTipIcon?: boolean,isW9Form:boolean=false) => {
    return (
      isDragActive ?
        <Typography data-test-id="dragTextTest">Drop the file here ...</Typography> :
        <Box style={{ height: "151px", width: "100%", display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "space-between" }}>
          <img src={fileUpload} style={{ marginLeft: "10px" }} />
          <Box style={{ height: "77px", paddingBottom: "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography className="gothamText" style={{ fontWeight: 300, fontSize: "24px", color: "#013D4F", marginLeft: '10px', lineHeight: "32px" }}>Drag & Drop</Typography>
            <MuiThemeProvider theme={tooltipTheme}>
              <Box style={{ display: "flex", alignItems: "center", position: "relative", marginLeft: "30px" }}>
                <Typography style={{ marginTop: "10px", fontSize: "14px", fontWeight: 500, color: "#4B4C4B", lineHeight: "22px",marginRight:isW9Form ? "12px":"0px" }}>or click here to browse your files</Typography>
                {isShowToolTipIcon &&
                <Tooltip style={{ width: "25px", height: "20px", top: 4 }} title={<><p style={{color:"#013D4F"}}>Upload Your Signature</p><p className="text">Please upload a clear image of your signature (recommended size: 800x400 pixels). Ensure it is well-lit and fully visible.</p></>} className="infoTextVoidedCheck" arrow>
                  <IconButton aria-label="info">
                    <img src={infoIcon} alt="info" />
                  </IconButton>
                </Tooltip>}
              </Box> 
              </MuiThemeProvider>
          </Box>
          <Typography style={{ fontWeight: 500, fontSize: "12px", color: "#4B4C4B", lineHeight: "18px",marginRight: !isShowToolTipIcon ? "-20px" : "-11px" }}>{isW9Form ? "(Please upload the file in only .pdf formats)":"(Please upload the file in only .jpg & .png formats)"}</Typography>
        </Box>
    )
  }
 
  isDragActiveFunctionVoidedCheck = (isDragActive: boolean) => {
    return (
      isDragActive ?
        <Typography data-test-id="dragTextTest">Drop the file here ...</Typography> :
        <Box style={{ height: "151px", width: "100%", display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "space-between" }}>
          <img src={fileUpload} style={{marginLeft:"10px"}}/>
          <Box style={{ height: "77px", paddingBottom: "10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center" }}>
            <Typography className="gothamText" style={{ fontWeight: 300, fontSize: "24px", color: "#013D4F", marginLeft: '10px' }}>Drag & Drop</Typography>
            <Typography style={{ marginTop: "10px", fontSize: "14px", fontWeight: 500, color:"#4B4C4B",lineHeight:"22px"}}>or click here to browse your files</Typography>
          </Box>
          <Typography style={{ fontWeight: 500, fontSize: "12px", color:"#4B4C4B",lineHeight:"18px"}}>(You may upload .jpg, .png, or .pdf formats)</Typography>
        </Box>
    )
  }
 
  voidedCheckDisplayImage = () => {
    if (this.state.voidedCheckFile)
    {
      const fileType = this.state.voidedCheckFile.type;
      if (fileType === 'application/pdf' || fileType === 'image/pdf') return <img src={pdfFile} />
      else if (fileType.startsWith('image/jpeg')) return <img src={jpegFile} />
      else if ( fileType.startsWith('image/jpg') ) return <img src = {jpegFile}/>
      else if (fileType === 'image/png') return <img src={pngFile} />
    }
  }

  fonts = [
    { name: "Font 1", fontFamily: "Ruslan Display" },
    { name: "Font 2", fontFamily: "Rubik Wet Paint" },
    { name: "Font 3", fontFamily: "Russo One" },
    { name: "Font 4", fontFamily: "Ruthie" },
    { name: "Font 5", fontFamily: "Sacramento" },
  ];

 
  BpIcon = styled('span')({
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    boxShadow: 'inset 0 0 0 1px gray, inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#fff',
  });
  
   BpCheckedIcon = styled(this.BpIcon)({
    backgroundColor: '#4FC8EC',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'inset 0 0 0 2px #4FC8EC, inset 0 -2px 0 rgba(16,22,26,.1)',
    '&::before': {
      display: 'block',
      width: '20px',
      height: '20px',
      backgroundImage: 'radial-gradient(#FFFFFF,#FFFFFF 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#4FC8EC',
    },
  });
 
  getDate() {
    const date = new Date();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    const hour12 = hours % 12 || 12;
  
    return `${month} ${day}, ${year} on ${hour12}:${minutes}`;
  }
  
  handleDisableFinishButton() {
    const { isSignCheck, uploadFullSignature, uploadIntitalSignture, drawSignature } = this.state.taxInfoDetails;
    const { activeTab } = this.state;

    if (!isSignCheck) return true;

  
    const isTab3Ready = uploadFullSignature.length > 0 && uploadIntitalSignture.length > 0 && activeTab === 'tab3';
    const isTab2Ready = drawSignature.length > 0 && activeTab === 'tab2';
    const isTab1Ready = this.state.signatureName && activeTab === 'tab1';
  
    return !(isTab3Ready || isTab2Ready || isTab1Ready);
  }
  
  clearSignature = () => {
    this.drawSignCanvasRef.current?.clear();
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        drawSignature: ""
      }
    }));
  };
  
  saveSignature = () => {
    const { activeTab } = this.state;
    this.setState((prevState) => {
      const updatedDetails = { ...prevState.taxInfoDetails };
      
      if (activeTab === 'tab2') {
        updatedDetails.drawSignature = this.state.taxInfoDetails.drawSignature;
        updatedDetails.uploadFullSignature = this.state.taxInfoDetails.drawSignature;
        updatedDetails.uploadIntitalSignture = '';
      } else if (activeTab === 'tab3') {
        updatedDetails.drawSignature = '';
      } else if (activeTab === 'tab1') {
        this.convertTextToImage();
      }
      
      this.setState({
        isEsignFinish:true,
        signatureName:'',
        signInitials:''})
      
      return { taxInfoDetails: updatedDetails };
    });
    this.handleSignatureClose();
  
  };
  
  endDrawSignature = () => {
    if (!this.drawSignCanvasRef.current) return;
  
    const dataURL = this.drawSignCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
  
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        drawSignature: dataURL,
      },
    }));
  };
  
  getSubtitle = () => {
    if(this.state.isFirstDealership) {
      return "Set up your first dealership to get started"
    } else if (this.state.editDealershipId) {
      return "Change the details for this dealership"
    } else {
      return "Please fill the details below to add a new Dealership to your Dealer Group."
    }
  }
 
  renderStepContent = (stepNumber:number) => {
    const isActive = this.state.step === stepNumber;
    const isDone = this.state.doneSteps.includes(stepNumber) && !isActive;
    const isSkipped = this.state.skippedSteps.includes(stepNumber) && !isActive;
    if (isActive) return <img src={arrow} alt="arrow" />
         else if (isDone || isSkipped) {
          return <CreateIcon style={{ width: '24px', height: '24px', color:"#DEE4E2" }} />
          } else {
          return stepNumber
        }
  }
 
  renderNavItem = (stepNumber: number, title: string, subtitle: string) => {
    const isActive = this.state.step === stepNumber;
    const isDone = this.state.doneSteps.includes(stepNumber) && !isActive;
    const isSkipped = this.state.skippedSteps.includes(stepNumber) && !isActive;
    const isClickable = this.state.editDealershipId || stepNumber <= (1 + Math.max(...this.state.doneSteps, ...this.state.skippedSteps));
    const boxStyle = () => {
      let style = 'stepItem';
      if (isActive) {
        style += ' active';
      }
      else if (isDone) {
        style += ' done'
      }
      else if (isSkipped) style += ' skipped';
      if (isClickable) style += ' clickable';
      return style;
    };
    return (
      <Box
        key={stepNumber}
        className={boxStyle()}
        onClick={() => isClickable && this.changeStep(stepNumber)}
        id={`step${stepNumber}`}
      >
        <Box className="stepText">
          <Box className="stepTitle">
            <Typography style={{
              fontSize:"20px", 
              fontWeight:500, 
            }}>
                {title}</Typography>
            {isDone && <CheckCircleOutlinedIcon style={{ color: '#26BBB3', width:"20px", height:"20px", marginBottom:"4px" }} />}
            {isSkipped && <FastForwardOutlinedIcon style={{ color: '#F05E22', marginBottom:"4px" }} />}
          </Box>
          <Typography className="stepSubtitle">{subtitle}</Typography>
        </Box>
        <Box className="stepNumber">
        {this.renderStepContent(stepNumber)}
        </Box>
      </Box>
    );
  };
 
  renderContent = () => {
    switch (this.state.step) {
      case 1:
        return this.step1();
      case 2:
        return this.step2();
      case 3:
        return this.step3();
      case 4:
        return this.step4();
    }
  };
 
  renderAutoCompleteAddress = () => {
    return (
      <Autocomplete
        fullWidth
        options={this.state.addressPredictions}
        getOptionLabel={(option) => option.description}
        data-test-id="dealership_address"
        popupIcon={null}
        onInputChange={(event, newInputValue) => {
          this.fetchPredictions(newInputValue);
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            this.handlePlaceSelect(newValue.place_id);
          }
        }}
        PaperComponent={(props) => (
          <StyledPaper data-test-id="paperStyledId" {...props} elevation={8} style={{ fontWeight: 400, fontSize: "16px" }} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            placeholder="Search Dealership Address"
            data-test-id="dealership_address_text"
            style={{ width: "100%" }}
            value={this.state.dealerDetails.dealership_address}
            onChange={(event) => this.handleAddressChange(event)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="end" style={{ cursor: "pointer" }}>
                    <img src={searchIcon} alt="map" id="searchIcon" />
                  </InputAdornment>
                </>
              ),
              endAdornment: (
                <>
                  <InputAdornment position="end" style={{ cursor: "pointer" }}>
                    <img src={mapIcon} alt="map" id="mapIcon" />
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
      />
    )
  }
 
  renderAutocompleteAddressShipping = () => {
    return (
      <Autocomplete
        fullWidth
        options={this.state.shippingAddressPredictions}
        getOptionLabel={(option) => option.description}
        disabled={this.state.isSameAddress}
        data-test-id="dealership_shipping_address"
        popupIcon={null}
        onInputChange={(event, newInputValue) => {
          this.fetchPredictionsShipping(newInputValue);
        }}
        onChange={(event, value) => {
          if (value) {
            this.handlePlaceSelectShipping(value.place_id);
          }
        }}
        PaperComponent={(props) => (
          <StyledPaper data-test-id="paperStyledShippingId" {...props} elevation={8} style={{ fontWeight: 400, fontSize: "16px" }} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search Dealer Shipping Address"
            data-test-id="dealership_shipping_address_text"
            onChange={this.handleDealershipShippingAddressChange}
            value={this.state.dealerDetails.dealership_shipping_address}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="end" style={{ cursor: "pointer" }}>
                    <img src={searchIcon} alt="map" id="searchIcon" />
                  </InputAdornment>
                </>
              ),
              endAdornment: (
                <>
                  <InputAdornment position="start" style={{ cursor: "pointer" }}>
                    <img src={mapIcon} alt="map" id="mapIcon" />
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
      />
    )
  }
 
  renderAutocompleteAddressBank = () => {
    return (
      <Autocomplete
        fullWidth
        style={{position:'relative'}}
        options={this.state.bankAddressPredictions}
        getOptionLabel={(option) => option.description}
        data-test-id="bank_address"
        popupIcon={null}
        onInputChange={(event, newInputValue) => {
          this.fetchPredictionsBank(newInputValue);
        }}
        onChange={(event, value) => {
          if (value) {
            this.handlePlaceSelectBank(value.place_id);
          }
        }}
        PaperComponent={(props) => (
          <StyledPaper data-test-id="paperStyledBankId" {...props} elevation={8} style={{ fontWeight: 400, fontSize: "16px" }} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search Bank Address"
            data-test-id="bank_address_text"
            onChange={this.handleBankAddressChange}
            value={this.state.thirdFormAddressDetails.address}
            InputProps={{
              ...params.InputProps,
              startAdornment: (<>
                  <InputAdornment position="end" 
                    style={{ cursor: "pointer" }}>
                    <img 
                      src={searchIcon} 
                      alt="map" 
                      id="searchIcon" 
                    />
                  </InputAdornment>
                </>
              ),
              endAdornment: (
                <>
                  <InputAdornment position="start" 
                  style={{ cursor: "pointer" }}>
                    <img 
                      src={mapIcon} 
                      alt="map" 
                      id="mapIcon" />
                  </InputAdornment>
                </>
              ),}}
          />
        )}
      />
    )
  }
 
  renderThirdFormVoidedCheck = () => {
    return (
      <Box style={{ position: "relative", marginTop: "20px" }}>
        <Box className="DealerGroupBox">
          <Box>
            <Typography className="dealerGroupText" style={{ marginBottom: "10px" }}>Voided Check</Typography>
            <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#4B4C4B" }}>Please upload a voided check for bank verification</Typography>
          </Box>
          <Box style={{ position: "relative" }}>
            <Box style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: 'center', position: "relative", marginTop: "10px" }}>
              <Box style={{ width: "100%", height: "196px", marginTop: "20px" }}>
                <Dropzone onDrop={this.onDropVoidedCheck} multiple={false} data-test-id="dropZoneVoidedCheckTestId"
                  accept={{
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/png': ['.png'],
                    'application/pdf': ['.pdf']
                  }}
                >
                  {({ getRootProps, isDragActive, getInputProps, }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        height: "196px",
                        backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        border: "1px solid #C5CBC9",
                        borderRadius: "8px",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      <input {...getInputProps()} />
                      {this.state.voidedCheckFile ? (
                        <Box className="test12" style={{ height: "171px", width: "100%", display: "flex", flexDirection: 'column' }}>
                          <Box className="test123" style={{ width: "100%", height: "11.67px", marginTop: "10px" }}>
                            <div data-test-id="removeTaxPhotoDiv" style={{ textAlign: 'right' }}>
                              <Button data-test-id="removeTaxPhotoVoidedCheck" onClick={this.removeFileVoidedCheck} 
                              style={{ width: "20px", height: "20px", marginRight: "22px", border: "0px", backgroundColor: 'white' }}><img src={closeBtn} style={{ width: "20px", height: "20px", color:"#4B4C4B" }} /></Button>
                            </div>
                          </Box>
                          <Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            {this.voidedCheckDisplayImage()}
                            <Typography style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginTop: "10px" }}>{this.state.voidedCheckFile.name}</Typography>
                          </Box>
                        </Box>
                      ) : this.isDragActiveFunctionVoidedCheck(isDragActive)}
                    </div>
                  )}
                </Dropzone>
                <Box style={{ position: "absolute", top: 4, left: 17, backgroundColor: "white", padding: "0px 4px" }}><Typography style={{ color: "#4B4C4B", fontWeight: 500, fontSize: "12px" }}>Upload Voided check</Typography></Box>
              </Box>
            </Box>
            <Tooltip title={<><p className="heading">Upload Voided check</p><p className="text">Upload a copy of your voided check or documentation from your bank confirming bank account and bank routing number</p></>} className="infoTextVoidedCheck" arrow>
              <IconButton aria-label="info">
                <img src={infoIcon} alt="info" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    )
  };

  InviteUserModal = () => {
    const {
      invitationSuccessScreen,
      roles,
      invitedUsers,
      dealershipsData,
      inviteToDealership,
      invitedUserRole,
      singleDealershipInfo,
      invitedUserEmail,
      userData,
    } = this.state;
    return (
      <InviteUserBackdrop
        open={this.state.showInviteUser}
      >
        <Box className="inviteContainer">
          <CloseRounded onClick={() => this.setCloseInviteUserModal(false)} data-test-id="closeDrawerInviteUser" width={18} style={{ position: "absolute", right: "60px", cursor: "pointer" }} />
          {invitationSuccessScreen ?
            (
              <Box className="successInvite">
                <img
                  className="successInviteImage"
                  src={invitationSuccess}
                  alt="invite success"
                />
                <Box className="successMessageBlock">
                  <Typography className="successTitle">
                    Invitation Sent
                  </Typography>
                  <Typography className="successDescription">
                    The user will get an email with a link to join.
                  </Typography>
                </Box>

                <Box className="successButtonsContainer">
                  <button className="successViewDealership" onClick={() => this.navigateToDashboard(false)}>
                    View Dealership
                  </button>
                  <button
                    onClick={this.closeInvitationSuccess}
                    className="successInviteMore"
                  >
                    Invite More Users
                  </button>
                </Box>
              </Box>
            ) :
            (
              <>
                <Box className="inviteHeader">
                  <Typography className="inviteTitle">Invite User</Typography>
                  <Typography className="inviteDescription">
                    Please fill the information below to add this user.
                  </Typography>
                </Box>

                {Boolean(invitedUsers.length) && (
                  <Box className="inviteUsersContainer">
                    <Typography className="addedUserTitle">Added User</Typography>

                    <Box className="usersList">
                      {invitedUsers.map((user, i) => (
                        <Box className="userItem" key={user.email + i}>
                          <Box className="userBlankAvatar">
                            {user.email.slice(0, 2).toUpperCase()}
                          </Box>
                          <Box className="userInfoContainer">
                            <Typography className="userInfoEmail">
                              {user.email}
                            </Typography>
                            <Typography className="userInfoDealerships">
                              {dealershipsData
                                .filter((dealership) =>
                                  user.dealer_information_ids.includes(
                                    dealership.id
                                  )
                                )
                                .map((dealership) => dealership.name)
                                .join(", ")}
                            </Typography>
                          </Box>
                          <Box className="userActionsContainer">
                            <Typography className="userRole">
                              {user.dealership_role_id === "1" ? "Admin" : "User"}
                            </Typography>
                            <img
                              onClick={() => this.editInvitedUser(user)}
                              className="editUser"
                              data-test-id="editInvitedUserImageTestId"
                              src={pencilGrey}
                              alt="edit user"
                            />
                            <img
                              onClick={() => this.deleteInvitedUser(user.email)}
                              className="deleteUser"
                              src={trashGrey}
                              alt="delete user"
                            />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}

                <Box className="inviteFormContainer">
                  {Boolean(invitedUsers.length) && (
                    <Typography className="formTitle">
                      Add Another User
                    </Typography>
                  )}
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="dealership-select-label">
                      Choose Dealership
                    </InputLabel>
                    <Select
                      labelId="dealership-select-label"
                      data-test-id="chooseDealershipSelect"
                      value={inviteToDealership}
                      renderValue={() =>
                        inviteToDealership
                          .map(
                            (dealershipId) =>
                              dealershipsData.find(
                                (dealershipToFind) =>
                                  dealershipToFind.id === dealershipId
                              )?.name
                          )
                          .join(", ")
                      }
                      onChange={this.handleDealershipSelect}
                      disabled={!userData?.attributes.isDealerGroup}
                      label="Choose Dealership"
                      multiple
                    >
                      {userData && userData.attributes.soloDealershipId ? (
                        <MenuItem value={userData.attributes.soloDealershipId}>
                          {singleDealershipInfo?.name}
                        </MenuItem>
                      ) : (
                        dealershipsData && dealershipsData.length > 0 && dealershipsData.map((dealership) => (
                          <MenuItem key={dealership.id} value={dealership.id}>
                            <ListItemText primary={dealership.name} />
                            <StyledCheckbox
                              checked={inviteToDealership.includes(dealership.id)}
                            />
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>

                  <TextField
                    placeholder="Email Address"
                    value={invitedUserEmail}
                    onChange={this.setInviteUserEmail}
                    data-test-id="setInviteUserEmailTextField"
                    inputProps={{
                      maxLength: 80,
                    }}
                    variant="outlined"
                    label="Email Address"
                  />

                  <InviteRadioGroupContainer>
                    <Typography className="radioLabel">Choose Role</Typography>
                    <FormControl>
                      <RadioGroup
                        value={invitedUserRole}
                        onChange={this.setInviteUserRole}
                        data-test-id="inviteUserRadioTestId"
                        row
                      >
                        {roles.map(role => <FormControlLabel
                          key={role.id}
                          value={role.id}
                          control={<Radio />}
                          label={role.attributes.label}
                        />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </InviteRadioGroupContainer>

                  {Boolean(
                    (inviteToDealership.length && invitedUserEmail) ||
                    invitedUsers.length
                  ) && (
                      <button
                        onClick={this.setInviteUsers}
                        data-test-id="addAnotherUserButtonId"
                        className="addAnotherUserButton"
                      >
                        Add Another User
                      </button>
                    )}
                </Box>
                <button
                  onClick={this.inviteUsersToDealership}
                  data-test-id="invite-user-button"
                  type="button"
                  className="inviteDealershipsUsers"
                  disabled={!inviteToDealership.length || !invitedUserEmail}
                >
                  Invite User
                </button>
              </>
            )}
        </Box>
      </InviteUserBackdrop>
    );
  };
 
  errorBox = () => {
    if (this.state.photoErrorMessage) {
      return (
        <Typography style={{ color:'#C43937', marginTop:'7px', marginLeft:'4px', fontSize:'12px' }}>
          {this.state.photoErrorMessage}
        </Typography>
      );
    } else {
      return null;
    }
  }
 
  errorCheckBox = () => {
    if (this.state.checkboxesTouched && this.state.dealerDetails.checkedBoxes.length == 0) {
      return (
        <Typography style={{ color:'#C43937', marginTop:'7px', marginLeft:'4px', fontSize:'12px' }}>
          At least one type of deal is required
        </Typography>
      );
    } else {
      return null;
    }
  }
 
  errorPhoneNumberContactInfo = (touched: FormikTouched<{ fullName: string; phoneNumber: string; email: string; }>, errors: FormikErrors<{
    fullName: string;
    phoneNumber: string;
    email: string;
  }>) => {
    return (
      Boolean(this.state.errorsContactBackendPhoneNumber) || touched.phoneNumber && !!errors.phoneNumber
    )
  }
 
  helperTextPhoneNumberContactInfo = (touched: FormikTouched<{ fullName: string; phoneNumber: string; email: string; }>, errors: FormikErrors<{
    fullName: string;
    phoneNumber: string;
    email: string;
  }>) => {
    return (
      (this.ErrorBoxx(Boolean(this.state.errorsContactBackendPhoneNumber), this.state.errorsContactBackendPhoneNumber)) || (touched.phoneNumber && errors.phoneNumber)
    )
  }
 
  errorEmailContactInfo = (touched: FormikTouched<{ fullName: string; phoneNumber: string; email: string; }>, errors: FormikErrors<{
    fullName: string;
    phoneNumber: string;
    email: string;
  }>) => {
    return (
      Boolean(this.state.errorsContactBackendEmail) || (touched.email && !!errors.email)
    )
  }
 
  helperTextEmailContactInfo = (touched: FormikTouched<{ fullName: string; phoneNumber: string; email: string; }>, errors: FormikErrors<{
    fullName: string;
    phoneNumber: string;
    email: string;
  }>) => {
    return (
      (this.ErrorBoxx(Boolean(this.state.errorsContactBackendPhoneNumber), this.state.errorsContactBackendPhoneNumber)) || (touched.email && errors.email)
    )
  }
 
  handleDropZoneData = (isFullSignature : boolean) => {
    return (
    isFullSignature ? this.state.taxInfoDetails.fullSignatureUploadView : this.state.taxInfoDetails.initialSignatureUploadView
    )
  }
 
  handleUploadSignature = (isFullSignature : boolean) => {
    return (
      isFullSignature ? this.state.taxInfoDetails.uploadFullSignature : this.state.taxInfoDetails.uploadIntitalSignture
    )
  }
 
  handleCognitiveFinishOrNextStep = () => {
    return (
      this.state.step == 4 ? "Finish" : "Next Step"
    )
  }

  handleCognitiveTypeItIn = () => {
    return (
      this.state.signInitials || this.state.signatureName) &&
        <Box style={{ marginTop: "20px", width: '100%', marginLeft:'20px',  }}>
          {this.fonts.map((font) => (
            <Box key={font.name} style={{ display: "flex", alignItems: "center", margin: '10px 0', width: '672px', height: '80px' }}>
              <Box style={{ display: 'flex', width: '100%' }}>
                <Box
                  style={{
                    fontSize: "24px",
                    marginRight: "10px",
                    display: 'flex',
                    justifyContent: 'space-between',
                    minWidth: '52%'
                  }}
                >
                  <Box>
                    <Typography>Signature Name</Typography>
                    <Typography style={{fontFamily: font.fontFamily, fontSize: "22px", fontWeight:400, color:'#4B4C4B'}}>{this.state.signatureName}</Typography>
                  </Box>
                  <Box>
                    <Typography>Initials</Typography>
                    <Typography style={{fontFamily: font.fontFamily, fontSize: "22px", fontWeight:400, color:'#4B4C4B'}}>{this.state.signInitials}</Typography>
                  </Box>
                </Box>
              </Box>

              <Radio
                checked={this.state.selectedFont === font.fontFamily}
                onChange={() => this.handleFontSelection(font.fontFamily)}
                checkedIcon={<img src={radioButtonActive} width='32px' style={{width:'32px', height:'32px'}}/>}
                icon={<img src={radioButtonInactive} style={{width:'32px', height:'32px'}}/>}
                value={font.fontFamily}
                name="font"
                style={{ marginRight: "10px" }}
              />
            </Box>
          ))}
        </Box>
  }
 
  handleCognitiveEsignModal = (activeTab : string) => {
    return (
      <Modal
            open={this.state.activeSignatureView}
            onClose={this.handleSignatureClose}
          >
            <Box style={webStyles.signatureContainer}>
              <Box sx={webStyles.signatureLeftContainer}>
                <Box style={webStyles.signatureHeader}>
                  <Box style={webStyles.signatureHeaderLeft}>
                    <Typography style={webStyles.signatureTitle}>Signature</Typography>
                    <Typography style={{...webStyles.signatureSubTitle,fontSize:"14px"}}>Your Information, Secured for Peace of Mind.</Typography>
                  </Box>
                  <Box style={webStyles.signatureHeaderRight}>
                    <img src={closeBtn} onClick={this.handleSignatureClose} style={{ width: "30px", height: "30px", cursor: "pointer" }} />
                  </Box>
                </Box>
                <Box style={webStyles.signatureNavigation}>
                  <Box data-test-id="tab1Button" onClick={() => this.handleTabChange('tab1')} style={{ ...webStyles.navigationTab, ...(activeTab === 'tab1' && webStyles.activeTab) }} >
                    Type it in
                  </Box>
                  <Box data-test-id="tab2Button" onClick={() => this.handleTabChange('tab2')} style={{ ...webStyles.navigationTab, ...(activeTab === 'tab2' && webStyles.activeTab) }}>
                    Draw it in
                  </Box>
                  <Box data-test-id="tab3Button" onClick={() => this.handleTabChange('tab3')} style={{ ...webStyles.navigationTab, ...(activeTab === 'tab3' && webStyles.activeTab) }}>
                    Upload Image
                  </Box>
                </Box>
                <Box style={{height:"calc(100vh - 320px)", overflow:"auto", marginTop:"20px", overflowX:"hidden"}}>
                  {activeTab === 'tab1' && (
                    <Box>
                      <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "24px" }}>
                        <Box style={{ position: "relative", width: "48%" }}>
                          <TextField
                            label="Signature Name"
                            variant="outlined"
                            fullWidth
                            data-test-id="signeturName"
                            style={{ position: 'relative' }}
                            name="signatureName"
                            value={this.state.signatureName}
                            id="signatureName"
                            type="text"
                            onChange={(event) => this.handleSignatureChange(event)}
                            inputProps={{
                              style: { borderRadius: "8px", width: "430px" },
                            }}
                          />
                        </Box>
 
                        <Box style={{ position: "relative", width: "48%" }}>
                          <TextField
                            label="Initials"
                            variant="outlined"
                            fullWidth
                            data-test-id="initials"
                            value={this.state.signInitials}
                            style={{ position: 'relative' }}
                            name="initials"
                            id="initials"
                            onChange={(event) => this.handleInitialsChange(event)}
                            inputProps={{
                              style: { borderRadius: "8px" },
                              maxLength: 12
                            }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        {this.handleCognitiveTypeItIn()}
                      </Box>
                    </Box>
                  )}
                  {activeTab === 'tab2' && (
                    <Box>
                      <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", marginTop: "24px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center",position:"relative" }}>
                        {this.state.taxInfoDetails.drawSignature !== "" ? (
                          <Button
                            data-test-id="clear-signature"
                            variant="text"
                            style={{
                              textTransform: "none",
                              position: "absolute",
                              top: 12,
                              right: 19,
                              border: "1px solid rgba(197, 203, 201, 1)",
                              padding: "6px 10px",
                              height: "32px",
                              width: "86px",
                              borderRadius: "8px"
                            }}
                            onClick={this.clearSignature}
                          >
                            Clear
                          </Button>
                        ) : null}
                      {this.state.taxInfoDetails.drawSignature.length > 0 ?
                        (<Box style={webStyles.imageBox} >
                          <img src={this.state.taxInfoDetails.drawSignature} alt="Signature"/>
                        </Box>) :
                        <SignatureCanvas
                          ref={this.drawSignCanvasRef || this.state.taxInfoDetails.drawSignature}
                          penColor="black"
                          onEnd={this.endDrawSignature}
                          canvasProps={{ width: 700, height: 250, className: 'drawSignCanvasRef', style: { border: "1px solid #C5CBC9", borderRadius: "8px", width: "99%" } }}
                        />}
 
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {activeTab === 'tab3' && (
                    <Box style={{ height: "auto", justifyContent: "center", alignItems: 'center', position: "relative", marginTop: "24px" }}>
                     {['Full Signature', 'Initial Signature'].map((label, index) => {
                      const isFullSignature = index === 0;
                      const dropZoneData = isFullSignature ? this.state.taxInfoDetails.fullSignatureUploadView : this.state.taxInfoDetails.initialSignatureUploadView;
                      const onDrop = isFullSignature ? this.onDropFullSignature : this.onDropInitialSignature;
                      const removeFile = isFullSignature ? this.removeFullSignatureFile : this.removeInitialSignatureFile;
                      const uploadSignature = isFullSignature ? this.state.taxInfoDetails.uploadFullSignature : this.state.taxInfoDetails.uploadIntitalSignture;
                      const isShowToolTipIcon = !isFullSignature

                      return (
                        <Box key={index} style={{ width: "100%", height: "196px", marginTop: index === 1 ? "24px" : "0", position: "relative" }}>
                          <Dropzone onDrop={onDrop} multiple={false} data-test-id={`dropZoneTestIdTab3` + index}
                            accept={{
                              'image/png': ['.png'],
                              'image/jpeg': ['.jpg', '.jpeg'],
                              'image/svg+xml': ['.svg']
                            }}
                          >
                            {({ getRootProps, getInputProps, isDragActive }) => (
                              <div
                                {...getRootProps()}
                                style={{
                                  height: "196px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                  color: "black",
                                  border: "1px solid #C5CBC9",
                                  borderRadius: "8px",
                                  backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
                                }}
                                data-test-id={`dropzone`+index}
                              >
                                <input {...getInputProps()} />
                                {dropZoneData ? (
                                  <Box style={{ height: "171px", width: "100%", display: "flex", flexDirection: 'column' }}>
                                    <Box style={{ width: "100%", height: "17.67px", marginTop: "10px" }}>
                                      <div data-test-id="removeSignatureDiv" style={{ textAlign: 'right' }}>
                                        <button data-test-id={`remove-signature` + index} onClick={removeFile} style={{ width: "20px", height: "20px", marginRight: "20px", border: "0px", backgroundColor: 'white' }}>
                                          <img src={closeBtn} style={{ width: "20px", height: "20px", color: "#C5CBC9" }} />
                                        </button>
                                      </div>
                                    </Box>
                                    <Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                      <img
                                        height="110px"
                                        width="172px"
                                        src={uploadSignature}
                                      />
                                      <Typography style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginTop: "10px" }}>{dropZoneData.name}</Typography>
                                    </Box>
                                  </Box>
                                ) : this.isDragActiveFunction(isDragActive,isShowToolTipIcon)}
                              </div>
                            )}
                          </Dropzone>
                          <Box style={{ position: "absolute", top: -9, left: 17, backgroundColor: "white", padding: "0px 4px" }}>
                            <Typography style={{ color: "#4B4C4B", fontWeight: 500, fontSize: "12px", lineHeight: "18px" }}>{label}</Typography>
                          </Box>
                        </Box>
                      );
                    })}
                    </Box>
                  )}
                  <FormControlLabel
                    data-test-id="formControlTestId"
                    control={
                      <Checkbox data-test-id="uploadInitialSignature" checked={this.state.taxInfoDetails.isSignCheck} color="primary" onChange={this.handleIsSigneturCheck} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                    }
                    label={
                      <Typography style={{ fontWeight: 500, color: "#4B4C4B", width: "100%", display: "flex", gap: "6px" }}>I agree to sign the <span style={{ color: "#0F172A", position: "relative", display: "block" }} >
                        <div style={{ ...webStyles.underLIne, position: "absolute" }}></div>
                        Electronic signature disclosure.
                      </span></Typography>
                    }
                    style={{ marginTop: "10px",marginLeft:"-9px" }}
                  />
 
                </Box>
                <Box sx={webStyles.nextStep} style={{
                  width: "100%",
                  position: "absolute",
                  left: 0,
                  bottom: "130",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
 
                  <Button style={{
                    width: "88%",
                    height: "56px",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    textTransform: "none",
                    borderRadius: "8px",
                    background: !this.handleDisableFinishButton() ? "#4FC8EC" : "#E8F8FD",
                    color: !this.handleDisableFinishButton() ? "#FFFFFF" : "#75D3F0",
                    border: "1px solid #C5CBC9",
                  }}
                    variant="contained"
                    data-test-id="esignFinishBtn"
                    id="esignFinishBtn"
                    onClick={this.saveSignature}
                    disabled={this.handleDisableFinishButton()}
                  >Finish</Button>
                </Box>
              </Box>
            </Box>
      </Modal>
    )
  }

  backToDashboardModal = ()=>{
    return(
      <Modal
      open={this.state.isOpenBackToDashboardModal}
      onClose={this.toggleDashboardModalNew}
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      style={{  display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   }}
    >
      <StyledModalBox >
        <Box display={'flex'} style={{width:'545px'}} justifyContent={'end'} >
          <Button onClick={this.toggleDashboardModalNew} data-test-id="closebtn"><CloseIcon/></Button>
        </Box>
  
       <Box style={{width:'520px', height:'126px'}}>
        <Box><Typography style={{color:'#0F172A', fontWeight:500, fontSize:'24px',marginBottom:'12px', fontFamily:'Roboto'}}>Exit without saving?</Typography></Box>
        <Typography style={{color:'#013D4F',fontSize:'18px',fontWeight:300, fontFamily:'Gotham light'}}>Are you sure you want to exit the dealership creation process and return to the dashboard? Your progress will be lost.</Typography>
       </Box>
  
       <Divider style={{ height:'2px', width:'113%', marginLeft:'-33px',backgroundColor:'#F0FAFA', marginTop:'30px'}}/>
       
       <Box style={{display:'flex', marginTop:'20px',justifyContent:'end', }}>
        <Button data-test-id="cancellogout" variant="outlined" onClick={this.toggleDashboardModalNew} style={{height:'56px', border:'1px solid #013D4F', width:'156px', marginRight:'15px', borderRadius:'8px'}}>
          <Typography style={{color:'#013D4F',fontSize:'16px', fontWeight:700, textTransform:'none',fontFamily:'Roboto'}}>
            Cancel
          </Typography>
        </Button>
        <Button data-test-id="finalDashboard" variant="contained" onClick={() => this.navigateToDashboard(false)} style={{ width:'230px',height:'56px', backgroundColor:'#4FC8EC', borderRadius:'8px'}}>
          <Typography style={{color:'white', textTransform:'none', fontSize:'16px', fontFamily:'Roboto', fontWeight:700, }}>
            Go to Dashboard
          </Typography>
        </Button>
       </Box>
      </StyledModalBox>
  </Modal>
    )
  }
 
  headerr = () => {
    return (
      <Box sx={webStylesHeader.header} data-test-id="headerTest">
        <Box sx={webStylesHeader.headerLeftSide}>
          <HamburgerMenuFull navigation={this.props.navigation} id={""} />
 
          <Box sx={webStylesHeader.logo}>
            <img style={{ marginBottom: '5px', height: '24px', width: '52px' }} src={logo} alt="vitu logo" />
          </Box>
        </Box>
 
        <Box sx={webStylesHeader.headerRightSide}>
          <Button className="logout" data-test-id="backToDashboardButton" style={{width:'66%', paddingLeft:"10px", paddingRight:"10px"}} variant="outlined" onClick={this.toggleDashboardModalNew}>
            Back to Dashboard
          </Button>
 
          <Box
            data-test-id="notifications"
            sx={webStylesHeader.notificationsButton}
          >
            <img src={notifications} alt="notifications button" />
          </Box>
 
          <ProfileDropdown navigation={this.props.navigation} id={""}/>
        </Box>
      </Box>
    )
  }
 
  step1 = () => {
    return (
      <Box className="dealerInfoForm">
        <Box className="headerForm">
          <Box className="headingForm">
            <Typography className="titleForm gothamText">Dealership Info</Typography>
            <Typography className="subtitleForm gothamText">
            Tell us some information about your company
            </Typography>
          </Box>
          <Button className="skip" style={{marginTop:"10px"}} data-test-id="skip" variant="outlined" onClick={this.handleSkip}>
            Skip
          </Button>
        </Box>
        <Box className="form" component="form">
          <MuiThemeProvider theme={tooltipTheme}>
            {((!this.state.isFirstDealership && !this.state.isDealerGroup && this.state.singleDealershipInfo!== undefined) || this.state.isFirstDealership || this.state.createDealershipInSoloMode) &&
              <Box style={{ position: "relative" }}>
                <Box className="DealerGroupBox">
                  <Typography className="dealerGroupText">Dealer Group</Typography>
                  <TextField
                    label="Are you a Dealer Group?"
                    variant="outlined"
                    data-test-id="texttest"
                    fullWidth
                    style={{ position: 'relative' }}
                    InputLabelProps={{
                      style: { color: '#4B4C4B', fontWeight: 500 }
                    }}
                    disabled={true}
                    InputProps={{
                      style: { color: "transparent", borderRadius: "8px" },
                      startAdornment: (
                        <InputAdornment position="start" style={{ cursor: "pointer" }}>
                          <RadioGroup
                            aria-label="dealer-group"
                            name="dealerGroup"
                            data-test-id="dealerGroup"
                            className="radioClass"
                            value={this.state.dealerDetails.dealerGroup.toString()}
                            onChange={this.handleRadioChange}
                            row={true}
                            style={{ color: "black" }}
                          >
                            <FormControlLabel
                              value="false"
                              data-test-id="radiotest"
                              disabled={this.state.mandatoryDealerGroupCondition}
                              control={
                                <Radio
                                  disableRipple
                                  color="default"
                                  checkedIcon={<this.BpCheckedIcon className="radioStyle" />}
                                  icon={<this.BpIcon className="radioStyle" />}
                                  {...this.props}
                                />} label="No" />
                            <FormControlLabel
                              value="true"
                              disabled={this.state.mandatoryDealerGroupCondition}
                              control={<Radio
                                disableRipple
                                color="default"
                                checkedIcon={<this.BpCheckedIcon className="radioStyle" />}
                                icon={<this.BpIcon className="radioStyle" />}
                                {...this.props}
                              />} label="Yes" />
                          </RadioGroup>
 
                        </InputAdornment>
                      ),
                    }}
                    name="dealer group"
                    id="dealership_group"
                  />
                  <Tooltip title={<><p className="heading">Are you a Dealer Group?</p><p className="text">Are you an independent dealership or are you part of a dealer group with multiple stores?</p></>} className="infoTextFirst" arrow>
                    <IconButton aria-label="info">
                      <img src={infoIcon} alt="info" />
                    </IconButton>
                  </Tooltip>
                  {this.state.dealerDetails.dealerGroup && (
                    <TextField data-test-id="dealerGroupName" className="dealerGroupName" name="dealership_group_name" style={{ marginTop: "20px" }} id="name" label="Dealer Group Name" variant="outlined" fullWidth onChange={this.handleDealerGroupNameChange} value={this.state.dealerDetails.dealerGroupName}
                      inputProps={{
                        style: { borderRadius: "8px" }
                      }}
                      error={this.returnDealershipGroupNameErrors()}
                      helperText={this.returnDealershipGroupNameHelperText()}
                      onBlur={this.setDealershipGroupNameTouched}
                    />
                  )}
                </Box>
 
 
              </Box>
            }
 
            <Box className="DealerShipDetailsBox" style={{marginBottom:"20px"}}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="dealerGroupText">Dealership Details</Typography>
                <Box style={{ width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={this.toggleExpand} data-test-id="accordion">
                  <img src={arrowTop} style={{ width: "32px", height: "32px", transform: this.state.isExpanded ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.5s ease-in-out' }} />
                </Box>
              </Box>
              <Box className="collapsibleContent" style={{ maxHeight: this.state.isExpanded ? "2000px" : "0px", overflow: "hidden", transition: 'max-height 0.5s ease-in-out' }}>
              <Grid style={{ display: 'flex', flexDirection: 'row', marginBottom: "20px" }}>
                <Grid item>
                  <Box style={{ height: '98px', width: '98px', borderRadius: '24px', textAlign: 'center', backgroundImage: `url(${emptyProfile})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.dealerDetails.profilePhoto ?
                      <Avatar className="avatar" variant="square" src={this.state.dealerDetails.profilePhoto} style={{ width: '100%', height: '100%', borderRadius: '24px', backgroundColor: 'rgba(240,250,250)' }} />
                      :
                      (
                        <>
                          {this.state.dealerDetails.initials ?
                            (
                              <Avatar
                                variant="square"
                                style={{ width: '90px', height: '90px', backgroundColor: 'rgba(240,250,250)', borderRadius: '24px', color: '#013D4F', fontSize: '32px', fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              >
                                {this.state.dealerDetails.initials}
                              </Avatar>
                            ) :
                            null
                          }
                        </>
                      )
                    }
                  </Box>
                </Grid>
                <Grid item style={{ marginLeft: '20px', width: '400px' }}>
                  <Box>
                    {this.state.dealerDetails.profilePhoto ?
                      (<Box>
                        <Button component="label" variant="outlined" style={{ color: '#013D4F', marginTop: '10px', border: '1px solid #013D4F', borderRadius: '8px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>{configJSON.reupload}</Typography>
                          <input
                            type="file"
                            hidden
                            onChange={(event) => { this.handlePhotoChange(event) }}
                            accept=".jpg,.jpeg,.png"
                          />
                        </Button>
 
                        <Button data-test-id="delete-picture-button" onClick={this.handleDeletePicture} variant="outlined" style={{ color: '#C43937', border: '1px solid #C43937', borderRadius: '8px', marginTop: '10px', marginLeft: '10px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>{configJSON.removephoto}</Typography></Button>
                      </Box>)
                      : (
                        <Box>
                          <Button data-test-id="photoChangeTestId" component="label" variant="outlined" style={{ color: '#013D4F', border: '1px solid #013D4F', borderRadius: '8px', marginTop: '10px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>{configJSON.uploadphoto}</Typography>
                            <input
                              type="file"
                              hidden
                              onChange={(event) => this.handlePhotoChange(event)}
                              accept=".jpg,.jpeg,.png"
                            />
                          </Button>
                        </Box>)
                    }
                    {this.errorBox()}
                  </Box>
                  <DetailBox >
                    <Typography style={{ fontSize: '12px', color: '#C5CBC9', marginTop: '12px', fontWeight: 400 }} color="secondary">{configJSON.photodetail}</Typography>
                  </DetailBox>
                </Grid>
              </Grid>
 
              <TextField name="dealership_name" className="dealership_name" data-test-id="dealership_name" id="name" label="Dealership Name" variant="outlined" fullWidth onChange={this.handleNameChange} value={this.state.dealerDetails.dealership_name}
                inputProps={{
                  style: { borderRadius: "8px" },
                  maxLength: 50
                }}
                error={this.returnDealershipNameErrors()}
                helperText={this.returnDealershipNameHelperText()}
                onBlur={this.setDealershipNameTouched}
              />
              <Box style={{ marginTop: "20px" }}>
                <Typography className="dealerGroupText">Dealership Address</Typography>
                <Box style={{ position: "relative", marginTop: "20px", height: "78px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                  {this.renderAutoCompleteAddress() }
                  <Tooltip title={<><p className="heading">Search Dealership Address</p><p className="text">Your dealership physical location</p></>} className="infoText" arrow>
                    <IconButton aria-label="info">
                      <img src={infoIcon} alt="info" />
                    </IconButton>
                  </Tooltip>
                </Box>
                  <TextField
                    name="street_address"
                    id="street_address"
                    label="Street Address"
                    data-test-id="dealership_street_address"
                    variant="outlined"
                    fullWidth onChange={this.handleStreetAddressChange}
                    value={this.state.dealerDetails.dealership_street_address}
                    error={this.returnStreetAddressErrors()}
                    helperText={this.returnStreetAddressHelperText()}
                    onBlur={this.setStreetAddressTouched}
                  />
                  <Box style={{ display: "flex", marginTop: "20px", flexDirection: "column", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        style={{ width: "48%" }}
                        error={this.returnStateErrors()} 
                      >
                        <InputLabel id="state-label">State</InputLabel>
                        <Select
                          labelId="state-label"
                          id="state"
                          data-test-id="dealership_state"
                          name="state"
                          value={this.state.dealerDetails.dealership_state}
                          onChange={(event) => this.handleStateChange(event)}
                          label="State"
                          IconComponent={(props) => <ExpandMoreRoundedIcon data-test-id="iconExpandId" {...props} style={{ marginRight: '16px', color:"#C5CBC9" }} />}
                          onBlur={this.setStateTouched}  
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                              style: {
                                maxHeight: 48 * 6.5,
                                width: 250,
                              },
                            },
                          }}
                        >
                          {this.state.dealerDetails.test.map((state) => (
                            <MenuItem key={state.value} value={state.value}>
                              {state.displayValue}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{this.returnStateHelperText()}</FormHelperText>
                      </FormControl>
 
                    <FormControl variant="outlined" fullWidth style={{ width: "48%" }} error={this.returnCityErrors()} >
                      <InputLabel id="city-label">City</InputLabel>
                      <Select
                        labelId="city-label"
                        id="city"
                        data-test-id="dealership_city"
                        name="city"
                        value={this.state.dealerDetails.dealership_city}
                        onChange={(event) => this.handleCityChange(event)}
                        label="City"
                        IconComponent={(props) => <ExpandMoreRoundedIcon data-test-id="iconExpandIdCity" {...props} style={{ marginRight: '16px', color:"#C5CBC9" }} />}
                        onBlur={this.setCityTouched} 
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 6.5,
                              width: 250,
                            },
                          },
                        }}
                      >
                        {this.state.dealerDetails.testCities.length > 0 ? (
                          this.state.dealerDetails.testCities.map((city: CityType) => (
                            <MenuItem key={city.value} value={city.value}>
                              {city.displayValue}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            No cities available
                          </MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{this.returnCityHelperText()}</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                    <TextField
                      name="zipCode"
                      id="zipCode"
                      label="Zip Code"
                      data-test-id="dealership_zipCode"
                      variant="outlined"
                      fullWidth onChange={this.handleZipCodeChange}
                      value={this.state.dealerDetails.dealership_zipCode}
                      style={{ width: "48%" }}
                      InputProps={{
                        style: { borderRadius: "8px" }
                      }}
                      inputProps={{
                        maxLength: 5
                      }}
                      error={this.returnZipCodeErrors()}
                      helperText={this.returnZipCodeHelperText()}
                      onBlur={this.setZipCodeTouched}
                    />
                    <TextField
                      name="ZipCodeEXT"
                      id="ZipCodeEXT"
                      data-test-id="dealership_zipCodeEXT"
                      label="Zip Code EXT (optional)"
                      variant="outlined"
                      fullWidth onChange={this.handleZipCodeEXTChange}
                      value={this.state.dealerDetails.dealership_zipCodeEXT}
                      style={{ width: "48%" }}
                      InputProps={{
                        style: { borderRadius: "8px" }
                      }}
                      inputProps={{
                        maxLength: 4
                      }}
                      error={this.returnZipCodeEXTErrors()}
                      helperText={this.returnZipCodeEXTHelperText() ? 'Must be 4 digits' : ''}
                    />
                  </Box>
                </Box>
              </Box>
 
              <Box style={{ marginTop: "20px" }}>
                <Typography className="dealerGroupText">Dealer Shipping Address</Typography>
                <Box style={{ position: "relative", marginTop: "20px", height: "78px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                  {this.renderAutocompleteAddressShipping() }
                  <Tooltip title={<><p className="heading">Search Dealer Shipping Address</p><p className="text">Dealership shipping address where you want to receive paperwork and indicia</p></>} className="infoText" arrow>
                    <IconButton aria-label="info">
                      <img src={infoIcon} alt="info" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <TextField
                  name="street_address"
                  id="street_address"
                  disabled={this.state.isSameAddress}
                  label="Street Address"
                  data-test-id="dealership_shipping_street_address"
                  variant="outlined"
                  fullWidth onChange={this.handleDealershipShippingStreetAddressChange}
                  value={this.state.dealerDetails.dealership_shipping_street_address}
                  inputProps={{
                    style: { borderRadius: "8px" }
                  }}
                  error={this.returnShippingStreetAddressErrors()}
                  helperText={this.returnShippingStreetAddressHelperText()}
                  onBlur={this.setShippingStreetAddressTouched}
                />
                <Box style={{ display: "flex", marginTop: "20px", flexDirection: "column", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <FormControl variant="outlined" fullWidth style={{ width: "48%" }} error={this.returnShippingStateErrors()} >
                      <InputLabel id="state-label">State</InputLabel>
                      <Select
                        labelId="state-label"
                        id="state"
                        disabled={this.state.isSameAddress}
                        name="state"
                        data-test-id="dealership_shipping_state"
                        value={this.state.dealerDetails.dealership_shipping_state}
                        onChange={(event) => this.handleDealershipShippingStateChange(event)}
                        label="State"
                        IconComponent={(props) => <ExpandMoreRoundedIcon data-test-id="iconExpandShippingStateId" {...props} style={{ marginRight: '16px', color:"#C5CBC9" }} />}
                        onBlur={this.setShippingStateTouched} 
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 6.5,
                              width: 250,
                            },
                          },
                        }}
                      >
                        {this.state.dealerDetails.test.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.displayValue}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{this.returnShippingStateHelperText()}</FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth style={{ width: "48%" }} error={this.returnShippingCityErrors()}>
                      <InputLabel id="city-label">City</InputLabel>
                      <Select
                        labelId="city-label"
                        id="city"
                        disabled={this.state.isSameAddress}
                        data-test-id="dealership_shipping_city"
                        name="city"
                        value={this.state.dealerDetails.dealership_shipping_city}
                        onChange={(event) => this.handleDealershipShippingCityChange(event)}
                        label="City"
                        IconComponent={(props) => <ExpandMoreRoundedIcon data-test-id="iconExpandShippingCityId" {...props} style={{ marginRight: '16px', color:"#C5CBC9" }} />}
                        onBlur={this.setShippingCityTouched}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 6.5,
                              width: 250,
                            },
                          },
                        }}
                      >
                        {this.state.dealerDetails.testShippingCities.length > 0 ? (
                          this.state.dealerDetails.testShippingCities.map((city: CityType) => (
                            <MenuItem key={city.value} value={city.value}>
                              {city.displayValue}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            No cities available
                          </MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{this.returnShippingCityHelperText()}</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                    <TextField
                      name="zipCode"
                      id="zipCode"
                      label="Zip Code"
                      data-test-id="dealership_shipping_zipCode"
                      disabled={this.state.isSameAddress}
                      variant="outlined"
                      fullWidth onChange={this.handleDealershipShippingZipCodeChange}
                      value={this.state.dealerDetails.dealership_shipping_zipCode}
                      error={this.returnShippingZipCodeErrors()}
                      helperText={this.returnShippingZipCodeHelperText()}
                      onBlur={this.setShippingZipCodeTouched}
                      style={{ width: "48%" }}
                      inputProps={{
                        style: { borderRadius: "8px" },
                        maxLength: 5
                      }}
                    />
                    <TextField
                      name="ZipCodeEXTShipping"
                      id="ZipCodeEXTShipping"
                      label="Zip Code EXT (optional)"
                      disabled={this.state.isSameAddress}
                      variant="outlined"
                      error={this.returnShippingZipCodeEXTErrors()}
                      helperText={this.returnShippingZipCodeEXTHelperText() ? 'Must be 4 digits' : ''}
                      data-test-id="dealership_shipping_zipCodeEXT"
                      fullWidth onChange={this.handleDealershipShippingZipCodeEXTChange}
                      value={this.state.dealerDetails.dealership_shipping_zipCodeEXT}
                      style={{ width: "48%" }}
                      inputProps={{
                        style: { borderRadius: "8px" },
                        maxLength: 4
                      }}
                    />
                  </Box>
                </Box>
 
                <FormControlLabel
                  style={{ marginTop: "8px", fontWeight: 400, fontSize: "14px" }}
                  control={
                    <Checkbox checked={this.state.isSameAddress} color="primary" onChange={this.handleIsSameAddress} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                  }
                  name="isSameAddress"
                  data-test-id="isSameAddress"
                  className="same gothamText"
                  label={<Typography style={{ fontWeight: 500, fontSize: "14px", color:"#4B4C4B" }}>Same as Dealership Address</Typography>}
                />
 
                <Box style={{ position: "relative", marginTop: "20px" }}>
                  <TextField
                    label="Business Credentials (e.g. License Number)"
                    variant="outlined"
                    fullWidth
                    data-test-id="business_credentials"
                    style={{ position: 'relative' }}
                    error={this.returnBusinessCredentialsErrors()}
                    helperText={this.returnBusinessCredentialsHelperText()}
                    onBlur={this.setBusinessCredentialsTouched}
                    value={this.state.dealerDetails.business_credentials}
                    name="business_credentials"
                    id="business_credentials"
                    onChange={this.handleBusinessChange}
                    inputProps={{
                      style: { borderRadius: "8px" },
                      maxLength: 25
                    }}
                  />
                  <Tooltip title={<><p className="heading">Dealership Credentials</p><p className="text">Dealership License, official authorization issued by the governing authority, permitting a dealership to legally sell vehicles.</p></>} className="infoText" arrow>
                    <IconButton aria-label="info">
                      <img src={infoIcon} alt="info" id="mapIcon" />
                    </IconButton>
                  </Tooltip>
                </Box>
 
                <Box style={{ position: "relative" }}>
                    <TextField
                      label="What type of deals do you do?"
                      variant="outlined"
                      data-test-id="textcar"
                      className="textcar"
                      fullWidth
                      disabled={true}
                      style={{ position: 'relative', marginTop: "24px", color:'white' }}
                      InputLabelProps={{
                        style : { color:'#4B4C4B', fontWeight:500 }
                      }}
                      InputProps={{
                        style: { borderRadius: "8px" },
                        startAdornment: (
                          <InputAdornment position="start" style={{ cursor: "pointer", marginLeft: "8px" }}>
                            <FormControlLabel
                              value="New Cars"
                              style={{ color: "#4B4C4B" }}
                              className="testt"
                              control={
                                <Checkbox
                                  checked={this.state.dealerDetails.checkedBoxes.includes("New Cars")}
                                  className="newCar"
                                  color="primary"
                                  onChange={this.handleCheckboxChangeNew}
                                  icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
                                  checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
                                />
                              }
                              label={<Typography style={{ fontWeight: 500, fontSize: "16px" }} >New Cars</Typography>}
                            />
                            <FormControlLabel
                              value="Used Cars"
                              className="usedtestt"
                              style={{ color: "#4B4C4B", marginLeft: "6px" }}
                              control={
                                <Checkbox
                                  checked={this.state.dealerDetails.checkedBoxes.includes("Used Cars")}
                                  className="usedCars"
                                  color="primary"
                                  onChange={this.handleCheckboxChangeUsed}
                                  icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
                                  checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
                                />
                              }
                              label={<Typography style={{ fontWeight: 500, fontSize: "16px" }} >Used Cars</Typography>}
                            />
                          </InputAdornment>
                        ),
                      }}
                      name="dealership_address"
                      id="dealership_address"
                    />
                  <Tooltip title={<><p className="heading">What type of deals do you do?</p><p className="text">Type of transactions you process at your dealership</p></>} className="infoTextLast" arrow>
                    <IconButton aria-label="info">
                      <img src={infoIcon} alt="info" />
                    </IconButton>
                  </Tooltip>
                  {this.errorCheckBox()}
                </Box>
              </Box>
              </Box>
            </Box>
 
          </MuiThemeProvider>
        
        </Box>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography style={{ fontSize: "14px", color: "#C43937"}}>{this.state.errorsDealerBackend}</Typography>
        </div>
      </Box>
    );
  };
 
  step2 = () => {
    return (
      <Box className="dealerInfoForm">
        <Box className="headerForm">
          <Box className="headingForm">
            <Typography className="titleForm gothamText" data-test-id="contactInfoTestId">Contact Information</Typography>
            <Typography className="subtitleForm gothamText">
              Let us know the best way to contact you
            </Typography>
          </Box>
          <Button className="skip" data-test-id="skipSecond" variant="outlined" style={{marginTop:"10px"}} onClick={this.handleSkip}>
            Skip
          </Button>
        </Box>
        
        <Formik
                initialValues={{ fullName:this.state.contactInfoDetails.fullName, phoneNumber:this.state.contactInfoDetails.phoneNumber, email: this.state.contactInfoDetails.email}}
                validationSchema={this.validationSchema}
                validate={values=>this.handleFormChange(values)}
                onSubmit={() => this.nextStep()}  
                validateOnMount={true}
                key={1}           
              >
                 {({ errors, touched, handleChange, handleBlur, values, isValid, dirty }) => {
    if ((isValid) !== this.state.isSecondFormFilled) {
      this.handleSecondFormFilled(isValid);
    }
 
    return (
      <Form onChange={() => this.handleFormChange(values)}
      className="formi">
          <MuiThemeProvider theme={tooltipTheme}>
            <Box style={{ position: "relative" }}>
              <Box className="DealerGroupBox">
              <Box style={{ position: "relative", marginTop:"20px" }}>
              <TextField
                label="Full Name"
                          variant="outlined"
                          fullWidth
                data-test-id="fullName"
                          style={{ position: 'relative' }}
                value={values.fullName}
                name="fullName"
                id="fullName"
                error={touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                          onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                          inputProps={{
                  style: {borderRadius:"8px"},
                  maxLength: 100
                }}
                onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                  {
                    this.handleFocusCognitive(event)
                }}}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {if (this.handleBlurr(event)) {this.cccc(event.target.labels, "0px")}
                handleBlur(event);
                }}
                InputLabelProps={{
                  style: { color: "rgba(75, 76, 75, 1)", fontWeight:500, paddingLeft:"6px" },
                          }}
                        />
              <Tooltip title={<><p className="heading">Full Name</p><p className="text">Your full name</p></>} className="infoText" arrow>
                <IconButton aria-label="info">
                  <img src={infoIcon} alt="info" id="mapIcon" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box style={{ position: "relative", marginTop:"20px" }}>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                data-test-id="phoneNumber"
                style={{ position: 'relative' }}
                value={values.phoneNumber}
                name="phoneNumber"
                id="phoneNumber"
                error={this.errorPhoneNumberContactInfo(touched, errors)}
                helperText={this.helperTextPhoneNumberContactInfo(touched, errors)}
                onChange={(event) => this.handleRegex(event, handleChange)}
                inputProps={{
                  maxLength:10
                }}
                onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                  {this.handleFocusCognitive(event)}
                }}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  if (this.handleBlurr(event)) {
                    this.cccc(event.target.labels, "0px")
                  }
                  handleBlur(event);
                }}
                InputLabelProps={{
                  style: { color: "rgba(75, 76, 75, 1)", fontWeight:500 },
                }}
                InputProps={{
                  style: {borderRadius:"8px"},
                  startAdornment: (
                    <InputAdornment position="end" style={{cursor: "pointer" ,display:"flex", alignItems:"center"}}>
                    <img src={flagUsa} style={{height:"14px", width:"18px"}}/>
                    <p style={{marginLeft:"8px", fontSize:"16px"}}>+</p>
                    <p style={{fontSize:"16px", marginTop:"18px"}}>1</p>
                    <p style={{marginLeft:"12px", marginRight:"10px", fontSize:"16px"}}>|</p>
                    </InputAdornment>
                  )
                }}
              />
              <Tooltip title={<><p className="heading">Phone Number</p><p className="text">Best phone number to reach you</p></>} className="infoText" arrow>
                <IconButton aria-label="info">
                  <img src={infoIcon} alt="info" id="mapIcon" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box style={{ position: "relative", marginTop:"20px" }}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                data-test-id="email"
                style={{ position: 'relative' }}
                value={values.email}
                name="email"
                id="email"
                error={this.errorEmailContactInfo(touched, errors)}
                helperText={this.helperTextEmailContactInfo(touched, errors)}
                onChange={handleChange}
                inputProps={{
                  style: {borderRadius:"8px"}
                }}
                InputLabelProps={{
                  style: { color: "rgba(75, 76, 75, 1)", fontWeight:500, paddingLeft:"6px" },
                }}
                onFocus={(event: React.FocusEvent<HTMLInputElement>) => {{this.handleFocusCognitive(event)}
                }
                }
                onBlur={
                  (event: React.FocusEvent<HTMLInputElement>) => {
                  if (this.handleBlurr(event)) 
                  {
                    this.cccc(event.target.labels, "0px")
                  }
                  handleBlur(event);
                }
              }
              />
              <Tooltip title={<><p className="heading">Email</p><p className="text">Best email contact</p></>} className="infoText" arrow>
                <IconButton aria-label="info">
                  <img src={infoIcon} alt="info" id="mapIcon" />
                </IconButton>
              </Tooltip>
            </Box>
            </Box>
            </Box>
 
 
          </MuiThemeProvider>
                  </Form>
                );
              }}
            </Formik>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
            <Typography style={{color:"#C43937", fontSize:"14px"}}>{this.state.errorsContactBackend}</Typography>
            </div>
      </Box>
    );
  };
 
step3 = () => {
    return (
      <Box className="dealerInfoForm">
        <Box className="headerForm">
          <Box className="headingForm">
            <Box style={{width:"85%"}}>
            <Typography className="titleForm gothamText">Electronic Fund Transfer Authorization Agreement for Preauthorized Payments</Typography>
            </Box>
            <Typography className="subtitleForm">
            Provide account details for secure payment processing
            </Typography>
          </Box>
          <Button className="skip" style={{marginTop:"40px", width: "98px", height: "44px",}} data-test-id="skipThird" variant="outlined" onClick={this.handleSkip}>
            Skip
          </Button>
        </Box>
 
        <Formik
          initialValues={{
            bankAccountNumber: this.state.thirdFormDetails.bankAccountNumber,
            confBankAccountNumber: this.state.thirdFormDetails.confBankAccountNumber, 
            routingNumber: this.state.thirdFormDetails.routingNumber, 
            confRoutingNumber: this.state.thirdFormDetails.confRoutingNumber, 
            bankName: this.state.thirdFormDetails.bankName, 
            branch: this.state.thirdFormDetails.branch, 
            name: this.state.contactInfoDetails?.fullName || this.state.thirdFormDetails.name, 
            phoneNumber: this.state.contactInfoDetails?.phoneNumber || this.state.thirdFormDetails.phoneNumber, 
            jobTitle: this.state.thirdFormDetails.jobTitle
          }}
          validationSchema={this.validationSchemaThirdForm}
          validate={values=>this.handleThirdFormChange(values)}
          onSubmit={() => this.nextStep()}
          validateOnMount={true}
          key={2}
        >
          {({ errors, touched, handleChange, handleBlur, values, isValid}) => {
            if ((isValid) !== this.state.isThirdFormValid) {
              this.handleThirdFormFilled(isValid);
            }
            return (
              <Form 
              onChange={() => this.handleThirdFormChange(values)}
              className="formiBank"
              >
                <MuiThemeProvider theme={tooltipTheme}>
                  <Box style={{ position: "relative" }}>
                    <Box className="DealerGroupBox" style={{marginBottom:"20px"}}>
                    <Box style={{ display: "flex", justifyContent: "space-between", height:"32px" }}>
                      <Typography className="dealerGroupText" style={{marginTop:"15px"}}>Bank Details</Typography>
                      <Box onClick={this.toggleExpandBank} style={{ width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }} data-test-id="accordionBankDetails">
                        <img src={arrowTop} style={{ transform: this.state.isExpandedBank ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.5s ease-in-out', width: "32px", height: "32px", }}/>
                      </Box>
                    </Box>
                      <Box className="collapsibleContent" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", maxHeight: this.state.isExpandedBank ? "2000px" : "0px", overflow: "hidden", transition: 'max-height 0.5s ease-in-out'}}>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <Box style={{ position: "relative", width: "48%", marginTop:"20px" }}>
                            <TextField
                              label="Bank Account Number"
                              variant="outlined"
                              fullWidth
                              data-test-id="bankAccountNumber"
                              style={{ position: 'relative' }}
                              value={values.bankAccountNumber}
                              name="bankAccountNumber"
                              id="bankAccountNumber"
                              inputMode= 'numeric'
                              onBlur={handleBlur}
                              error={this.returnThirdFormBankAccountNumberErrors(touched, errors)}
                              helperText={this.returnThirdFormBankAccountNumberHelperText(touched, errors)}
                              type="tel"
                              onChange={(event) => this.handleRegex(event, handleChange)}
                              inputProps={{
                                style: { borderRadius: "8px", width:"430px" },
                                inputMode: 'numeric', 
                                pattern: "[0-9]",
                                maxLength: 12 
                              }}
                            />
                            <Tooltip title={<><p className="heading">Bank Account Number</p><p className="text">Bank Account Number is a unique identifier assigned by your bank to your account. This number is used for transactions, such as payments and deposits.</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>
 
                          <Box style={{ position: "relative", width: "48%", marginTop:"20px" }}>
                            <TextField
                              label="Confirm Bank Account Number"
                              variant="outlined"
                              fullWidth
                              data-test-id="confBankAccountNumber"
                              style={{ position: 'relative' }}
                              value={values.confBankAccountNumber}
                              name="confBankAccountNumber"
                              id="confBankAccountNumber"
                              onBlur={handleBlur}
                              error={this.returnThirdFormConfBankAccountNumberErrors(touched, errors)}
                              helperText={this.returnThirdFormConfBankAccountNumberHelperText(touched, errors)}
                              onChange={(event) => this.handleRegex(event, handleChange)}
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength:12 
                              }}
                            />
                            <Tooltip title={<><p className="heading">Confirm Bank Account Number</p><p className="text">Confirm the bank account number you provided to ensure it is correct</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop:"20px" }}>
                          <Box style={{ position: "relative", width: "48%" }}>
                            <TextField
                              label="Routing Number"
                              variant="outlined"
                              fullWidth
                              data-test-id="routingNumber"
                              style={{ position: 'relative' }}
                              value={values.routingNumber}
                              name="routingNumber"
                              id="routingNumber"
                              onBlur={handleBlur}
                              error={this.returnThirdFormRoutingNumberErrors(touched, errors)}
                              helperText={this.returnThirdFormRoutingNumberHelperText(touched, errors)}
                              onChange={(event) => this.handleRegex(event, handleChange)}
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength: 9
                              }}
                            />
                            <Tooltip title={<><p className="heading">Routing Number</p><p className="text">Routing Number is a nine-digit code used to identify the financial institution in a transaction.</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>
 
                          <Box style={{ position: "relative", width: "48%" }}>
                            <TextField
                              label="Confirm Routing Number"
                              variant="outlined"
                              fullWidth
                              data-test-id="confRoutingNumber"
                              style={{position: 'relative'}}
                              value={values.confRoutingNumber}
                              name="confRoutingNumber"
                              id="confRoutingNumber"
                              onBlur={handleBlur}
                              error={this.returnThirdFormConfRoutingErrors(touched, errors)}
                              helperText={this.returnThirdFormConfRoutingHelpertext(touched, errors)}
                              onChange={(event) => this.handleRegex(event, handleChange)}                              
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength: 9
                              }}
                            />
                            <Tooltip title={<><p className="heading">Confirm Routing Number</p><p className="text">Confirm the routing number you provided to ensure it is correct</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop:"20px" }}>
                          <Box style={{ position: "relative", width: "48%" }}>
                            <TextField
                              label="Bank Name"
                              variant="outlined"
                              fullWidth
                              data-test-id="bankName"
                              style={{ position: 'relative' }}
                              value={values.bankName}
                              name="bankName"
                              id="bankName"
                              onBlur={handleBlur}
                              error={this.returnThirdFormBankNameErrors(touched, errors)}
                              helperText={this.returnThirdFormBankNameHelperText(touched, errors)}
                              onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength: 100
                              }}
                            />
                            <Tooltip title={<><p className="heading">Bank Name</p><p className="text">Official name of the financial institution where your account is held</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>
 
                          <Box style={{ position: "relative", width: "48%" }}>
                            <TextField
                              label="Branch"
                              variant="outlined"
                              fullWidth
                              data-test-id="branch"
                              style={{ position: 'relative' }}
                              value={values.branch}
                              name="branch"
                              id="branch"
                              error={this.returnThirdFormBranchErrors(values.branch, touched.branch)}
                              helperText={this.returnThirdFormBranchHelperText(values.branch, touched.branch)}
                              onBlur={handleBlur}
                              onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength: 100
                              }}
                            />
                            <Tooltip title={<><p className="heading">Branch</p><p className="text">The Branch refers to the specific location or office of your bank where your account is maintained. This information helps in identifying the exact branch for transaction purposes.</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  
                  </Box>
 
                  <Box className="DealerShipDetailsBox">
                    <Box style={{ display: "flex", justifyContent: "space-between", height:"32px"}}>
                      <Typography className="dealerGroupText" style={{marginTop:"15px"}}>Bank Address</Typography>
                      <Box style={{ width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={this.toggleExpandBankAddress} data-test-id="accordionBankAddress">
                        <img src={arrowTop} style={{ transform: this.state.isExpandedBankAddress ? 'rotate(0deg)' : 'rotate(180deg)', width: "32px", height: "32px", transition: 'transform 0.5s ease-in-out'}} />
                      </Box>
                    </Box>
 
                    <Box className="collapsibleContent" style={{ transition: 'max-height 0.5s ease-in-out', maxHeight: this.state.isExpandedBankAddress ? "1000px" : "0px", overflow:"hidden"}}>
                      <Box style={{paddingTop:"0px", overflow: "hidden", position: "relative",}}>
                        <Box style={{ marginTop: "8px", paddingTop:"16px", height: "78px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                          {this.renderAutocompleteAddressBank()}
                          <Tooltip title={<><p className="heading">Search Bank Address</p><p className="text">The Bank Address is the physical location of the bank branch where your account is held.</p></>} className="infoTextBankAddress" arrow>
                            <IconButton aria-label="info">
                              <img src={infoIcon} alt="info" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <TextField
                        label="Street Address"
                        variant="outlined"
                        fullWidth
                        data-test-id="streetAddressThird"
                        style={{ position: 'relative' }}
                        value={this.state.thirdFormAddressDetails.streetAddress}
                        name="streetAddress"
                        id="streetAddress"
                        onChange={this.handleBankStreetAddressChange}
                        inputProps={{
                          style: { borderRadius: "8px" }
                        }}
                        error={this.returnThirdFormStreetAddressErrors()}
                        helperText={this.returnThirdFormStreetAddressHelperText()}
                        onBlur={this.setThirdFormStreetAddressTouched}
                      />
                      <Box style={{ display: "flex", marginTop: "20px", flexDirection: "column", justifyContent: "space-between" }}>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <FormControl variant="outlined" fullWidth style={{ width: "48%" }} error={this.returnThirdFormStateErrors()}>
                            <InputLabel id="state-label" style={{paddingLeft:"6px"}}>State</InputLabel>
                            <Select
                              labelId="thirdFormState"
                              id="thirdFormState"
                              label="State"
                              onChange={this.handleThirdFormStateChange}
                              data-test-id="thirdFormState"
                              name="thirdFormState"
                              value={this.state.thirdFormDetailsNew.thirdFormState}
                              IconComponent={(props) => <ExpandMoreRoundedIcon data-test-id="iconExpandthirdFormStateId" {...props} style={{ marginRight: '16px', color:"#C5CBC9" }} />}
                              onBlur={this.setThirdFormStateTouched}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                  style: {
                                    maxHeight: 48 * 6.5,
                                    width: 250,
                                  },
                                },
                              }}
                            >
                              {this.state.dealerDetails.test.map((state) => (
                                <MenuItem 
                                  key={state.value} 
                                  value={state.value}>
                                  {state.displayValue}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>{this.returnThirdFormStateHelperText()}</FormHelperText>
                          </FormControl>
                          <FormControl 
                            variant="outlined" 
                            fullWidth style={{ width: "48%" }}
                            error={this.returnThirdFormCityErrors()}
                            >
                            <InputLabel id="city-label" style={{paddingLeft:"6px"}}>City</InputLabel>
                            <Select
                              labelId="city-label"
                              data-test-id="dealership_city"
                              id="city"
                              value={this.state.thirdFormDetailsNew.thirdFormCity}
                              name="city"
                              onChange={this.handleThirdFormCityChange}
                              label="City"
                              IconComponent={(props) => <ExpandMoreRoundedIcon data-test-id="iconExpandthirdFormCityId" {...props} style={{ marginRight: '16px', color:"#C5CBC9" }} />}
                              onBlur={this.setThirdFormCityTouched}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                  style: {
                                    maxHeight: 48 * 6.5,
                                    width: 250,
                                  },
                                },
                              }}
                            >
                              {(this.state.thirdFormDetailsNew.thirdFormCities).length > 0 ? (
                                this.state.thirdFormDetailsNew.thirdFormCities.map((city: CityType) => (
                                  <MenuItem 
                                    key={city.value} 
                                    value={city.value}>
                                    {city.displayValue}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="" disabled>
                                  No cities available
                                </MenuItem>
                              )}
                            </Select>
                            <FormHelperText>{this.returnThirdFormCityHelperText()}</FormHelperText>
                          </FormControl>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                          <TextField
                            name="thirdFormZipCode"
                            id="thirdFormZipCode"
                            label="Zip Code"
                            data-test-id="thirdFormZipCode"
                            variant="outlined"
                            fullWidth
                            onChange={(event) => {
                              {this.handleBankZipCodeChangeCognitive(event)}
                          }}
                            value={this.state.thirdFormAddressDetails.thirdFormZipCode}
                            error={this.returnThirdFormZipCodeErrors()}
                            helperText={this.returnThirdFormZipCodeHelperText()}
                            onBlur={this.setThirdFormZipCodeTouched}
                            style={{ width: "48%" }}
                            InputProps={{
                              style: { borderRadius: "8px" }
                            }}
                            inputProps={{
                              maxLength: 5
                            }}
                          />
                          <TextField
                            name="thirdFormZipCodeEXT"
                            id="thirdFormZipCodeEXT"
                            data-test-id="thirdFormZipCodeEXT"
                            label="Zip Code EXT (optional)"
                            variant="outlined"
                            onBlur={handleBlur}
                            fullWidth
                            onChange={(event) => {
                              {this.handleBankZipCodeEXTChangeCognitive(event)}
                          }}
                            value={this.state.thirdFormAddressDetails.thirdFormZipCodeEXT}
                            style={{ width: "48%" }}
                            InputProps={{
                              style: { borderRadius: "8px" }                            
                            }}
                            inputProps={{
                              maxLength: 4
                            }}
                            error={this.returnThirdFormZipCodeEXTErrors()}
                            helperText={this.returnThirdFormZipCodeEXTHelperText()}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
 
                  <Box className="DealerShipDetailsBox"
                    style={{
                      transition: 'max-height 0.5s ease-in-out',
                      maxHeight: this.state.isExpandedBankAuth ? '1000px' : '88px',
                      overflow: 'hidden',
                      marginTop:"20px"
                    }}>
                    <Box
                      className="dealershipDetailsInnerBox"
                     >
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Typography className="dealerGroupText" style={{lineHeight:"28px"}}>Authorized Signature</Typography>
                          <Typography className="subtitleForm" style={{fontFamily:"Roboto, sans-serif"}}>
                            I (we) hereby authorize VITU to initiate debit entries to the checking
                            account indicated and the depository named to debit the same to such
                            account.
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            width: '32px',
                            height: '32px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={arrowTop}
                            alt="Toggle"
                            style={{
                              transform: this.state.isExpandedBankAuth ? 'rotate(0deg)' : 'rotate(180deg)',
                              width: '32px',
                              height: '32px',
                              transition: 'transform 0.5s ease-in-out',
                            }}
                            onClick={this.toggleExpandBankAuth}
                          />
                        </Box>
                      </Box>
                    </Box>
                
                  <Box className="collapsibleContent" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%",marginTop:"24px" }}>
                    <Box style={{ position: "relative", width: "48%" }}>
                      <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          data-test-id="inputTestIdOne"
                          style={{ position: 'relative' }}
                          value={values.name}
                          name="name"
                          id="name"
                          onBlur={handleBlur}
                          error={this.returnThirdFormName(touched, errors)}
                          helperText={touched.name && errors.name}
                          type="text"
                          onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                          inputProps={{
                            style: { borderRadius: "8px", width: "430px" },
                            maxLength:50
                          }}
                        />
                        <Tooltip title={<><p className="heading">Name</p><p className="text">Authorized Signature is the signature of the individual who has the legal authority to sign documents on behalf of the account or organization. This signature is required to validate and authorize transactions.</p></>} className="infoText" arrow>
                          <IconButton aria-label="info">
                            <img src={infoIcon} alt="info" id="mapIcon" />
                          </IconButton>
                        </Tooltip>
                      </Box>
 
                      <Box style={{ position: "relative", width: "48%" }}>
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          data-test-id="phoneNumber"
                          style={{ position: 'relative' }}
                          value={values.phoneNumber}
                          name="phoneNumber"
                          id="phoneNumber"
                          onBlur={handleBlur}
                          error={this.returnThirdFormPhoneNumber(touched, errors)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          onChange={(event) => this.handleRegex(event, handleChange)}
                          inputProps={{
                            style: { borderRadius: "8px" },
                            maxLength:10
                          }}
                        />
                        <Tooltip title={<><p className="heading">Phone Number</p><p className="text">Best phone number to reach the authorized signer</p></>} className="infoText" arrow>
                          <IconButton aria-label="info">
                            <img src={infoIcon} alt="info" id="mapIcon" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                      <Box style={{ position: "relative", width: "100%" }}>
                        <TextField
                          label="Job Title"
                          variant="outlined"
                          fullWidth
                          data-test-id="inputTestIdOne"
                          style={{ position: 'relative' }}
                          value={values.jobTitle}
                          name="jobTitle"
                          id="jobTitle"
                          onBlur={handleBlur}
                          onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                          error={this.returnThirdFormJobTitle(touched, errors)}
                          helperText={touched.jobTitle && errors.jobTitle}
                          type="text"
                          inputProps={{
                            style: { borderRadius: "8px" },
                            maxLength:100
                          }}
                        />
                        <Tooltip title={<><p className="heading">Job Title</p><p className="text">Job title of the authorized signatory.</p></>} className="infoText" arrow>
                          <IconButton aria-label="info">
                            <img src={infoIcon} alt="info" id="mapIcon" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
 
                    <Box style={{ display: "flex", justifyContent: "space-between", borderRadius: "8px",padding:"24px",border:"1px solid #C5CBC9",marginTop:"24px" }}>
                      {(this.state.taxInfoDetails.uploadFullSignature &&
                        !this.state.activeSignatureView && this.state.isEsignFinish == true) ? (
                        <Box style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
                          <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <img src={closeBtn} style={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={this.removeShowFullSignatureFile}/>
                          </Box>
                          <Box style={{ position: "absolute", top: "-34px", left: "0", backgroundColor: "white", padding: "0px 4px" }}>
                            <Typography style={{ color: "#4B4C4B", fontWeight: 500, fontSize: "12px" }}>Authorization Signature</Typography>
                          </Box>
                          <img
                            height="110px"
                            width="172px"
                            src={this.state.taxInfoDetails.uploadFullSignature }
                          />
                          <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Button onClick={()=>this.handleopenPdfPopupExist('body')} data-test-id="openDocumentDataTestId">
                              <Typography style={{fontSize:'14px', fontWeight:700, color:'#013D4F'}}>Open Document</Typography>
                              <img src={arrowRight} style={{width: '16px', height: '16px' }}/>
                            </Button>
                            <Typography style={{ fontWeight: 500, lineHeight: "18px", fontSize: "12px", color: "#C5CBC9" }}>Signed on {this.getDate()}</Typography>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Box>
                            <Typography className="dealerGrouptText">Authorization Signature</Typography>
                            <Typography className="SubtitleForm" style={{width:"92%",fontFamily:"Roboto, sans-serif"}}>
                              Signature needed for the Electronic Fund Transfer Authorization Agreement for Preauthorized Payments
                            </Typography>
                            </Box>
                            <Box style={{ height: "50px", display: "flex", alignSelf:"center" }}>
                              <Button
                                data-test-id="openESign"
                                variant="outlined"
                                style={{
                                  padding: "10px 24px",
                                  color: "#4B4C4B",
                                  lineHeight: "24px",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  textTransform: "none",
                                  borderRadius: "8px",
                                  textWrap: "nowrap",
                                  maxWidth: "131px",
                                  alignSelf: "center"
                                }}
                                onClick={this.handleopenPdfPopup('body')}
                              >
                                E-Sign Now
                              </Button>
                            </Box>
                        </>
                      )}
                      <Dialog
                        open={this.state.taxInfoDetails.isContractPdfView}
                        onClose={this.handleContractPDFViewClose}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                        fullWidth               
                        maxWidth={false}
                        PaperProps={{
                          style: {
                            borderRadius: '17px',
                          },
                        }}
                      >
                        <Box style={{
                          padding: "16px",
                          position: "absolute",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "98.3%",
                        }}>
                          <img src={logo} style={{ width: "60px", height: "30px" }} />
                          <img src={closeBtn} style={{ width: "30px", height: "30px", cursor: "pointer" }} onClick={this.handleContractPDFViewClose} />
                        </Box>
                        <DialogContent  style={{ overflowY: "auto", padding: "0px",width:"100%" }} >
                          <PDFViewer navigation={undefined} id={""}/>
                          <DialogActions style={ {padding:"20px 0px 20px 0px", display: "flex", justifyContent: "center", alignItems: "center", background:"#F0FAFA",gap:"8%" }}>
                          <FormControlLabel
                            data-test-id='taxInfoDetailsTestId'
                            control={ 
                              <Checkbox  checked={this.state.taxInfoDetails.isPdfCheck} color="primary" onChange={this.handleIsPdfBtn} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}/>
                            }
                            label={
                              <Typography style={{fontWeight:500, color:"#4B4C4B" , width:"100%",display:"flex", gap:"6px"}}>I agree to sign the <span style={{color:"#0F172A", position:"relative" , display:"block"}} >
                                  <div style={{...webStyles.underLIne , position:"absolute"}}></div>
                                  Electronic signature disclosure.
                                  </span></Typography>
                            }
                          />
                            <Button  style={{
                              background: this.state.taxInfoDetails.isPdfCheck ? "#4FC8EC" : "#E8F8FD",
                              color: this.state.taxInfoDetails.isPdfCheck ? "#FFFFFF" : "#75D3F0",
                              textTransform: "none",
                              border:"1px solid #C5CBC9",
                              boxShadow:"none",
                              borderRadius:"8px",
                              
                            }} data-test-id='buttonTestID' variant="contained" onClick={this.handleSignatureOpen} disabled={!this.state.taxInfoDetails.isPdfCheck}>
                              E-Sign Now
                            </Button>
                          </DialogActions>
                        </DialogContent>
                      </Dialog>
                      <Dialog
                        open={this.state.taxInfoDetails.isContractPdfViewSign}
                        onClose={this.handleClosePdfDocSign}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                        fullWidth            
                        data-test-id="test"   
                        maxWidth={false}
                        PaperProps={{
                          style: {
                            borderRadius: '17px',
                          },
                        }}
                      >
                        <Box style={{
                          padding: "16px",
                          position: "absolute",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "98.3%",
                        }}>
                          <img src={logo} style={{ width: "60px", height: "30px" }} />
                          <img src={blackClose} style={{ width: "18px", height: "18px", cursor: "pointer",marginRight:"15px" }} onClick={this.handleClosePdfDocSign} />
                        </Box>
                        <DialogContent  style={{ overflowY: "auto", padding: "0px",width:"100%" }} >
                          <div style={{ width: '100%', height: '90vh', overflow: 'auto' ,background:"white" }}>
                            <embed
                            src={this.state.taxInfoDetails.pdfSignFile}
                            type="application/pdf"
                            style={{ width: '100%', height: '100vh', background:"white",marginTop:"25px"}}
                            id='toolbarViewerCss'
                            />
                          </div>
                        </DialogContent>
                      </Dialog>
                    </Box>
                  </Box>
                  {this.renderThirdFormVoidedCheck() } 
                </MuiThemeProvider>
              </Form>
            )
          }}
        </Formik>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography style={{ fontSize: "14px", color: "#C43937"}}>{this.state.errorsBankInfoBackend}</Typography>
        </div>
      </Box>
    );
  };
 
  step4 = () => {
    return (
      <>
      <Box className="dealerInfoForm">
        <Box className="headerForm">
          <Box className="headingForm">
            <Typography className="titleForm gothamText" data-test-id="contactInfoTestId">Tax Info</Typography>
            <Typography className="subtitleForm" style={{marginTop:'5px'}}>
            VITU requires pertinent information included in the W-9 for tax purposes.
            </Typography>
          </Box>
        </Box>
 
          <Box style={{ position: "relative" }}>
            <Box className="DealerGroupBox">
              <Box>
                <Typography style={{fontWeight:400, fontSize:"16px", color:"#013D4F"}}>Instructions</Typography>
                <Typography style={{fontWeight:500, fontSize:"14px", color:"#4B4C4B"}}>Please visit the link, fill in the form, download, and upload the document into this step when you are done.</Typography>
              </Box>
              <Box style={{marginTop:"20px"}}>
                <Button href={this.state.taxInfoDetails.taxInfoFormLink} target="blank" className="w9FormButton" style={{ marginTop: "10px", padding:"0px 20px"}} data-test-id="W9FormButton" variant="outlined">
                  Click to open a W-9 form
                </Button>
              </Box>
              <Box style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: 'center', position: "relative", marginTop: "10px", marginBottom:"15px" }}>
                <Box style={{ width: "100%", height: "196px", marginTop: "20px" }}>
                  <Dropzone onDrop={this.onDropNew} multiple={false} data-test-id="dropZoneTestId"
                  accept={{
                    'application/pdf': ['.pdf']
                  }}
                  >
                    {({ getRootProps, getInputProps, isDragActive}) => (
                      <div
                        {...getRootProps()}
                        style={{
                          height: "196px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                          color: "black",
                          border: "1px solid #C5CBC9",
                          borderRadius: "8px",
                          backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
                        }}
                      >
                        <input {...getInputProps()} />
                        {this.state.taxInfoDetails.taxInfoFile ? (
                          <Box className="test12" style={{ height: "171px", width: "100%", display: "flex", flexDirection: 'column' }}>
                            <Box className="test123" style={{ width: "100%", height: "11.67px", marginTop: "10px" }}>
                              <div data-test-id="removeTaxPhotoDiv" style={{ textAlign: 'right' }}>
                                <Button data-test-id="removeTaxPhoto" onClick={this.removeFile} style={{ width: "20px", height: "20px", marginRight: "22px", border: "0px", backgroundColor: 'white', cursor: 'pointer' }}><img src={closeBtn} style={{ width: "20px", height: "20px", color: "#4B4C4B" }} /></Button>
                              </div>
                            </Box>
                            <Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <img src={pdfFile} />
                              <Typography data-test-id='selectedFileName' style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginTop: "10px" }}>{this.state.taxInfoDetails.taxInfoFile.name}</Typography>
                            </Box>
                          </Box>
                        ) : this.isDragActiveFunction(isDragActive,false,true)}
                      </div>
                    )}
                  </Dropzone>
                  <Box style={{ position: "absolute", top: 5, left: 17, backgroundColor: "white", padding: "0px 4px" }}><Typography style={{ color: "#4B4C4B", fontWeight: 500, fontSize: "12px" }}>Upload W-9 Form</Typography></Box>
                </Box>
              </Box>
            </Box>
          </Box>
      </Box>
      <Box className="dealerInfoForm" style={{zIndex:1200}}>
        <Drawer anchor={"right"} open={this.state.isOpen} style={{zIndex: 1200}} 
        >
          <Box style={{ width: "768px", height: "100%", display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <Box style={{ width: `${this.state.dealerDetails.dealerGroup ? '512px' : '460px'}`, height: "308px", display: 'flex', justifyContent: "space-between", alignItems: "center", flexDirection: 'column' }}>
              <Box style={{ width: "140px", height: "140px" }}>
                <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid item>
                    <Box style={{ width: '140px', height: '140px', borderRadius: '24px', textAlign: 'center', backgroundImage: `url(${finalbg})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      { this.state.dealerDetails.profilePhoto 
                        ?
                        <Avatar 
                        variant="square" 
                        className="avatar" 
                        src={this.state.dealerDetails.profilePhoto} 
                        style={{ width: '100%', height: '100%', backgroundColor: "white" , borderRadius: '24px'}} />
                        :
                        (
                          <>
                            {this.state.dealerDetails.initials 
                            ?
                              (
                                <Avatar variant="square"
                                  style={{ width: '130px', height: '130px', borderRadius: '24px', backgroundColor: 'rgba(240,250,250)', fontWeight: 500, color: '#013D4F', fontSize: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                  <Typography style={{fontSize:'45px'}}>
                                    {this.state.dealerDetails.initials}
                                    </Typography>
                                </Avatar>
                              ) 
                              :
                              null
                            }
                          </>
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', marginTop: "35px" }}>
                <Typography style={{ fontSize: "18.75px", fontWeight: 300, color: "#013D4F" }} className="gothamtext" data-test-id="dealershipCreatedTextId">Dealership Created</Typography>
                <Typography style={{ fontWeight: 500, fontSize: `${this.state.dealerDetails.dealerGroup ? "14px" : "12px"}`, color: "#4B4C4B", textAlign: 'center', marginTop: "6px" }}>{this.state.dealerDetails.dealerGroup ? "Setup complete! Invite other users, create another dealership, or visit your home dashboard." : "Setup complete! Invite other users, create a deal, or visit your home dashboard."}</Typography>
              </Box>
              <Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "35px" }}>
                <Box style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                  <Button style={{ width: "47.5%", height: "56px", border: "0.78px solid #C5CBC9", borderRadius: "8px" }} onClick={() => this.setShowInviteUser(true)} data-test-id="inviteUserButtonTestId"><Typography style={{ fontWeight: 500, fontSize: "12.5px", color: "#4B4C4B", textTransform: "none" }}>Invite User</Typography></Button>
                  <Button style={{ width: "47.5%", height: "56px", backgroundColor: "#4FC8EC", borderRadius: "8px" }} onClick={this.state.dealerDetails.dealerGroup ? this.navigateToCreateAnotherDealership : this.navigateToCreateDeal} data-test-id="createDealButtonTestId"><Typography style={{ fontWeight: 500, fontSize: "12.5px", color: "white", textTransform: "none" }}>{this.state.dealerDetails.dealerGroup ? "Create Another Dealership" : "Create Deal"}</Typography></Button>
                </Box>
                <Button style={{ marginTop: "5px", height: "56px", width: "244px" }}>
                  <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#013D4F", textTransform: "none" }} onClick={()=>this.navigateToDashboard(false)} data-test-id="goToDashboardButtonTestId">Go to Dashboard</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Drawer>
          <div style={{ display: "flex",  alignItems: "center", justifyContent: "center", width: "100%" }}>
            <Typography
              style={{ color: "#C43937", fontSize: "14px" }}>
              {this.state.errorsTaxInfoBackend}
            </Typography>
          </div>
      </Box>
      {this.InviteUserModal()}
      </>
    );
  };
 
// Customizable Area End

  render() {
    // Customizable Area Start
    const {activeTab, editDealershipId} = this.state
    return (
      <Box>
        <CustomisableUserProfilesStyle>
          {this.state.isFirstDealership ?
            <Box>
              <Box className="header" style={{position:"relative", marginTop:"0px"}}>
                <img className="logo" src={logo} alt="logo"></img>
                <Button className="logout" data-test-id="logoutTest" variant="outlined" onClick={this.logout}>
                  Log Out
                </Button>
              </Box>
            </Box>
            : this.headerr()
          }
          <Box style={{ width: "99.9%", position: "absolute", left: "0", borderTop: "2px solid #F0FAFA", marginTop: "10px" }}>
          </Box>
          <Box className="heading">
            <Typography className="title gothamText">{editDealershipId ? "Edit Dealership Profile" : "Create Dealership"}</Typography>
            <Typography className="subtitle gothamText">
              {this.getSubtitle()}
            </Typography>
          </Box>
          <Box className="mainContent">
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Box className="steps">
                {this.renderNavItem(1, "Dealership Info", "Your business info")}
                {this.renderNavItem(2, "Contact Info", "How to contact you")}
                {this.renderNavItem(3, "Banking Info", "How to make payments")}
                {this.renderNavItem(4, "Tax Info", "W-9")}
              </Box>
              <Box style={{ borderLeft: "2px solid #F0FAFA", left: "26%", height: "560px", top: "0%" }}></Box>
 
            </Box>
            <Box className="stepContent">
              <Box className="content">{this.renderContent()}</Box>
              <Box className="nextStep">
                <Button variant="contained" onClick={this.nextStep} disabled={!this.isNextButtonEnabled()} data-test-id="nextStepBtn" id="nextStepBtn">{this.handleCognitiveFinishOrNextStep()}</Button>
                <Typography>
                  Profile progress will be automatically saved.
                </Typography>
              </Box>
            </Box>
          </Box>
          {this.handleCognitiveEsignModal(activeTab)}
        </CustomisableUserProfilesStyle>
        {this.backToDashboardModal()}
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomisableUserProfilesStyle = styled(Box)(({theme}) => ({
  padding: "24px",
  boxSizing: "border-box",
  minWidth: '1366px',
  "& .dealerInfoForm": {
    padding: "0px 20px",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  "& .header": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems:"center"
  },
  "& .logo": {
    width: "54px",
    height: "24px",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: "6px", 
      height:"56px",
      "& .MuiAutocomplete-input": {
        padding: "0px", 
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "9px", 
      },
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '9px',
      },
      '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
        backgroundColor: "#013D4F",
      },
      "& .MuiAutocomplete-popper": {
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            "&[data-focus='true'], &:hover": {
              backgroundColor: "##013D4F",
            },
            "&[aria-selected='true']": {
              backgroundColor: "#013D4F",
            },
          },
        },
      },
    },
  },
  "& .logout, .skip": {
    width: "98px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .w9FormButton": {
    marginTop:"20px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
  },
  "& .collapsibleContent": {
    transition:"max-height 0.3s ease-in-out",
  },
  "& .heading": {
    paddingTop: "112px",
    paddingBottom: "32px",
    margin: "0 14.27%",
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      margin: "0 8%",
    },
  },
  "& .title": {
    fontSize: "30px",
    lineHeight: "40px",
    color: "#013D4F",
    fontWeight: 300
  },
  "& .subtitle": {
    fontSize: "18px",
    lineHeight: "18px",
    color: "#4B4C4B",
    fontWeight: 300,
    marginTop:"10px",
    zIndex: 40,
    position:'relative'
  },
  "& .headingForm": {
    fontFamily: "Gotham, sans-serif",
    fontWeight: 300,
  },
  "& .titleForm": {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    fontWeight: 300,
    minWidth:'350px'
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    }
  },
  '& label.Mui-focused': {
    color: '#4B4C4B',
    fontWeight:500
  },
  '& .MuiInputLabel-root': {
    paddingLeft: '6px',
    color: '#C5CBC9',
  },
  '& label.MuiInputLabel-shrink': {
    marginLeft: 0,
    paddingLeft: 0,
    color: '#4B4C4B',
    fontWeight:500
  },
  "& .radioStyle": {
    width:"20px", 
    height:"20px"
  },
  "& .dealerGroupBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 20px",
    border: "2px solid #F0FAFA",
    borderRadius : "16px"
  },
  "& .dealerGroupText": {
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  "& .custom-placeholder": {
     paddingLeft:"10px",
     fontSize:"16px",
     fontWeight:400,
    },
  "& .MuiFormGroup-root": {
    display: "inline",
  },
  "& .MuiFormControlLabel-label": {
    marginTop:"3.5px"
  },
  "& .dealershipDetailsBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px",
    border: "2px solid #F0FAFA",
    borderRadius : "16px",
    height:"auto"
  },
  "& .dealershipDetailsInnerBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius : "16px",
    height:"auto",
  },
  "& .dealerGrouptText": {
    color:"#013D4F",
    fontWeight:400,
    marginBottom:"5px",
    fontSize:"16px",
    lineHeight:"24px"
  },
  "& .SubtitleForm":{
      color:"#4B4C4B",
      fontWeight:500,
      lineHeight:"22px",
      fontSize:"14px"
  },
  "& .subtitleForm": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#4B4C4B",
  },
  "& .mainContent": {
    margin: "0 14.37%",
    display: "flex",
    position: "relative",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      margin: "0 8%",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "98.7%",
      borderTop: "2px solid #F0FAFA",
    }
  },
  "& .steps": {
    display:"flex",
    flexDirection:"column",
    marginRight: "24px",
    marginTop:"26px",
    padding:"20px 0px",
    width:"250px"
  },
  "& .stepContent": {
    flex: "3",
    marginTop:"24px",
    width:"400px",
    padding:"20px 0px",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0",
    },
  },
  "& .stepItem": {
    marginBottom: "24px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "16px",
    color: "#C5CBC9",
  },
  "& .stepItem.active .stepTitle": {
    color: "#013D4F",
    "& .MuiTypography-body1": {
      color:"#013D4F"
    }
  },
  "& .stepItem.done .stepTitle": {
    color: "#26BBB3",
    "& .MuiTypography-body1": {
      color:"#26BBB3"
    }
  },
  "& .stepTitle": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
  },
  "& .stepSubtitle": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  "& .stepText": {
    textAlign: "end",
  },
  "& .stepNumber": {
    width: "48px",
    height: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius:'50%',
    backgroundColor:"rgba(240, 250, 250, 0.78)"
  },
  "& .stepItem.skipped .stepTitle": {
    color: "#F05E22",
    "& .MuiTypography-body1": {
      color:"#F05E22"
    }
  },
  "& .stepItem.clickable": {
    cursor: "pointer",
  },
  "& .stepItem:not(.clickable)": {
    cursor: "not-allowed",
  },
  "& .pac-container.pac-logo": {
    width: "800px !important",
    backgroundColor: "red !important", 
    padding: "10px !important",
    fontSize: "16px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)", 
    border: "1px solid #ccc", 
    borderRadius: "4px", 
  },
  "& .pac-item": {
    padding: "10px",
    fontSize: "14px",
    cursor: "pointer",
  },
  "& .pac-item:hover": {
    backgroundColor: "red",
  },
  "& .pac-item-query": {
    fontSize: "14px",
    color: "#red",
  },
  "& .pac-matched": {
    fontWeight: "bold",
  },
  "& .pac-icon": {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  "& .headerForm": {
    marginBottom: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "& .photoForm": {
    maxWidth: "640px",
    height: "321px",
    background: `url(${profileBackground}) no-repeat top center`,
    backgroundSize: "100% 191px",
    border: "2px solid #F0FAFA",
    borderRadius: "30px",
  },
  "& .photoFormContent": {
    padding: "48px",
    marginTop: "81px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
  "& .profilePhoto": {
    width: "120px",
    height: "120px",
    borderRadius: "30px",
  },
  "& .updatePhoto": {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  "& .uploadPhoto": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .upload, .delete": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .upload, .uploadLogo": {
    color: "#013D4F",
    border: "1px solid #013D4F",
  },
  "& .delete": {
    border: "1px solid #C43937",
    color: "#C43937",
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#C43937',
  },
  "& .uploadLogo": {
    position: "absolute",
    top: "12px",
    right: "24px",
    height: "32px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .hints": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "10px",
    color: "#C5CBC9",
  },
  "& .nextStep": {
    marginTop: "24px",
    textAlign: "center",
  },
  "& .nextStep button": {
    width: "89%",
    height: "56px",
    marginBottom: "8px",
    backgroundColor: "#4FC8EC",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none",
    borderRadius:"8px"
  },
  "& .nextStep p": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
  },
  "& .form": {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  "& .formControl": {
    position: "relative",
    height: "56px",
    padding: "0 24px 10px 24px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .Mui-checked": {
    color:"#4FC8EC",
  },
  "& .info": {
    position: "absolute",
    top: "-35px",
    right: "0",
  },
  "& .infoTextFirst": {
    position: "absolute",
    top: "53px",
    right: "24px",
  },
  "& .infoTextVoidedCheck": {
    position: "absolute",
    top: "0px",
    right: "4px",
  },
  "& .infoTextLast": {
    position:"absolute",
    right:"2px",
    top: "0px",
  },
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: '#C5CBC9',
      opacity: 1,
    },
  },
  "& .infoText": {
    position: "absolute",
    top: "-23px",
    right: "2px",
  },
  "& .infoTextStep3": {
    position: "absolute",
    top: "72px",
    right: "26px",
  },
  "& .infoTextBankAddress": {
    position:"absolute",
    top:"0px",
    right:"2.5px",
  },
  "& .same": {
    marginTop: "-14px",
    marginLeft: "7px",
    fontWeight:500,
  },
  "& .nextStep button:disabled": {
    color: "#75D3F0",
    backgroundColor: "#E8F8FD",
  },
  "& .MuiAutocomplete-groupUl": {
    backgroundColor:"red"
  },
})
);
const DetailBox = styled(Box)({
  "@media (max-width:960px)": {
     flexDirection:'row', justifyContent:'center', alignItems:'center', width:'215px'
    },
    "@media (min-width:720px)": {
      
    }
})
const StyledPaper = styled(Paper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    '& li': {
      height: '56px',
      display: 'flex', 
      alignItems: 'center', 
      '&:hover': {
        backgroundColor: '#F0FAFA', 
      },
    },
  },
}));
 
const webStyles = {
  signatureContainer: {
    display: "flex",
    width: "100%",
    height: "100vh",
    background: "rgba(1, 61, 79, 0.52)",
    top: 0,
    left: 0,
    zIndex: 123,
    justifyContent: "right",
    backdropFilter: "blur(3px)",
    overflow: "hidden",
  },
  signatureLeftContainer: {
    width: "100%",
    maxWidth: "700px",
    background: "#FFFFFF",
    height: "100vh",
    padding: "48px 48px",
    position:"relative",
    overflowX:"auto"
  },
  signatureHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "auto",
    alignItems: "center"
  },
  signatureTitle: {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    fontWeight: 300,
    fontFamily: "Gotham, sans-serif"
  },
  signatureSubTitle: {
    fintSize: "14px",
    fontWeight: 500,
    lineHoght: "22px",
    marginTop: "5px",
    fontFamily: "Roboto, sans-serif",
    color:"#4B4C4B"
  },
  signatureNavigation: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    borderBottom: "1px solid black",
    marginTop: "35px"
  },
  navigationTab: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    display: "grid",
    placeItems: "center",
    fontFamily: "Roboto, sans-serif"
  },
  activeTab: {
    borderBottom: "2px solid #013D4F",
    fontWeight: 500,
    color:"#013D4F",
  },
  tabContent: {
    marginTop: "20px",
    height: "59vh"
  },
  nextStep: {
    marginTop: "-44px",
  },
  nextStepBtn: {
    width: "100%",
    height: "56px",
    marginBottom: "8px",
    backgroundColor: "#4FC8EC",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none",
    borderRadius: "8px"
  },
  finishBtnDisable: {
    color: "#75D3F0",
    backgroundColor: "#E8F8FD",
    width: "100%",
    height: "56px",
    marginBottom: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    borderRadius: "8px"
  },
  signatureHeaderLeft: {},
  signatureHeaderRight: {
    alignSelf:"baseline"
  },
  underLIne : {
    width: "100%",
    height: "1px",
    backgroundColor: "#0F172A",
    bottom: "5px",
  },
  imageBox:{
   border: "1px solid #C5CBC9",
   borderRadius: "8px",
   width: "693px",
   height: "247.5px",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
  },
}

const webStylesHeader : Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
 
  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 0px",
  },
 
  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "0px",
  },
 
  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
  },
 
  logo: {
    width: "52px",
  },
 
  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
  },
 
 
  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
 
  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
 
  main: {
    display: "flex",
    flexWrap: "nowrap",
    position: "relative",
  },
 
 
  buttonText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "14px",
    fontWeight: 500,
  },
 
  content: {
    width: "100%",
    marginLeft: "120px",
  },
 
}

const InviteUserBackdrop = styled(Backdrop)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "rgba(1, 61, 79, 0.4)",
  height: "100vh",
  zIndex: 1300,
  alignItems: "unset",

  "& .successInvite": {
    display: "flex",
    alignSelf: "center",
    justifySelf: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },

  "& .successInviteImage": {
    width: "180px",
    height: "227px",
    marginBottom: "76px",
  },

  "& .successMessageBlock": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "48px",
  },

  "& .successTitle": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Gotham, sans-serif",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 300,
    color: "#013D4F",
  },

  "& .successDescription": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .successButtonsContainer": {
    display: "flex",
    flexWrap: "nowrap",
    gap: "24px",
  },

  "& .successViewDealership": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    padding: "0 10px",
    flexGrow: 1,
    backgroundColor: "transparent",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
    cursor: "pointer",
    color: "#4B4C4B",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
  },

  "& .successInviteMore": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    padding: "0 10px",
    flexGrow: 1,
    backgroundColor: "#4FC8EC",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
  },

  "& .inviteContainer": {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    flexBasis: "768px",
    flexShrink: 1,
    padding: "48px 64px",
    gap: "48px",
    backgroundColor: "#FFF",
    overflow: "auto",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "39px",
    },

    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
    },
  },

  "& .inviteTitle": {
    fontFamily: "Gotham, sans-serif",
    lineHeight: "32px",
    fontSize: "24px",
    color: "#013D4F",
    fontWeight: 300,
  },

  "& .inviteDescription": {
    fontFamily: "Roboto, sans-serif",
    lineHeight: "22px",
    fontSize: "14px",
    color: "#4B4C4B",
    fontWeight: 500,
  },

  "& .formTitle": {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "20px",
    color: "#013D4F",
  },

  "& .inviteUsersContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
  },

  "& .addedUserTitle": {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "20px",
    fontWeight: 500,
  },

  "& .usersList": {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
  },

  "& .userItem": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px",
  },

  "& .userBlankAvatar": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    color: "#013D4F",
    backgroundColor: "#F0FAFA",
    borderRadius: "12px",
  },

  "& .userInfoContainer": {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  "& .userInfoEmail": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .userInfoDealerships": {
    fontFamily: "Roboto, sans-serif",
    color: "#4B4C4B",
    fontWeight: 400,
    fontSize: "14px",
  },

  "& .userActionsContainer": {
    display: "flex",
    height: "100%",
    flexWrap: "nowrap",
    alignSelf: "flex-start",
    gap: "24px",
  },

  "& .userRole": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "41px",
    height: "24px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 600,
    fontSize: "12px",
    color: "013D4F",
    borderRadius: "26px",
    backgroundColor: "#F0FAFA",
  },

  "& .editUser": {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  "& .deleteUser": {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  "& .inviteFormContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#4FC8EC",
    },

    "& ul > li .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "transparent",
    },
  },

  "& .MuiInputLabel-formControl": {
    transform: "translate(16px, 21px) scale(1)",
  },

  "& .MuiInputLabel-shrink": {
    transform: "translate(12px, -6px) scale(0.75)",
  },

  "& .inviteDealershipsUsers": {
    height: "56px",
    flexShrink: 0,
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    color: "#FFF",
    backgroundColor: "#4FC8EC",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
  },

  "& .addAnotherUserButton": {
    display: "flex",
    alignSelf: "flex-end",
    justifyContent: "center",
    alignItems: "center",
    width: "180px",
    height: "44px",
    color: "#013D4F",
    border: "1px solid #013D4F",
    borderRadius: "8px",
    fontWeight: 700,
    backgroundColor: "transparent",
    cursor: "pointer",
  },
});

const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#4FC8EC",
  },

  "&:hover": {
    backgroundColor: "transparent",
  },
});

const InviteRadioGroupContainer = styled(Box)({
  display: "flex",
  position: "relative",
  alignItems: "center",
  border: "1px solid #C5CBC9",
  borderRadius: "8px",
  padding: "0 24px",
  height: "56px",

  "& p.radioLabel": {
    position: "absolute",
    top: "-9px",
    left: "15px",
    padding: "1px 8px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.87)",
  },

  "& .MuiTypography-body1": {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
  },

  "& .MuiRadio-colorSecondary.Mui-checked": {
    color: "#4FC8EC",
  },

  "& .MuiSvgIcon-root": {
    fontSize: "23px",
  },

  "& .MuiIconButton-colorSecondary:hover": {
    backgroundColor: "transparent",
  },

  "& .MuiRadio-colorSecondary.Mui-checked:hover": {
    backgroundColor: "transparent",
  },
});

const StyledModalBox = styled(Box)({
  width: '512px',
  minWidth:'200px',
  border: '1px solid #FFFFFF',
  boxShadow: theme.shadows[5],
  backgroundColor:'#FFFFFF',
  padding: theme.spacing(2, 4, 3),
  borderRadius:'8px',
})
 
// Customizable Area End
