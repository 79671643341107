
import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Avatar,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { vitu, home, bar, myTeam, allDeals, bell } from "./assets";
import CreateDealMainController, {
  Props,
  DashboardView
} from "./CreateDealMainController.web";
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
import HamburgerMenu from "../../customisableuserprofiles/src/HamburgerMenu.web";
import CreateDealButton from "./CreateDealButton.web";

export default class CreateDealMain extends CreateDealMainController {
  constructor(props: Props) {
    super(props);
  }

  SidebarButton = styled(Button)({
    width: "80px",
    height: "65px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E8F8FD"
    },
    "& > span": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textTransform: "capitalize"
    }
  });

  render() {
    const { currentView, userData } = this.state;

    return (
      <Box>
        <Box sx={webStyles.container}>
          <Box sx={webStyles.header}>
            <Box sx={webStyles.headerLeftSide}>
              <HamburgerMenu navigation={this.props.navigation} id={""} data-test-id="hamburgerMenuTestId" />

              <Box sx={webStyles.logo}>
                <img src={vitu} width={52} height={24} alt="vitu logo" />
              </Box>
            </Box>

            <Box sx={webStyles.headerRightSide}>
              <CreateDealButton fullWidth={false} 
                data-test-id="create-deal-btn" 
                navigation={this.props.navigation} 
                dealerships={this.state.dealerships} 
                savedDeals={this.state.savedDeals}>
                  Create Deal
              </CreateDealButton>
              
              <Box
                data-test-id="notifications"
                sx={webStyles.notificationsButton}
              >
                <img src={bell} alt="notifications button" />
              </Box>

              <ProfileDropdown navigation={this.props.navigation} id={""} data-test-id="profileDropdownTestId"/>

            </Box>
          </Box>

          <Box sx={webStyles.main}>
            <Box sx={webStyles.sidebar}>
              <SidebarButton
                data-test-id="home-button"
                onClick={this.navigateToDashboard}
              >
                <img src={home} alt="home button" />
                <span style={webStyles.buttonText}>Home</span>
              </SidebarButton>

              <SidebarButton
                data-test-id="deals-button"
                style={{
                  background: this.getStyle(
                    currentView === DashboardView.ALL_DEALS,
                    "#E8F8FD",
                    "transparent"
                  )
                }}
              >
                <img src={allDeals} alt="deals button" />
                <span style={webStyles.buttonText}>All Deals</span>
              </SidebarButton>

              <this.SidebarButton
                data-test-id="team-button"
                onClick={this.navigateToMyTeam}
              >
                <img src={myTeam} alt="home button" />
                <span style={webStyles.buttonText}>My Team</span>
              </this.SidebarButton>
            </Box>

            <Box sx={webStyles.content} style={{marginLeft:'auto'}}>
              <Container
                style={{ ...webStyles.containerStyle, position: "relative" }}
              >
                <Box
                  display={"inline-flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={"24px"}
                  border="2px dashed #96C2CF"
                  maxWidth={"872px"}
                  width={"100%"}
                  padding="40px 26px"
                >
                  <Box sx={webStyles.contentInner}
                    >
                  <Box style={{display:'flex'}} justifyContent={"center"}alignItems={"center"}>
                    <Box
                      display={"flex"}
                      sx={{ flexDirection: "column" }}
                      alignItems={"start"}
                      style={{ gap: "15px" }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "31px",
                          color: "#C5CbC9"
                        }}
                      >
                        You have no deals yet
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          fontFamily: "Gotham",
                          fontSize: "36px",
                          fontStyle: "normal",
                          fontWeight: "300",
                          lineHeight: "31px",
                          color: "#013D4F"
                        }}
                      >
                        Create Your First Deal Now!
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          fontFamily: "Roboto",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "31px",
                          color: "#C5CbC9",
                        }}
                      >
                        Track your deals and explore add-ons to expedite your
                        registration processing
                      </Typography>
                    </Box>
                    <Box
                      height="70px"
                      display={"flex"}
                      style={{ gap: "13px" }}
                      justifyContent={"center"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="34"
                        viewBox="0 0 33 34"
                        fill="none"
                      >
                        <path
                          d="M18.7897 1.66406C17.8423 6.02846 13.0581 14.7573 1.5 14.7573"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M18.7891 32.3906C19.4605 28.0822 22.8513 19.4653 31.043 19.4653"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M18.7891 1.5C19.4605 7.48706 22.8513 19.4612 31.043 19.4612"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M18.7897 32.3906C17.8423 26.5155 13.0581 14.7652 1.5 14.7652"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="43"
                        height="45"
                        viewBox="0 0 43 45"
                        fill="none"
                      >
                        <path
                          d="M24.4899 1.71875C23.2051 7.63738 16.7171 19.4747 1.04297 19.4747"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M24.4897 43.3906C25.4003 37.5479 29.9986 25.8624 41.1074 25.8624"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M24.4897 1.5C25.4003 9.61915 29.9986 25.8575 41.1074 25.8575"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M24.4899 43.3906C23.2051 35.4232 16.7171 19.4885 1.04297 19.4885"
                          stroke="#013D4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Box>
                  </Box>
                    <CreateDealButton fullWidth={true}  data-test-id="mainbtnn" navigation={this.props.navigation} dealerships={this.state.dealerships} savedDeals={this.state.savedDeals}>Create Your First Deal</CreateDealButton>
                  </Box>                 
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const SidebarButton = styled(Button)({
  width: "80px",
  height: "65px",
  cursor: "pointer",

  "& > span": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textTransform: "capitalize"
  }
});

const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
  },

  containerStyle: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "300px",
    marginBottom: "500px",
    gap: "0px"
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    height: "69px",
    flexShrink: 0,
    boxSizing: "border-box",
    padding: "0 32px",
    borderBottom: "1px solid #F0FAFA",
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "no-wrap",
    alignItems: "center",
    gap: "8px"
  },

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",

    "& > img": {
      width: "100%",
      height: "100%"
    }
  },

  logo: {
    width: "52px"
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "no-wrap",
    alignItems: "center",
    gap: "24px",
    
    "@media (max-width: 360px)": {
      gap: "8px",
      width: "80%",
      padding: "8px 15%"
    },
  },

  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",

    "& > img": {
      width: "100%",
      height: "100%"
    }
  },

  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",

    "& > img": {
      width: "100%"
    }
  },

  main: {
    display: "flex",
    flexWrap: "no-wrap",
    position: "relative",
    height: "100%"
  },

  sidebar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0",
    gap: "32px",
    width: "120px",
    borderRight: "1px solid #F0FAFA"
  },

  buttonText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500
  },

  content: {
    display: "flex",
    justfyContent: "center",
    alignItems: "center",
    width: "100%"
  },

  contentInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "42px",
    maxWidth: "819px",
    width: "100%",

    "@media (max-width: 360px)": {
      marginTop: "30px"
    },
  }
};
