Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.apiKey =  `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqGLrGi6T2_Yra0AANe5D5L741KMnGKnY&libraries=places&v=3`;
exports.testApiEndpoint = "bx_block_profile/dealer_informations";
exports.dealershipPutApiEndpoint = "bx_block_profile/dealer_informations/";
exports.contactApiEndpoint = "account_block/accounts/update_account";
exports.getContactInfoAPIEndpoint = "account_block/accounts/get_user";
exports.getBankInfoAPIEndpoint = "bx_block_profile/bank_informations/fetch_bank_information";
exports.bankInfoAPIEndpoint = "bx_block_profile/bank_informations";
exports.taxInfoAPIEndpoint = "bx_block_profile/dealer_informations/add_tax_info";
exports.taxInfoLinkAPIEndpoint = "bx_block_profile/tax_informations";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "account_block/accounts/get_user";
exports.updateCustomizableProfileEndPoint = "account_block/accounts/update_account";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.createDealerDetails = "bx_block_profile/business_informations";
exports.getDealershipsUrl = "bx_block_profile/dealer_informations";
exports.getUserUrl = "account_block/accounts/get_user"
exports.getRolesUrl = "bx_block_profile/dealer_informations/get_dealership_roles"
exports.inviteUserToDealershipUrl = "bx_block_teambuilder/dealership_members/invite_user";
exports.dealershipInfoText = "Dealership Info";
exports.dealershipInfoSubText = "Tell us about your company";
exports.skiptext = "Skip";
exports.dealerGroupText = "Dealer Group";
exports.tooltipAreYouText = "Are you a Dealer Group?";
exports.tooltipAreYouSubText = "Are you an independent dealership or are you part of a dealer group with multiple stores?";
exports.dealershipDetailsText = "Dealership Details";
exports.dealershipAddressText = "Dealership Address";
exports.searchDealershipAddressText = "Search Dealership Address";
exports.cityText = "City";
exports.dealershippingAddressText = "Dealer Shipping Address";
exports.TooltipDealershipAddressText = "Dealership Address";
exports.TooltipCompanyAddressText = "Company Address";
exports.stateText = "State";
exports.tooltipDealershipCredText = "Dealership Credentials";
exports.tooltipWhatDealsText = "What type of deals do you do?";
exports.typeOfTransactionsText = "Type of transactions you process at your dealership";
exports.titleText = "Create Dealership";
exports.subtitleText = "Set up your first dealership to get started";
exports.nextStepText = "Next Step";
exports.profileProgressText = "Profile progress will be automatically saved.";
exports.reupload = "Upload Dealership Logo";
exports.removephoto = "Remove Logo";
exports.uploadphoto = "Upload Dealership Logo";
exports.spUploadphoto = "Upload Service Provider Logo";
exports.photodetail = "Use images at least 800x800 pixels in .jpg or .png format. If no logo is uploaded, dealership name initials will be used.";
exports.spPhotodetail = "Use images at least 800x800 pixels in .jpg or .png format. If no logo is uploaded, dealership name initials will be used.";
exports.formPostEndpoint = "bx_block_profile/dealer_informations";
exports.redirectToURL = "https://www.irs.gov/pub/irs-pdf/fw9.pdf";
exports.fetchPdf = "/bx_block_profile/e_signs";
exports.serviceProviderInfoEndpoint = "/bx_block_profile/service_provider_informations";
exports.fetchPdfSign = "/bx_block_profile/bank_informations/merge_signature"
exports.spTaxInfoApiEndPoint = "/bx_block_profile/service_provider_informations/update_tax_info"
exports.spServicebleLocationApiEndPoint = "/bx_block_profile/serviceable_locations"
exports.dealsCountGetEndPoint = '/bx_block_posts/deals'
exports.getProviderDetailsEndPoint= "/bx_block_profile/service_provider_informations/get_provider_details";
exports.addState="Add State"
exports.finishBtn="Finish"
exports.saveBtn="Save"
exports.deleteBtn="Delete Serviceable Location"

// Customizable Area End
