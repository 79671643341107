import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
export const configJSON = require("./config");
import * as Yup from "yup";

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  applicationType: string;
  registrationAction: string;
  vin: string;
  vehicleType: string;
  vehicleYear: string;
  emptyWeight: string;
  grossWeight: string;
  saleType: string;
  soldAs: string;
  msrp: string;
  tsp: string;
  sellerZip: string;
  ownerFirstName: string;
  ownerLastName: string;
  purchaserZip: string;
  firstName: string;
  lastName: string;
  PurchaserZip: string;
  registrationType: string;
  apiToken: string | null;
  checkDecode: boolean;
  deal_id: string;
  dealer_information_id: string;
  headerCheck: boolean;
  decodeError: string;
}

interface SS {
  id: any;
}

export default class CreateDealFormController
  extends BlockComponent<Props, S, SS>
  implements IBlock {
  createDealApiCallId: string = "";
  validationSchema: any;
  getDecodeApiId: string = "";
  getDealInformationId: string = "";
  autopopulateSellerZipApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleDecode = this.handleDecode.bind(this);

    this.state = {
      applicationType: "",
      registrationAction: "",
      vin: "",
      vehicleType: "",
      vehicleYear: "",
      emptyWeight: "",
      grossWeight: "",
      saleType: "",
      soldAs: "",
      msrp: "",
      tsp: "",
      sellerZip: "",
      ownerFirstName: "",
      ownerLastName: "",
      purchaserZip: "",
      firstName: "",
      lastName: "",
      PurchaserZip: "",
      registrationType: "",
      apiToken: null,
      checkDecode: false,
      deal_id: "",
      dealer_information_id: "",
      headerCheck: true,
      decodeError: ""
    };

    this.validationSchema = Yup.object({
      applicationType: Yup.string().required("Application Type is required"),
      registrationType: Yup.string().when("applicationType", {
        is: "Title and Registration",
        then: Yup.string().required("Registration Type is required")
      }),
      vin: Yup.string()
        .matches(
          /^[A-Z0-9]{11,17}$/,
          "VIN must be between 11 and 17 alphanumeric characters"
        )
        .required("VIN is required"),
      vehicleType: Yup.string().required("Vehicle Type is required"),
      vehicleYear: Yup.string().required("Vehicle Year is required"),
      emptyWeight: Yup.string()
        .matches(
          /^\d{1,10}$/,
          "Weight must be up to 10 digits and only numbers"
        )
        .required("Empty weight is required"),
      grossWeight: Yup.number()
        .typeError("Weight must be a number")
        .positive("Weight must be a positive number")
        .integer("Weight must be an integer")
        .max(9999999999, "Weight must be up to 10 digits")
        .required("Gross weight is required")
        .test("is-greater-than-empty-weight", "Gross Weight must be equals or bigger than Empty Weight", (value)=> {return value >= this.state.emptyWeight}),
      saleType: Yup.string().required("Sale Type is required"),
      soldAs: Yup.string().required("Sold As is required"),
      msrp: Yup.string().required("MSRP is required"),
      tsp: Yup.string().required("TSP is required"),
      sellerZip: Yup.string().required("Seller ZIP is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      purchaserZip: Yup.string().matches(/^\d{5}$/,"Purchaser ZIP must be in 5 digits format").required("Purchaser ZIP is required")
    });

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    if (localStorage.getItem("dInfoId")) {
      this.autopopulateSellerZip();
    }

    // Customizable Area End
  }
  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.apiToken != this.state.apiToken && this.state.apiToken != null) {

      let id = localStorage.getItem("dId")
      if (id) {
        this.getDealInformation()
      }
    }
    // Customizable Area End
  }

  async componentWillUnmount() {
    localStorage.removeItem("dId")
  }

  autopopulateSellerZip() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.autopopulateSellerZipApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.autopopulateSellerZipApi + localStorage.getItem("dInfoId")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getDealInformation() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.apiToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDealInformationId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDealInformationApi + this.state.deal_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDealInformationMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);

      this.handleTokenSet(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createDealApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleJsonResponse(resJson);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDecodeApiId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleDecodeJsonResponse(resJson.data);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDealInformationId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleDealInformationResponse(resJson);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.autopopulateSellerZipApiId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        sellerZip: resJson.data
      });
    }
  }
  handleDealInformationResponse(resJson: any) {
    if (resJson?.data) {
      let datavalue = resJson?.data?.attributes;
      this.setState({
        registrationType: datavalue?.registration_type,
        sellerZip: datavalue?.seller_zip,
        firstName: datavalue?.owner_first_name,
        vehicleYear: datavalue?.vehicle_year,
        emptyWeight: datavalue?.vehicle_weight_empty,
        vin: datavalue?.vehicle_vin,
        vehicleType: datavalue?.vehicle_type, 
        soldAs: datavalue?.sold_as,
        msrp: datavalue?.msrp,
        tsp: datavalue?.tsp,
        applicationType: datavalue?.application_type,
        lastName: datavalue?.owner_last_name,
        grossWeight: datavalue?.vehicle_weight_gross,
        saleType: datavalue?.sale_type,
        purchaserZip: datavalue?.purchaser_zip,
        dealer_information_id: datavalue?.dealership_detail?.data?.id
        ,headerCheck: datavalue?.vehicle_info_type === 'Motor Vehicles or Motorcycles'
      });
    }
    if (resJson?.errors?.length > 0) {
      this.showAlert("Error", resJson.errors[0].token);
    }
  }
  handleTokenSet(token: any) {
    if (!token) {
      this.setState({ apiToken: localStorage.getItem("authToken") });
      let id = localStorage.getItem("dId");
      if (id) {
        this.setState({ deal_id: id });
      }
      let dInfoId = localStorage.getItem("dInfoId");
      if (dInfoId) {
        this.setState({ dealer_information_id: dInfoId });
      }
    }

    if (token) {
      this.setState({ apiToken: token });
      let id = localStorage.getItem("dId");
      if (id) {
        this.setState({ deal_id: id })
      }
      let dInfoId = localStorage.getItem("dInfoId");
      if (dInfoId) {
        this.setState({ dealer_information_id: dInfoId });
      }
    }
  }
  handleJsonResponse(resJson: any) {
    console.log(resJson);
    if (!resJson?.data) {
      let value: any = Object.values(resJson[0])

      this.showAlert("Errro", value[0]);
    }

    if (resJson?.errors?.length > 0) {
      this.showAlert("Error", resJson.errors[0].token);
    }
    if (resJson?.data?.attributes?.is_draft) {
      this.navigateToCreateDealMain();
    } else {
      localStorage.setItem("createdDealId", resJson?.data?.id);
      this.navigateToDealDetails();
    }
  }

  handleDecodeJsonResponse = (resJson: any) => {
    if (resJson.HasError) {
      this.setState({
        checkDecode: false,
        vehicleYear: "",
        emptyWeight: "",
        decodeError: resJson.ResponseMessage
      });
    } else {
      const resbody = JSON.parse(resJson.ResponseBody);
      this.setState({
        vehicleYear: resbody.record.year,
        emptyWeight: resbody.record.unladenWeight,
        decodeError: "",
        checkDecode: true
      });
    }
  };
  navigateToDealDetails = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectTemplatesDealDashboard"
    );
    this.send(msg);
  };

  navigateToCreateDealMain = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AllDeals');
    this.send(msg);
  };

  handleNumericInput = (e: React.ChangeEvent<HTMLInputElement>, maxLength: number, type: 'number' | 'text' = 'text') => {
    const input = e.target.value;
    let value = String(input || '');

    if (type === 'number') {
      value = value.match(/[0-9.]+/g)?.join('') || '';

      const parts = value.split('.');
      if (parts.length > 2) {
        value = parts[0] + '.' + parts.slice(1).join('');
      }
    }
    
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }

    e.target.value = value;
};


   handleVinInput = (e: React.ChangeEvent<HTMLInputElement>, maxLength: number) => {
    const input = e.target as HTMLInputElement;
    let value = (input.value ?? '').toUpperCase().replace(/[^A-Z0-9]/g, '');
  
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
  
    // Update the value in the state
    input.value = value;
  };

  handleSubmit = (is_draft: boolean) => {
    this.setState(
      {
        dealer_information_id:
          localStorage.getItem("dInfoId") || this.state.dealer_information_id
      },
      () => {
        const formData = new FormData();
        if (this.state.deal_id) {
          formData.append("deal_id", this.state.deal_id);
        }
        formData.append("deal[tsp]", this.state.tsp);
        formData.append("deal[msrp]", this.state.msrp);
        formData.append("deal[sold_as]", this.state.soldAs);
        formData.append("deal[sale_type]", this.state.saleType);
        formData.append("deal[seller_zip]", this.state.sellerZip);
        formData.append("deal[purchaser_zip]", this.state.purchaserZip);
        formData.append("deal[vehicle_type]", this.state.vehicleType);
        formData.append("deal[vehicle_year]", this.state.vehicleYear);
        formData.append("deal[vehicle_vin]", this.state.vin);
        formData.append("deal[owner_first_name]", this.state.firstName);
        formData.append("deal[application_type]", this.state.applicationType);
        formData.append("deal[owner_last_name]", this.state.lastName);
        formData.append("deal[registration_type]", this.state.registrationType);
        formData.append("deal[vehicle_weight_empty]", this.state.emptyWeight);
        formData.append("deal[vehicle_weight_gross]", this.state.grossWeight);
        formData.append(
          "deal[dealer_information_id]",
          this.state.dealer_information_id
        );
        formData.append("deal[is_draft]", "" + is_draft);
        formData.append("deal[vehicle_info_type]", this.state.headerCheck ? 'Motor Vehicles or Motorcycles' : 'Motorhome or Trailers');
        const Webheader = {
          token: this.state.apiToken
        };

        const WebrequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createDealApiCallId = WebrequestMessage.messageId;

        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createDealAPiEndPoint
        );

        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(Webheader)
        );

        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );

        WebrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.createDealAPiMethod
        );

        runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
      }
    );
  };

  handleDecode() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDecodeApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/deals/decode_vin?vin=${this.state.vin}&state_code=CA`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDecodeAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  resetForm = () => {
    this.setState({
      applicationType: "",
      registrationAction: "",
      vin: "",
      vehicleType: "",
      vehicleYear: "",
      emptyWeight: "",
      grossWeight: "",
      saleType: "",
      soldAs: "",
      msrp: "",
      tsp: "",
      ownerFirstName: "",
      ownerLastName: "",
      firstName: "",
      lastName: "",
      registrationType: "",
      checkDecode: false,
      purchaserZip: ""
    });
  };
}
